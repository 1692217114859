import { Typography } from "antd";

const { Text } = Typography;

const EllipsisMiddle: React.FC<{ suffixCount: number; children: string }> = ({
  suffixCount,
  children,
}) => {
  const start = children.slice(0, children.length - suffixCount - 10).trim();
  const suffix = children.slice(-suffixCount).trim();

  return (
    <Text style={{ width: "100%" }}>
      {start}...{suffix}
    </Text>
  );
};

export default EllipsisMiddle;
