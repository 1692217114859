import { Button, Descriptions, Divider, Spin, Typography } from "antd";
import { useParams } from "react-router-dom";
import { TITLE, BASE_PATH, ProcessingStatusBadge } from ".";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import { useEffect, useRef, useState } from "react";
import service from "@services/asset";
import { useNavigate } from "react-router-dom";

import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
  ExportOutlined,
} from "@ant-design/icons";
const { Title } = Typography;

function View(props: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const setBreadcrumbs = useBreadcrumbs();
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "View Screen" },
  ]);
  useEffect(() => {
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const handleForm = (entityId?: string) => {
    if (entityId) {
      navigate(`${BASE_PATH}/edit?entity=${entityId}`);
    } else {
      navigate(`${BASE_PATH}/new`);
    }
  };
  useEffect(() => {
    if (id) {
      setLoading(true);
      service
        .get(id)
        .then((res) => {
          setData(res);
          breadcrumbsRef.current = [
            { label: TITLE[1], url: BASE_PATH },
            { label: res.name },
          ];
          setBreadcrumbs(breadcrumbsRef.current);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  return (
    <Spin spinning={loading}>
      <div className="flex justify-between">
        <Title level={3}>{data?.name}</Title>
        <Button icon={<EditOutlined />} onClick={() => handleForm(id)}>
          Edit
        </Button>
      </div>

      <div className="bg-white p-3 rounded-lg">
        <div>
          <span className="font-semibold">Asset Information</span>
        </div>
        <Divider className="my-2" />
        <div>
          <Descriptions
            size="small"
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="Name" span={2}>
              {data?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Mime Type" span={2}>
              {data?.mimeType}
            </Descriptions.Item>
            <Descriptions.Item label="Original File" span={2}>
              <a onClick={() => window.open(data?.originalUrl, "_blank")}>
                <ExportOutlined /> {data.name}.
                {data?.originalUrl?.split(".").pop().split("?")[0]}{" "}
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="Status" span={2}>
              <ProcessingStatusBadge
                record={data}
                status={data?.status}
                isLoading={false}
              />
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
      <div className="bg-white p-3 mt-3 rounded-lg">
        <div>
          <span className="font-semibold">Asset Information</span>
        </div>
        <Divider className="my-2" />
        <div className="flex gap-x-4">
          {data.processedUrls &&
            Object.keys(data.processedUrls).map((resKey) => {
              const url = data.processedUrls[resKey];
              const folder = url.substring(0, url.lastIndexOf("/") + 1);
              const imageUrl =
                folder + url.split("/").pop().split(".")[0] + ".0000000.jpg";
              return (
                <div>
                  <div className="mb-2">
                    <span className="font-semibold">{resKey}</span>
                  </div>

                  <a onClick={() => window.open(url, "_blank")}>
                    <img src={imageUrl} alt="thumbnail" width="200" />
                  </a>
                </div>
              );
            })}
        </div>
      </div>
    </Spin>
  );
}
export default View;
