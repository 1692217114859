import { Layout, Menu, Avatar, Dropdown, Button, Space, Select } from "antd";
import {
  UserOutlined,
  MenuOutlined,
  ApiOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { FaUserCircle, FaSignOutAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import LogoPNG from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import * as uiActions from "../store/uiSlice";
import { Store } from "@store/index";
import { useEffect, useState } from "react";
import * as authActions from "@store/authSlice";
import Logout from "../utils/logout";

const { Header: AntHeader } = Layout;
export default function Header(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authState = useSelector((state: Store) => state.auth);
  useEffect(() => {
    dispatch(authActions.getUserAsync());
  }, []);
  const AccountMenu = (
    <Menu style={{ top: -15 }}>
      <Menu.Item
        key="0"
        icon={<FaUserCircle />}
        className="flex items-center"
        onClick={() => navigate("/profile")}
      >
        <span className="ml-2">Account</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="1"
        icon={<FaSignOutAlt />}
        className="flex items-center"
        onClick={() => Logout(true, false)}
      >
        <span className="ml-2">Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <AntHeader className="bg-transparent">
      <div className="h-full flex  justify-between items-center ">
        <Button
          type="text"
          icon={props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => props.onCollapse(!props.collapsed)}
          style={{
            fontSize: "16px",
            width: 64,
            height: 64,
          }}
        />
        <div className="flex items-center "></div>
        <div className="grid place-items-center grid-flow-col gap-x-2">
          <Button
            type="link"
            icon={<ApiOutlined />}
            onClick={() => dispatch(uiActions.setShowClientTesting(true))}
            className="hidden lg:block"
          >
            Client Testing
          </Button>
          <Dropdown overlay={AccountMenu} trigger={["click"]} className="pr-3">
            <div className="flex items-center cursor-pointer">
              <Avatar
                icon={<UserOutlined />}
                size={36}
                src={authState?.user?.image}
              />
            </div>
          </Dropdown>
        </div>
      </div>
    </AntHeader>
  );
}
