import {
  Button,
  Descriptions,
  Divider,
  Spin,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useParams } from "react-router-dom";
import { TITLE, BASE_PATH } from ".";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import { useEffect, useRef, useState } from "react";
import service from "@services/client";
import { useNavigate } from "react-router-dom";
import deviceService from "@services/device";
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { titleCase } from "title-case";
import DeviceStatusModal from "@components/DeviceStatusModal";
import useDevicesHealth from "@hooks/useDevicesHealth";
import _ from "lodash";
import ClientStatusModal from "@components/ClientStatusModal";
import ClientDeviceStatusModal from "@components/ClientDeviceStatusModal";
const { Title } = Typography;

export function DevicesTable(props: any) {
  const { data, loading } = props;
  const [selectedDevice, setSelectedDevice] = useState<any>();
  const [lastSeenData, setLastSeenData] = useState<any>({});

  const health = useDevicesHealth({
    devices: data || [],
    ids: [],
    onUpdate: (sockets) => updateLastSeen(sockets),
  });
  const updateLastSeen = (sockets) => {
    const newData: any[] = _.cloneDeep(data || []);
    if (sockets.length === 0) {
      return;
    }

    const newLastSeenData: any = {};

    for (let socket of sockets) {
      const recordIndex = data?.findIndex((d) => d.deviceId === socket.id);
      if (recordIndex !== -1) {
        newLastSeenData[newData[recordIndex]._id] = socket.lastSeen;
      }
    }
    setLastSeenData({ ...lastSeenData, ...newLastSeenData });
  };
  const columns: any[] = [
    {
      key: "device",
      align: "right",
      render: (_, record) => {
        if (health.payload?.[record.deviceId]) {
          const { Render } = health.payload[record.deviceId];
          return <Render lastSeen={lastSeenData[record._id]} />;
        }
      },
    },
    {
      key: "group",
      title: "Group",
      dataIndex: "group",
      filters: data
        .map((device) => device.group)
        .reduce((acc, group) => {
          if (acc.findIndex((g) => g?._id === group?._id) === -1) {
            acc.push(group);
          }
          return acc;
        }, [])
        .map((group) => ({
          text: group?.name || "No Group",
          value: group?._id || "undefined",
        })),
      onFilter: (value, record) => {
        return value === "undefined"
          ? record.group?._id === undefined
          : record.group?._id === value;
      },
      render: (group) =>
        group?.name || <span className="italic">No Group</span>,
    },
    { key: "deviceId", title: "Device ID", dataIndex: "deviceId" },
    {
      key: "resolution",
      title: "Max Resolution",
      filters: ["480p", "720p", "1080p", "1440p", "2160p"].map((res) => ({
        text: res,
        value: res.substring(0, res.indexOf("p")),
      })),
      dataIndex: "display",
      onFilter: (value, record) => {
        const minimumResolution = Math.min(
          ...record.display?.resolution.split("x").map((res) => +res)
        );

        return minimumResolution >= +value;
      },

      render: (display) => {
        return (
          <div>
            {display?.resolution} ({display?.refreshRate})
          </div>
        );
      },
    },

    {
      key: "orientation",
      title: "Orientation",
      dataIndex: "orientation",
      filters: ["LANDSCAPE", "PORTRAIT"].map((orientation) => ({
        text: titleCase(orientation.toLowerCase() || ""),
        value: orientation,
      })),
      onFilter: (value, record) => record.orientation === value,
      render: (orientation) => titleCase(orientation.toLowerCase() || ""),
    },
    {
      key: "osVersion",
      title: "OS Version",
      dataIndex: "osVersion",
      render: (osVersion, record) => (
        <div>
          {osVersion} (API: {record.apiLevel})
        </div>
      ),
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      filters: ["REGISTERED", "ACTIVATED", "SUSPENDED"].map((status) => ({
        text: (
          <Tag
            color={
              status === "REGISTERED"
                ? "blue"
                : status === "ACTIVATED"
                ? "green"
                : "default"
            }
          >
            {status}
          </Tag>
        ),
        value: status,
      })),
      onFilter: (value, record) => record.status === value,
      render: (status) => (
        <Tag
          color={
            status === "REGISTERED"
              ? "blue"
              : status === "ACTIVATED"
              ? "green"
              : "default"
          }
        >
          {status}
        </Tag>
      ),
    },
    {
      key: "actions",
      title: "Actions",
      render: (record) => (
        <div>
          <Tooltip
            title={props.isClientActive ? "Toggle Device" : "Client Suspended"}
          >
            <Switch
              checked={props.isClientActive && record.isActive}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onClick={() => {
                setSelectedDevice(record);
              }}
              disabled={record.status === "REGISTERED" || !props.isClientActive}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <DeviceStatusModal
        data={selectedDevice}
        open={!!selectedDevice}
        onCancel={() => setSelectedDevice(undefined)}
        onRequestRefresh={() => {
          props.onRefresh();
          setSelectedDevice(undefined);
        }}
      />
      <Table
        dataSource={data}
        columns={columns.filter((c) =>
          !props.hiddenColumns || props.hiddenColumns.length === 0
            ? true
            : !props.hiddenColumns.includes(c.key)
        )}
        loading={loading}
        size="small"
      />
    </div>
  );
}

function View(props: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const setBreadcrumbs = useBreadcrumbs();
  const [data, setData] = useState<any>({});
  const [devices, setDevices] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [devicesLoading, setDevicesLoading] = useState(false);
  const [activeToggleLoading, setActiveToggleLoading] = useState(false);
  const [activeToggleModalVisible, setActiveToggleModalVisible] =
    useState(false);
  const [activeDevicesToggleModalVisible, setActiveDevicesToggleModalVisible] =
    useState(false);
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "View Client" },
  ]);
  useEffect(() => {
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const handleForm = (entityId?: string) => {
    if (entityId) {
      navigate(`${BASE_PATH}/edit?entity=${entityId}`);
    } else {
      navigate(`${BASE_PATH}/new`);
    }
  };
  useEffect(() => {
    if (id) {
      onRefresh();
    }
  }, [id]);

  const onRefresh = () => {
    service
      .get(id)
      .then((res) => {
        setData(res);
        breadcrumbsRef.current = [
          { label: TITLE[1], url: BASE_PATH },
          { label: res.name },
        ];
        service
          .getDevicesForClient(id)
          .then((res) => {
            setDevices(res);
          })
          .finally(() => {
            setDevicesLoading(false);
          });
        setBreadcrumbs(breadcrumbsRef.current);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Spin spinning={loading}>
      <ClientStatusModal
        data={data}
        open={activeToggleModalVisible}
        onCancel={() => setActiveToggleModalVisible(false)}
        onRequestRefresh={() => {
          onRefresh();
          setActiveToggleModalVisible(false);
        }}
      />
      <ClientDeviceStatusModal
        data={{ ...data, devices }}
        open={activeDevicesToggleModalVisible}
        onCancel={() => setActiveDevicesToggleModalVisible(false)}
        onRequestRefresh={() => {
          onRefresh();
          setActiveDevicesToggleModalVisible(false);
        }}
      />
      <div className="flex justify-between">
        <Title level={3} className="flex gap-x-2 items-center">
          {data?.name} ({data?.code}){" "}
          {data?.isActive ? "" : <Tag>SUSPENDED</Tag>}
        </Title>
        <Button icon={<EditOutlined />} onClick={() => handleForm(id)}>
          Edit
        </Button>
      </div>
      {data?.address && (
        <div className="bg-white rounded-lg p-3">
          <div>
            <span className="font-semibold">Address Information</span>
          </div>
          <Divider className="my-2" />
          <div>
            <Descriptions
              size="small"
              bordered
              column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label="Address Line 1">
                {data?.address.address1} {data?.address.address2}
              </Descriptions.Item>
              <Descriptions.Item label="City">
                {data?.address.city}
              </Descriptions.Item>
              <Descriptions.Item label="State">
                {data?.address.state}
              </Descriptions.Item>
              <Descriptions.Item label="Pincode">
                {data?.address.pincode}
              </Descriptions.Item>
              <Descriptions.Item label="Allowed Access to CMS" span={2}>
                <Switch
                  checked={data?.isActive}
                  onClick={() => setActiveToggleModalVisible(true)}
                />
              </Descriptions.Item>
              {/* <Descriptions.Item label="Allowed Device Access">
                <Switch
                  checked={data?.isDeviceAccessActive}
                  onClick={() => setActiveDevicesToggleModalVisible(true)}
                />
              </Descriptions.Item> */}
            </Descriptions>
          </div>
        </div>
      )}
      {data?.contact && (
        <div className="bg-white rounded-lg p-3 mt-3">
          <div>
            <span className="font-semibold">Contact Information</span>
          </div>
          <Divider className="my-2" />
          <div>
            <Descriptions size="small" bordered>
              <Descriptions.Item label="Name">
                {data?.contact.name}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {data?.contact.email}
              </Descriptions.Item>
              <Descriptions.Item label="Phone">
                {data?.contact.phone}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      )}
      <div className="bg-white rounded-lg p-3 mt-3">
        <div>
          <span className="font-semibold">Registered Devices</span>
        </div>
        <Divider className="my-2" />
        <DevicesTable
          data={devices}
          loading={devicesLoading}
          onRefresh={onRefresh}
          isClientActive={data?.isActive}
        />
      </div>
    </Spin>
  );
}
export default View;
