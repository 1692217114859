import { Routes, Route } from "react-router-dom";
import List from "./List";
import Form from "./Form";
import View from "./View";
import { LoadingOutlined } from "@ant-design/icons";
import { Tag } from "antd";

export const MODEL = "campaign";
export const BASE_PATH = "/compositions";
export const TITLE = ["Composition", "Compositions"];

export const StatusBadge = ({ status }) => {
  return status === "ACTIVE" ? (
    <Tag color="green">{status}</Tag>
  ) : status === "DRAFT" ? (
    <Tag color="default">{status}</Tag>
  ) : status === "PUBLISHED" ? (
    <Tag color="blue">{status}</Tag>
  ) : status === "CANCELLED" ? (
    <Tag color="error">{status}</Tag>
  ) : status === "COMPLETED" ? (
    <Tag color="warning">{status}</Tag>
  ) : (
    <Tag color="grey">{status}</Tag>
  );
};
export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/new`} element={<Form />} />
      <Route path={`/edit`} element={<Form />} />
      <Route path={`/`} element={<List />} />
      <Route path={`/:id`} element={<View />} />
    </Routes>
  );
}
