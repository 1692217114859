import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import * as uiActions from "../store/uiSlice";
import {
  Breadcrumb,
  Button,
  Divider,
  Menu,
  Modal,
  Select,
  Spin,
  theme,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "antd";
import Header from "@components/Header";
import { useEffect, useState } from "react";
import useBreakpoint from "../utils/useBreakpoint";

import { Store } from "@store";
import Navigation from "../components/Navigation";
import deviceService from "@services/device";
import campaignService from "@services/campaign";
import Compositions from "@views/Compositions";
import Clients from "@views/Clients";
import Layouts from "@views/Layouts";
import ClientDevices from "@views/ClientDevices";
import Devices from "@views/Devices";
import Assets from "@views/Assets";
import DeviceGroups from "@views/DeviceGroups";
import Settings from "@views/Settings";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import Campaigns from "@views/Campaigns";
import { initialize, terminate } from "@services/config/progress";
import useIsAdmin from "@hooks/useIsAdmin";
import { SyncOutlined, ArrowRightOutlined } from "@ant-design/icons";
import "react-json-view-lite/dist/index.css";
import Dashboard from "@views/Dashboard";
import dayjs from "dayjs";
function BreadcrumbComponent(props: any) {
  const location = useLocation();
  const urlState = useSelector((state: Store) => state.url);
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Route changed to:", location.pathname);
  }, [location]);

  return urlState.breadcrumbs?.length > 0 ? (
    <div className="px-6 mt-4">
      <Breadcrumb>
        {urlState.breadcrumbs.map((b) => (
          <Breadcrumb.Item>
            <a onClick={() => navigate(b.url)}>{b.label}</a>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  ) : undefined;
}

function AppLayout(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [showNav, setShowNav] = useState<boolean>(true);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const breakpoint = useBreakpoint();
  const uiState = useSelector((state: Store) => state.ui);
  const isAdmin = useIsAdmin();
  const authState = useSelector((state: Store) => state.auth);
  const [devices, setDevices] = useState<any[]>([]);
  const [selectedClientDevice, setSelectedClientDevice] = useState<any>();
  const [currentCampaign, setCurrentCampaign] = useState<any>();
  useEffect(() => {
    handleOnRefresh();
  }, [uiState.showClientTesting]);

  useEffect(() => {
    if (authState.user) {
      initialize();
    } else {
      terminate();
    }
  }, [authState.user]);
  const handleOnRefresh = async () => {
    try {
      const devices = await deviceService.getAllForClient();
      setDevices(devices);
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetCurrentCampaign = async () => {
    const currentCampaign = await campaignService.getCurrentCampaign(
      selectedClientDevice
    );
    if (currentCampaign.active) {
      currentCampaign.active.schedule.start = dayjs(
        currentCampaign.active.schedule.start
      ).format("DD/MM/YY hh:mm:ss A");
      currentCampaign.active.schedule.end = dayjs(
        currentCampaign.active.schedule.end
      ).format("DD/MM/YY hh:mm:ss A");
    }
    if (currentCampaign.next) {
      currentCampaign.next.schedule.start = dayjs(
        currentCampaign.next.schedule.start
      ).format("DD/MM/YY hh:mm:ss A");
      currentCampaign.next.schedule.end = dayjs(
        currentCampaign.next.schedule.end
      ).format("DD/MM/YY hh:mm:ss A");
    }

    setCurrentCampaign(currentCampaign);
  };
  return (
    <Layout className="min-h-screen">
      <Modal
        open={uiState.showClientTesting}
        onCancel={() => dispatch(uiActions.setShowClientTesting(false))}
        title="Client Testing"
      >
        <div className="flex flex-col gap-y-2">
          Select Screen:
          <div className="flex gap-x-2">
            <Select
              className="w-full"
              onChange={setSelectedClientDevice}
              value={selectedClientDevice}
              placeholder="Select a screen"
            >
              {devices.map((d) => (
                <Select.Option value={d.deviceId}>
                  <div>{d.deviceId}</div>
                </Select.Option>
              ))}
            </Select>
            <Button icon={<SyncOutlined />} onClick={handleOnRefresh} />
            <Button
              icon={<ArrowRightOutlined />}
              type="primary"
              onClick={handleGetCurrentCampaign}
            />
          </div>
        </div>
        {currentCampaign && (
          <>
            <Divider className="my-2" />
            <JsonView
              data={currentCampaign || {}}
              shouldExpandNode={allExpanded}
              style={defaultStyles}
            />
          </>
        )}
      </Modal>
      <Navigation
        showNav={showNav}
        collapsed={collapsed}
        onCollapse={setCollapsed}
        onShowDrawer={setShowDrawer}
        onNavigate={navigate}
        showDrawer={showDrawer}
        authState={authState}
      />
      <Layout>
        <Header collapsed={collapsed} onCollapse={setCollapsed} />
        <Layout>
          {/* {uiState.showSider && <BreadcrumbComponent />} */}
          <div className="pt-6 px-4">
            <Routes>
              {isAdmin ? (
                <>
                  <Route path="/clients/*" element={<Clients />} />
                  <Route path="/devices/*" element={<Devices />} />
                </>
              ) : (
                <>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route
                    path="/registered-devices/*"
                    element={<ClientDevices />}
                  />
                  <Route path="/campaigns/*" element={<Campaigns />} />
                  <Route path="/layouts/*" element={<Layouts />} />
                  <Route path="/compositions/*" element={<Compositions />} />
                  <Route path="/device-groups/*" element={<DeviceGroups />} />
                  <Route path="/assets/*" element={<Assets />} />
                  <Route path="/settings/*" element={<Settings />} />
                </>
              )}
            </Routes>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default AppLayout;
