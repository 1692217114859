import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  TimePicker,
} from "antd";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
const { Option } = Select;
const REPEAT_OPTIONS = [
  { label: "Single", value: "single" },
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
];

export const COLOR_OPTIONS = [
  { background: "bg-red-500", color: "text-white" },
  { background: "bg-yellow-500", color: "text-black" },
  { background: "bg-green-500", color: "text-white" },
  { background: "bg-blue-500", color: "text-white" },
  { background: "bg-purple-500", color: "text-white" },
  { background: "bg-gray-500", color: "text-white" },
];

function ColorFormControl(props: any) {
  return (
    <div className="flex gap-x-2 ml-1">
      {COLOR_OPTIONS.map((color) => (
        <div
          className={`${color.background} rounded-full cursor-pointer ${
            props.value?.background === color.background &&
            `ring-2 ring-${color} ring-offset-2`
          } `}
          style={{ height: 20, width: 20 }}
          onClick={() => props.onChange(color)}
        ></div>
      ))}
    </div>
  );
}
function EventForm(props: any) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const allDay = Form.useWatch("allDay", form);
  const repeat = Form.useWatch("repeat", form);
  const [id, setId] = useState("");
  const [repeatId, setRepeatId] = useState("");
  const handleSubmit = (values: any) => {
    setLoading(true);
    props.onSubmit({
      ...values,
      start: values.start.toDate(),
      end: values.end.toDate(),
      repeatId: values.repeat !== "single" ? nanoid() : undefined,
    });
    setLoading(false);
  };
  const handleUpdate = (values: any) => {
    setLoading(true);
    props.onUpdate({
      ...values,
      id: id,
      start: values.start.toDate(),
      end: values.end.toDate(),
      repeatId: repeatId,
    });
    setLoading(false);
  };

  useEffect(() => {
    form.setFieldsValue({ ...props.defaultValues });
    if (props.defaultValues?.id) {
      setId(props.defaultValues.id);
    }
    if (props.defaultValues?.repeatId) {
      setRepeatId(props.defaultValues.repeatId);
    }
  }, [props]);
  return (
    <Form
      form={form}
      onFinish={id ? handleUpdate : handleSubmit}
      initialValues={{
        ...props.defaultValues,
        repeat: REPEAT_OPTIONS[0].value,
      }}
    >
      <Row gutter={8}>
        {/* <Col span={24}>
          <Form.Item
            name="color"
            label="Color"
            rules={[{ required: true, message: "Color is required" }]}
            initialValue={
              COLOR_OPTIONS[Math.floor(Math.random() * COLOR_OPTIONS.length)]
            }
          >
            <ColorFormControl />
          </Form.Item>
        </Col> */}
        <Col span={24}>
          <Form.Item
            className="w-full"
            label="All Day"
            name="allDay"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="w-full"
            label="Start At"
            name="start"
            rules={[{ required: true, message: "Start At is required" }]}
          >
            <TimePicker
              className="w-full"
              placeholder="Select Start At"
              format="hh:mmA"
              disabled={allDay}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="w-full"
            label="End At"
            name="end"
            rules={[{ required: true, message: "End At is required" }]}
          >
            <TimePicker
              className="w-full"
              placeholder="Select End At"
              format="hh:mmA"
              disabled={allDay}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            className="w-full"
            label="Repeat"
            name="repeat"
            extra={
              repeat === "daily"
                ? "Applicable for the next 90 days"
                : repeat === "weekly"
                ? "Applicable for the next 52 weeks"
                : repeat === "monthly"
                ? "Applicable for the next 12 months"
                : undefined
            }
          >
            <Select
              placeholder="Select Repeat"
              defaultValue={REPEAT_OPTIONS[0].value}
            >
              {REPEAT_OPTIONS.map((option) => (
                <Option value={option.value}>{option.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <div className="mt-3">
        <Form.Item noStyle>
          <Space>
            <Button type="default" onClick={props.onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </div>
    </Form>
  );
}

export default EventForm;
