import { Tag } from "antd";

export default [
  {
    label: "DRAFT",
    color: "default",
    value: "DRAFT",
    render: <Tag color="default">DRAFT</Tag>,
  },
  {
    label: "PUBLISHED",
    color: "blue",
    value: "PUBLISHED",
    render: <Tag color="blue">PUBLISHED</Tag>,
  },
  {
    label: "ACTIVE",
    color: "green",
    value: "ACTIVE",
    render: <Tag color="green">ACTIVE</Tag>,
  },
  {
    label: "COMPLETED",
    color: "warning",
    value: "COMPLETED",
    render: <Tag color="warning">COMPLETED</Tag>,
  },
  {
    label: "CANCELLED",
    color: "error",
    value: "CANCELLED",
    render: <Tag color="error">CANCELLED</Tag>,
  },
];
