import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const TYPE = "proof_of_play";

export interface ProofOfPlayState {
  metrics: any;
}
const INITIAL_STATE: ProofOfPlayState = {
  metrics: {},
};

const slice = createSlice({
  name: TYPE,
  initialState: INITIAL_STATE,
  reducers: {
    addMetric: (state, action) => {
      const { deviceId, ...payload } = action.payload;
      if (!state.metrics) {
        state.metrics = {};
      }
      if (!state.metrics[deviceId]) {
        state.metrics[deviceId] = [];
      }
      state.metrics[deviceId].push({ ...payload });
    },
  },
  extraReducers: (builder) => {},
});
export const { addMetric } = slice.actions;
export default slice.reducer;
