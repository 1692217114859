import AuthLayout from "../layout/AuthLayout";
import AppLayout from "../layout/AppLayout";
import {
  Routes,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import SetupLayout from "layout/SetupLayout";
import FullScreenLayout from "layout/FullScreenComponent";
const router = createBrowserRouter([
  {
    path: "/auth/*",
    element: <AuthLayout />,
  },
  {
    path: "/fs/*",
    element: <FullScreenLayout />,
  },
  {
    path: "/setup/*",
    element: <SetupLayout />,
  },
  {
    path: "*",
    element: <AppLayout />,
  },
]);

export default function RouteConfig(props: any) {
  return <RouterProvider router={router} />;
}
