import { useEffect, useState, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Input,
  Select,
  Table,
  Space,
  Popover,
  List,
  Checkbox,
  Form,
  Modal,
  Divider,
  Badge,
  Tag,
  Dropdown,
  Tooltip,
  Drawer,
  message,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
  ExportOutlined,
  SyncOutlined,
  MoreOutlined,
  StopOutlined,
  CheckCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import deviceGroupService from "@services/deviceGroup";
import { LuActivity } from "react-icons/lu";
import { MdLiveTv } from "react-icons/md";
import { debounce } from "throttle-debounce";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TITLE, BASE_PATH } from ".";
import service from "@services/device";
import _ from "lodash";
import useSearchParamsTableState from "hooks/useSearchParamsTableState";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import { TbColumns3 } from "react-icons/tb";
import useSelectedColumnsState from "@hooks/useSelectedColumnsState";
import saveAs from "file-saver";
import { titleCase } from "title-case";
import useDevicesHealth from "@hooks/useDevicesHealth";
import TelemetryModal from "@components/TelemetryModal";
import ProofOfPlayModal from "@components/ProofOfPlayModal";
const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const MASTER_KEYS = [{ value: "deviceGroups", service: deviceGroupService }];

export default function Entities(props: any) {
  const breadcrumbsRef = useRef([{ label: TITLE[1], url: BASE_PATH }]);
  const [columnsDrawerVisible, setColumnsDrawerVisible] = useState(false);

  const [
    input,
    setInput,
    tableState,
    setTableState,
    data,
    loading,
    onRefresh,
    setData,
  ] = useSearchParamsTableState({ onFetch: service.paginateForClient });
  const navigate = useNavigate();
  const setBreadcrumbs = useBreadcrumbs();
  const [selectedDeviceForMetrics, setSelectedDeviceForMetrics] =
    useState<any>();
  const [selectedDeviceForLiveView, setSelectedDeviceForLiveView] =
    useState<any>();
  const [exportLoading, setExportLoading] = useState(false);
  const health = useDevicesHealth({
    devices: data?.list || [],
    ids: [],
    onUpdate: (sockets) => updateLastSeen(sockets),
  });
  const [lastSeenData, setLastSeenData] = useState<any>({});
  const [tags, setTags] = useState<any>([]);
  console.log("tags", tags);
  const ADDITIONAL_COLUMNS = [
    { key: "deviceId", title: "Device ID", dataIndex: "deviceId" },

    {
      key: "resolution",
      title: "Max Resolution",
      filters: ["480p", "720p", "1080p", "1440p", "2160p"].map((res) => ({
        text: res,
        value: res,
      })),
      dataIndex: "display",
      render: (display) => {
        return (
          <div>
            {display?.resolution} ({display?.refreshRate})
          </div>
        );
      },
    },

    {
      key: "osVersion",
      title: "OS Version",
      dataIndex: "osVersion",
      render: (osVersion, record) => (
        <div>
          {osVersion} (API: {record.apiLevel})
        </div>
      ),
    },
    {
      key: "activatedAt",
      title: "Activated At",
      dataIndex: "activatedAt",
      render: (activatedAt, record) =>
        activatedAt ? dayjs(activatedAt).format("DD/MM/YYYY") : "Legacy Data",
    },
    {
      title: "Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",

      render: (text, record, index) => dayjs(text).format("DD/MM/YYYY"),
    },
  ];
  const { selectedColumns, setSelectedColumns } = useSelectedColumnsState({
    breadcrumbs: breadcrumbsRef.current,
    availableColumns: ADDITIONAL_COLUMNS,
    defaultSelected: [],
  });

  useEffect(() => {
    loadMastersDebounced();
    handleLoadTags();

    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const [masters, setMasters] = useState<any>({});
  const handleLoadTags = useCallback(async () => {
    try {
      const tags = await service.getUniqueTags();
      setTags(tags.map((tag) => tag._id));
    } catch (error) {
      message.error("Failed to load tags");
    }
  }, []);
  const getColumnsFilters = useCallback(
    (key: string) => {
      return masters[key]
        ? masters[key].map((entity) => ({
            text: entity.name,
            value: entity._id,
          }))
        : [];
    },
    [masters]
  );
  const loadMasters = useCallback(() => {
    const promises: any[] = [];
    for (let masterKey of MASTER_KEYS) {
      promises.push(
        masterKey.service
          .getAll()
          .then((p) => ({ key: masterKey.value, data: p }))
      );
    }
    Promise.all(promises).then((payload) => {
      const output: any = {};
      payload.forEach((m) => (output[m.key] = m.data));
      setMasters(output);
    });
  }, []);
  const loadMastersDebounced = useCallback(debounce(300, loadMasters), []);
  const handleActivate = async (record: any) => {
    try {
      await service.activateDevice(record._id);
      onRefresh();
    } catch (error) {
      console.log(error);
    }
  };
  const handleSuspend = async (record: any) => {
    try {
      await service.suspendDevice(record._id);
      onRefresh();
    } catch (error) {
      console.log(error);
    }
  };

  const columns: any[] = [
    {
      key: "device",
      align: "right",
      render: (_, record) => {
        if (health.payload?.[record.deviceId]) {
          const { Render } = health.payload[record.deviceId];
          return (
            <div className="flex justify-center">
              <Render lastSeen={lastSeenData[record._id]} />
            </div>
          );
        }
      },
    },
    {
      key: "group",
      title: "Group",
      dataIndex: "group",
      filters:
        masters.deviceGroups?.map((group: any) => ({
          text: group.name,
          value: group._id,
        })) || [],
      render: (group) =>
        group?.name || <span className="italic">No Group</span>,
    },
    {
      key: "orientation",
      title: "Orientation",
      dataIndex: "orientation",
      filters: ["LANDSCAPE", "PORTRAIT"].map((orientation) => ({
        text: titleCase(orientation.toLowerCase() || ""),
        value: orientation,
      })),

      render: (orientation) => titleCase(orientation.toLowerCase() || ""),
    },
    { key: "location", title: "Location", dataIndex: "location" },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      filters: tags.map((tag) => ({ text: tag, value: tag })),
      render: (tags) => {
        return tags ? (
          <Space>
            {tags.map((tag: any) => (
              <Tag key={tag} color="default">
                {tag}
              </Tag>
            ))}
          </Space>
        ) : (
          <></>
        );
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      filters: ["REGISTERED", "ACTIVATED", "SUSPENDED"].map((status) => ({
        text: (
          <Tag
            color={
              status === "REGISTERED"
                ? "blue"
                : status === "ACTIVATED"
                ? "green"
                : "default"
            }
          >
            {status}
          </Tag>
        ),
        value: status,
      })),
      render: (status) => (
        <Tag
          color={
            status === "REGISTERED"
              ? "blue"
              : status === "ACTIVATED"
              ? "green"
              : "default"
          }
        >
          {status}
        </Tag>
      ),
    },
    {
      key: "actions",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: "telemetry",
                label: (
                  <Button
                    size="small"
                    type="link"
                    icon={<LuActivity className="mr-2 text-lg" />}
                    onClick={() => setSelectedDeviceForMetrics(record)}
                  >
                    View Metrics
                  </Button>
                ),
              },
              {
                key: "live",
                label: (
                  <Button
                    size="small"
                    type="link"
                    icon={<MdLiveTv className="mr-2 text-lg" />}
                    onClick={() => setSelectedDeviceForLiveView(record)}
                  >
                    Live View
                  </Button>
                ),
              },
              record.status === "SUSPENDED"
                ? {
                    key: "activated",
                    label: (
                      <Button
                        size="small"
                        type="link"
                        icon={<CheckCircleOutlined />}
                        onClick={() => handleActivate(record)}
                      >
                        Activate Device
                      </Button>
                    ),
                  }
                : {
                    key: "suspend",
                    label: (
                      <Button
                        size="small"
                        type="link"
                        icon={<StopOutlined />}
                        onClick={() => handleSuspend(record)}
                      >
                        Suspend Device
                      </Button>
                    ),
                  },
              {
                key: "changeLog",
                label: (
                  <Button
                    size="small"
                    type="link"
                    icon={<UnorderedListOutlined />}
                  >
                    Change Log
                  </Button>
                ),
              },
            ],
          }}
          trigger={["click"]}
        >
          <Button icon={<MoreOutlined />} type="link" />
        </Dropdown>
      ),
    },
    ...(selectedColumns as any[]),
  ];

  const handleForm = (entityId?: string) => {
    if (entityId) {
      navigate(`${BASE_PATH}/edit?entity=${entityId}`);
    } else {
      navigate(`${BASE_PATH}/new`);
    }
  };
  const handleView = (entityId?: string) => {
    navigate(`${BASE_PATH}/${entityId}`);
  };
  const handleBulk = () => {
    navigate(`${BASE_PATH}/bulk`);
  };
  const handleExport = async () => {
    try {
      setExportLoading(true);
      const file = await service.exportToXLSX();
      saveAs(file, `Devices-${dayjs().format("DDMMYYYYHHmm")}.xlsx`);
    } catch (error) {
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };

  const updateLastSeen = (sockets) => {
    const newData: any[] = _.cloneDeep(data.list || []);
    if (sockets.length === 0) {
      return;
    }

    const newLastSeenData: any = {};

    for (let socket of sockets) {
      const recordIndex = data?.list.findIndex((d) => d.deviceId === socket.id);
      if (recordIndex !== -1) {
        newLastSeenData[newData[recordIndex]._id] = socket.lastSeen;
      }
    }
    setLastSeenData({ ...lastSeenData, ...newLastSeenData });
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     const firstDevice = data?.list?.[0];
  //     if (firstDevice) {
  //       service.subscribeToEvent(firstDevice.deviceId, "TELEMETRY");
  //     }
  //   }, 2000);
  // }, [data?.list]);

  return (
    <div>
      <Drawer
        open={columnsDrawerVisible}
        title="Select Columns"
        onClose={() => setColumnsDrawerVisible(false)}
      >
        <List
          size="small"
          dataSource={ADDITIONAL_COLUMNS}
          renderItem={(item) => (
            <List.Item className="flex items-center gap-x-2 justify-start">
              <div className="w-max">
                <Checkbox
                  checked={selectedColumns.some((c) => c.key === item.key)}
                  onChange={(e) => {
                    setSelectedColumns(item);
                  }}
                  children={item.title}
                />
              </div>
            </List.Item>
          )}
        />
      </Drawer>
      <TelemetryModal
        open={!!selectedDeviceForMetrics}
        data={selectedDeviceForMetrics}
        onCancel={() => setSelectedDeviceForMetrics(undefined)}
        status={health.payload?.[selectedDeviceForMetrics?.deviceId]}
      />
      <ProofOfPlayModal
        open={!!selectedDeviceForLiveView}
        data={selectedDeviceForLiveView}
        onCancel={() => setSelectedDeviceForLiveView(undefined)}
        status={health.payload?.[selectedDeviceForLiveView?.deviceId]}
      />
      <Row justify="space-between">
        <Col>
          <Title level={4}>All {TITLE[1]}</Title>
        </Col>
        <Col></Col>
        <Col>
          <Space>
            <Button
              icon={<TbColumns3 />}
              onClick={() => setColumnsDrawerVisible(true)}
            />
            <Button
              type="primary"
              icon={<ExportOutlined />}
              onClick={() => handleExport()}
            >
              Export XLSX
            </Button>
          </Space>
        </Col>
      </Row>
      <div className="mt-4 bg-white rounded-lg">
        <div className="flex justify-between p-4">
          <Search
            placeholder={`Search ${TITLE[1]}`}
            enterButton={<SearchOutlined />}
            className="w-max"
            size="middle"
            onChange={(event) => {
              setInput(event.target.value);
            }}
            value={input}
          />
          {data?.queryCount !== data?.totalCount && (
            <Text className="flex items-center">
              Showing {data.queryCount} of {data?.totalCount} entries
              <Button
                type="link"
                onClick={() => {
                  setInput("");
                  setTableState({ ...tableState, filters: {} });
                }}
              >
                Clear Search
              </Button>
            </Text>
          )}
          <Button icon={<SyncOutlined />} onClick={onRefresh} />
        </div>
        <Row className="mt-2">
          <Col span={24}>
            <Table
              loading={loading}
              dataSource={data?.list}
              columns={columns}
              pagination={{
                ...(tableState?.pagination || {}),
                current: +tableState?.pagination?.current || 1,
                total: data?.queryCount,
              }}
              bordered
              className="overflow-table"
              onChange={(pagination, filters, sorter) => {
                delete (sorter as any).column;
                setTableState({ pagination, filters, sorter });
              }}
              rowKey={(record) => record._id}
              size="small"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
