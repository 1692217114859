import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Typography,
  Input,
  Select,
  Button,
  Space,
  Spin,
  Divider,
  InputNumber,
} from "antd";
import { TITLE, BASE_PATH } from ".";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import querystring from "query-string";
import service from "@services/client";
import useBreadcrumbs from "@hooks/useBreadcrumbs";

const { Title } = Typography;
const { Option } = Select;

export default function EntityForm(props: any) {
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "Client Form" },
  ]);
  const [form] = Form.useForm();
  const [id, setId] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadLoading, setLoadLoading] = useState(false);
  const [dependentsLoading, setDependentsLoading] = useState(false);
  const [masters, setMasters] = useState<any>({});
  const setBreadcrumbs = useBreadcrumbs();
  const [devices, setDevices] = useState<any[]>([]);
  const [devicesLoading, setDevicesLoading] = useState(false);
  const [errors, setErrors] = useState<any>({
    basic: false,
    material: false,
    fluid: false,
    test: false,
  });
  const handleSubmit = (values: any) => {
    setLoading(true);
    if (id) {
      service
        .update({ ...values, _id: id })
        .then((response) => {
          if (!response.error) {
            navigate(BASE_PATH);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      service
        .create({ ...values })
        .then((response) => {
          if (!response.error) {
            navigate(BASE_PATH);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleCancel = () => {
    navigate(BASE_PATH);
  };
  useEffect(() => {
    const params: any = querystring.parse(location.search);
    if (!_.isEmpty(params)) {
      setId(params.entity);
      setLoadLoading(true);
      service
        .get(params.entity)
        .then((payload) => {
          form.setFieldsValue({ ...payload });
          loadDevices(payload._id);
        })
        .finally(() => setLoadLoading(false));
    }
  }, [location]);
  useEffect(() => {
    handleLoadDependencies();
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const handleLoadDependencies = async () => {
    try {
      setDependentsLoading(true);
      const promises = [];
      const [] = await Promise.all(promises);
      setMasters({});
    } catch (error) {
    } finally {
      setDependentsLoading(false);
    }
  };
  const handleAutoGeneratePassword = () => {
    form.setFieldsValue({
      credentials: {
        password: Math.random().toString(36).slice(-8),
      },
    });
  };

  const loadDevices = async (id: string) => {
    setDevicesLoading(true);
    service
      .getDevicesForClient(id)
      .then((res) => {
        setDevices(res);
      })
      .finally(() => {
        setDevicesLoading(false);
      });
  };
  return (
    <div>
      <Title level={3}>
        {id ? "Update" : "New"} {TITLE[0]}
      </Title>
      <Spin spinning={loadLoading}>
        <div className="mt-4 bg-white rounded-lg px-4 py-4">
          <Form layout="vertical" form={form} onFinish={handleSubmit} preserve>
            <div className="flex gap-x-4">
              <Form.Item
                className="w-1/2"
                label="Code"
                rules={[
                  { required: true, message: "Code is required" },
                  {
                    validator: async (_, value) => {
                      const response = await service.checkCode(
                        value?.trim().toUpperCase(),
                        id
                      );
                      return response.exists
                        ? Promise.reject("Code already exists")
                        : Promise.resolve();
                    },
                  },
                ]}
                name="code"
              >
                <Input placeholder="Enter Code" />
              </Form.Item>
              <Form.Item
                className="w-1/2"
                label="Company Name"
                rules={[{ required: true, message: "Name is required" }]}
                name={["name"]}
              >
                <Input placeholder="Enter Company Name" />
              </Form.Item>
            </div>
            <div className="border rounded px-2 py-2">
              <div>
                <span className="font-semibold">Address Information</span>
              </div>
              <Divider className="my-2" />
              <div className="flex gap-x-2">
                <Form.Item
                  className="w-1/2"
                  label="Address Line 1"
                  rules={[{ required: true, message: "Line 1 is required" }]}
                  name={["address", "address1"]}
                >
                  <Input placeholder="Address Line 1" />
                </Form.Item>
                <Form.Item
                  className="w-1/2"
                  label="Address Line 2 "
                  name={["address", "address2"]}
                >
                  <Input placeholder="Address Line 2 (Optional)" />
                </Form.Item>
              </div>
              <div className="flex gap-x-2">
                <Form.Item
                  className="w-1/3"
                  label="City"
                  rules={[{ required: true, message: "City is required" }]}
                  name={["address", "city"]}
                >
                  <Input placeholder="Enter City" />
                </Form.Item>
                <Form.Item
                  className="w-1/3"
                  label="State"
                  rules={[{ required: true, message: "State is required" }]}
                  name={["address", "state"]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select State"
                  >
                    <Option value="Andaman and Nicobar Islands">
                      Andaman and Nicobar
                    </Option>
                    <Option value="Andhra Pradesh">Andhra Pradesh</Option>
                    <Option value="Arunachal Pradesh">Arunachal Pradesh</Option>
                    <Option value="Assam">Assam</Option>
                    <Option value="Bihar">Bihar</Option>
                    <Option value="Chhattisgarh">Chhattisgarh</Option>
                    <Option value="Goa">Goa</Option>
                    <Option value="Gujarat">Gujarat</Option>
                    <Option value="Haryana">Haryana</Option>
                    <Option value="Himachal Pradesh">Himachal Pradesh</Option>
                    <Option value="Jharkhand">Jharkhand</Option>
                    <Option value="Karnataka">Karnataka</Option>
                    <Option value="Kerala">Kerala</Option>
                    <Option value="Madhya Pradesh">Madhya Pradesh</Option>
                    <Option value="Maharashtra">Maharashtra</Option>
                    <Option value="Manipur">Manipur</Option>
                    <Option value="Meghalaya">Meghalaya</Option>
                    <Option value="Mizoram">Mizoram</Option>
                    <Option value="Nagaland">Nagaland</Option>
                    <Option value="Odisha">Odisha</Option>
                    <Option value="Punjab">Punjab</Option>
                    <Option value="Rajasthan">Rajasthan</Option>
                    <Option value="Sikkim">Sikkim</Option>
                    <Option value="Tamil Nadu">Tamil Nadu</Option>
                    <Option value="Telangana">Telangana</Option>
                    <Option value="Tripura">Tripura</Option>
                    <Option value="Uttar Pradesh">Uttar Pradesh</Option>
                    <Option value="Uttarakhand">Uttarakhand</Option>
                    <Option value="West Bengal">West Bengal</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  className="w-1/3"
                  label="Pincode"
                  rules={[{ required: true, message: "Pincode is required" }]}
                  name={["address", "pincode"]}
                >
                  <Input placeholder="Enter Pincode" />
                </Form.Item>
              </div>
            </div>
            <div className="border rounded px-2 py-2 mt-3">
              <div>
                <span className="font-semibold">Contact Information</span>
              </div>
              <Divider className="my-2" />
              <div className="flex gap-x-2">
                <Form.Item
                  className="w-1/3"
                  label="Name"
                  rules={[{ required: true, message: "Name is required" }]}
                  name={["contact", "name"]}
                >
                  <Input placeholder="Enter Name" />
                </Form.Item>
                <Form.Item
                  className="w-1/3"
                  label="Email"
                  rules={[
                    { required: true, message: "State is required" },
                    { type: "email", message: "Please enter a valid email" },
                  ]}
                  name={["contact", "email"]}
                >
                  <Input placeholder="Enter Email" />
                </Form.Item>
                <Form.Item
                  className="w-1/3"
                  label="Phone"
                  rules={[{ required: true, message: "Phone is required" }]}
                  name={["contact", "phone"]}
                >
                  <Input placeholder="Enter Phone" />
                </Form.Item>
              </div>
            </div>
            <div className="border rounded px-2 py-2 mt-3">
              <div>
                <span className="font-semibold">License Information</span>
              </div>
              <Divider className="my-2" />
              <div className="flex gap-x-2">
                <Form.Item
                  className="w-full"
                  label="Number of Device Licenses"
                  rules={[
                    {
                      required: true,
                      message: "Number of Device Licenses is required",
                    },
                    {
                      validator: async (_, value) => {
                        const minimumLicenses = devices?.filter(
                          (d) => d.status === "ACTIVATED"
                        );
                        if (value < minimumLicenses.length) {
                          return Promise.reject(
                            `Minimum number of licenses required is ${minimumLicenses.length} due to number of Activated screens.`
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  name={["licenseCount"]}
                >
                  <InputNumber
                    placeholder="Enter the number of licenses"
                    className="w-full"
                  />
                </Form.Item>
              </div>
            </div>
            {!id && (
              <div className="border rounded px-2 py-2 mt-3">
                <div>
                  <span className="font-semibold">Access Information</span>
                </div>
                <Divider className="my-2" />
                <div className="flex gap-x-2">
                  <Form.Item
                    className="w-1/2"
                    label="Username"
                    rules={[
                      { required: true, message: "Username is required" },
                      {
                        validator: async (_, value) => {
                          const response = await service.checkUsername(
                            value?.trim().toLowerCase(),
                            id
                          );
                          return response.exists
                            ? Promise.reject("Username already exists")
                            : Promise.resolve();
                        },
                      },
                    ]}
                    name={["credentials", "username"]}
                  >
                    <Input placeholder="Enter Username" />
                  </Form.Item>
                  <Form.Item
                    className="w-1/2"
                    label="Password"
                    rules={[
                      { required: true, message: "Password is required" },
                      // Should contain at least one digit and one letter

                      {
                        pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                        message:
                          "Password should contain at least one digit and one letter",
                      },

                      // Should be atleast 8 characters
                      {
                        min: 8,
                        message: "Password should be atleast 8 characters",
                      },
                    ]}
                    name={["credentials", "password"]}
                    extra={
                      <a
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAutoGeneratePassword();
                        }}
                        className="select-none"
                      >
                        Auto-Generate Password
                      </a>
                    }
                  >
                    <Input.Password placeholder="Enter Password" />
                  </Form.Item>
                </div>
              </div>
            )}
            <div className="mt-3">
              <Form.Item noStyle>
                <Space>
                  <Button type="default" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Spin>
    </div>
  );
}
