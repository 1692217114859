import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const TYPE = "telemtry";

export interface TelemetryState {
  metrics: any;
}
const INITIAL_STATE: TelemetryState = {
  metrics: {},
};

const slice = createSlice({
  name: TYPE,
  initialState: INITIAL_STATE,
  reducers: {
    addMetric: (state, action) => {
      const { deviceId, payload, timestamp } = action.payload;
      if (!state.metrics) {
        state.metrics = {};
      }
      if (!state.metrics[deviceId]) {
        state.metrics[deviceId] = [];
      }
      state.metrics[deviceId].push({ ...payload, timestamp });
    },
  },
  extraReducers: (builder) => {},
});
export const { addMetric } = slice.actions;
export default slice.reducer;
