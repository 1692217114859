import { EventWrapper } from "@components/EventWrapper";
import { StatusBadge } from "@views/Campaigns";
import { Button, Dropdown } from "antd";
import dayjs from "dayjs";
import { memo, useEffect, useMemo, useState } from "react";
import { dayjsLocalizer, EventWrapperProps } from "react-big-calendar";
import { DeleteOutlined } from "@ant-design/icons";
const localizer = dayjsLocalizer(dayjs);

function Event(props: any) {
  return (
    <div className="flex flex-col gap-y-1 py-2 h-full">
      <div>
        <StatusBadge status={props.event.status || "Current"} />
      </div>
      <div className="text-xs">{props.event.campaignName}</div>
      <div className="italic ">{props.event.title}</div>
      {props.event.deviceGroups && (
        <div className="italic flex flex-col gap-y-2 border rounded px-2 py-1 text-xs mt-1">
          Screen Groups:
          <div className="flex flex-col gap-y-1">
            {props.event.deviceGroups.map((dg) => (
              <span key={dg.id}>{dg.name}</span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function useCalendarComponents() {
  const components = useMemo(
    () => ({
      event: Event,
      // eventWrapper: ,
    }),
    []
  );

  return {
    components,
    localizer,
    views: { day: true, week: true, month: true },
  };
}
export default useCalendarComponents;
