import { Alert, Button, Divider, Form, Input, Select } from "antd";
import SetupTVIcon from "@components/Vectors/SetupTV";
import SuccessTVIcon from "@components/Vectors/SuccessTV";
import WarningTVIcon from "@components/Vectors/WarningTV";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAPIWrapper from "@hooks/useAPIWrapper";
import service from "@services/device";
// https://api.dev.otistv.org/setup/3db6cd07-0dc4-4e65-94fc-89c1dfca118d?socket=DSS23F1DS&deviceInfo=%22%7B%5C%22deviceId%5C%22%3A%20%5C%22TV1234567890abcdef%5C%22%2C%5C%22deviceModel%5C%22%3A%20%5C%22Sony%20Bravia%20X900H%5C%22%2C%5C%22manufacturer%5C%22%3A%20%5C%22Sony%5C%22%2C%5C%22osVersion%5C%22%3A%20%5C%22Android%20TV%2010%5C%22%2C%5C%22apiLevel%5C%22%3A%2029%2C%5C%22display%5C%22%3A%20%7B%20%20%5C%22screenSize%5C%22%3A%20%5C%2265%20inches%5C%22%2C%20%20%5C%22resolution%5C%22%3A%20%5C%223840x2160%5C%22%2C%20%20%5C%22refreshRate%5C%22%3A%20%5C%22120Hz%5C%22%7D%2C%5C%22network%5C%22%3A%20%7B%20%20%5C%22connectionType%5C%22%3A%20%5C%22Ethernet%5C%22%2C%20%20%5C%22networkOperator%5C%22%3A%20%5C%22Comcast%5C%22%2C%20%20%5C%22isRoaming%5C%22%3A%20false%7D%2C%5C%22storage%5C%22%3A%20%7B%20%20%5C%22internal%5C%22%3A%20%7B%5C%22totalSpace%5C%22%3A%20%5C%2216GB%5C%22%2C%5C%22availableSpace%5C%22%3A%20%5C%2210GB%5C%22%20%20%7D%7D%2C%5C%22memory%5C%22%3A%20%7B%20%20%5C%22totalRAM%5C%22%3A%20%5C%223GB%5C%22%2C%20%20%5C%22availableRAM%5C%22%3A%20%5C%221.5GB%5C%22%7D%2C%5C%22location%5C%22%3A%20%7B%20%20%5C%22country%5C%22%3A%20%5C%22United%20States%5C%22%2C%20%20%5C%22timeZone%5C%22%3A%20%5C%22Pacific%20Standard%20Time%5C%22%7D%2C%5C%22sensors%5C%22%3A%20%5B%20%20%5C%22Ambient%20Light%20Sensor%5C%22%5D%2C%5C%22bluetooth%5C%22%3A%20%7B%20%20%5C%22status%5C%22%3A%20%5C%22Enabled%5C%22%2C%20%20%5C%22pairedDevices%5C%22%3A%20%5B%5C%22Soundbar%5C%22%2C%5C%22Game%20Controller%5C%22%20%20%5D%7D%2C%5C%22wifi%5C%22%3A%20%7B%20%20%5C%22status%5C%22%3A%20%5C%22Connected%5C%22%2C%20%20%5C%22connectedNetwork%5C%22%3A%20%5C%22Home%20WiFi%5C%22%7D%2C%5C%22installedApps%5C%22%3A%20%5B%20%20%5C%22Netflix%5C%22%2C%20%20%5C%22YouTube%5C%22%2C%20%20%5C%22Amazon%20Prime%20Video%5C%22%5D%2C%5C%22audioOutput%5C%22%3A%20%7B%20%20%5C%22speakerConfiguration%5C%22%3A%20%5C%22Stereo%5C%22%2C%20%20%5C%22supportedAudioFormats%5C%22%3A%20%5B%5C%22Dolby%20Atmos%5C%22%2C%5C%22DTS%5C%22%20%20%5D%7D%7D%22

function RegisterDevice(props: any) {
  const [form] = Form.useForm();
  const handleRequest = useAPIWrapper();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDevice, setLoadingDevice] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const params = useParams();
  const [device, setDevice] = useState<any>(null);
  const [socket, setSocket] = useState<any>(null);
  const [status, setStatus] = useState<string>("Pending");
  const [tags, setTags] = useState<any[]>([]);
  const [tagsLoading, setTagsLoading] = useState<boolean>(false);
  const loadDevice = async (id: string) => {
    try {
      setLoadingDevice(true);
      const response = await service.getByDeviceId(id);
      if (response) {
        setStatus("Duplicate");
      }
    } catch (error) {
    } finally {
      setLoadingDevice(false);
    }
  };
  const loadTags = async () => {
    try {
      setTagsLoading(true);
      const response = await service.getUniqueTags();
      setTags(response.filter((t) => !!t));
    } catch (error) {
      console.log(error);
    } finally {
      setTagsLoading(false);
    }
  };

  const getSavedDevice = async (id: string) => {
    try {
      setLoadingDevice(true);
      const response = await service.getSavedDevice(id);
      if (response) {
        setDevice(response);

        if (response.isRegistered) {
          setStatus("Duplicate");
        }
      }
    } catch (error) {
    } finally {
      setLoadingDevice(false);
    }
  };
  const getConnectionStatus = async () => {
    try {
      const response = await service.getDeviceConnectionStatus(params.id);
      setIsConnected(response?.isConnected);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (device?.deviceId) {
      const interval = setInterval(() => {
        getConnectionStatus();
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [device]);
  useEffect(() => {
    if (params.id) {
      getSavedDevice(params.id as string);
      loadTags();
    }
  }, [params]);
  const handleRegister = async () => {
    try {
      const values = form.getFieldsValue();
      setLoading(true);
      const payload = { device: { ...device, ...values }, socket: socket };
      const response = await handleRequest(() =>
        service.create({ ...payload })
      );
      if (response) {
        setStatus("Success");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const payload = {
    header: {
      title:
        status === "Pending"
          ? "Register Screen"
          : status === "Success"
          ? "Registration Successful"
          : status === "Finished"
          ? ""
          : "Screen Already Registered",
    },
    body: {
      title:
        status === "Pending"
          ? "You are registering the following screen:"
          : status === "Success"
          ? "The following screen has been registered"
          : "",
      icon:
        status === "Pending" ? (
          <SetupTVIcon className="text-3xl" />
        ) : status === "Success" ? (
          <SuccessTVIcon className="text-3xl" />
        ) : status === "Finished" ? (
          <></>
        ) : (
          <WarningTVIcon className="text-3xl" />
        ),
      content: [
        {
          title: "Status",
          value: isConnected ? (
            <span className="font-semibold text-sm text-green-500">ONLINE</span>
          ) : (
            <span className="font-semibold text-sm text-gray-500">
              UNAVAILABLE
            </span>
          ),
        },
        {
          title: "Brand",
          value: device?.manufacturer,
        },
        {
          title: "Model",
          value: device?.deviceModel,
        },
        {
          title: "OS",
          value: device?.osVersion,
        },
        {
          title: "Device ID",
          value: device?.deviceId,
        },
        {
          title: "Resolution",
          value: device?.display?.resolution,
        },
      ],
    },
    footer: {
      alert:
        status === "Success" ? (
          isConnected ? (
            <Alert
              message={
                <div>
                  <div>Your device should refresh automatically.</div>
                  <div>
                    Please click ‘Refresh’ in the Otis app to refresh manually.
                  </div>
                </div>
              }
              type="info"
              showIcon
            />
          ) : (
            <Alert
              message={
                <div>
                  <div>Connection to your device has been lost.</div>
                  <div>
                    Please click ‘Refresh’ in the Otis app to refresh manually.
                  </div>
                </div>
              }
              type="info"
              showIcon
            />
          )
        ) : undefined,
      button:
        status === "Pending"
          ? {
              text: "Register",
              loading: loading,
              onClick: handleRegister,
              disabled: !device,
            }
          : {
              text: "Finish",
              loading: loading,
              onClick: () => setStatus("Finished"),
              disabled: !device,
            },
    },
  };

  return (
    <div className="container mx-auto  py-4 flex flex-col justify-between  stretch-to-fill">
      {status === "Finished" ? (
        <div className="flex flex-col items-center h-full justify-center gap-y-4">
          <div className="text-xl font-bold text-center">
            Thank you for using Ottis TV Digital Signage
          </div>
          <div>You can safely close this window.</div>
        </div>
      ) : (
        <>
          <div className="  text-2xl font-semibold text-center">
            {payload.header.title}
          </div>
          <div className="  flex flex-col items-center gap-y-4">
            <div className="text-lg">{payload.body.title}</div>
            <div className="border border-black p-4 rounded flex flex-col items-center gap-y-4">
              {payload.body.icon}
              <div>
                {payload.body.content.map((item: any, index: number) => (
                  <div className="text-lg" key={index}>
                    <span className="uppercase font-semibold text-sm">
                      {item.title}:
                    </span>{" "}
                    {item.value}
                  </div>
                ))}
              </div>
              <Divider className="my-2" />
              <div className="w-full flex justify-start">
                <Form form={form} className="w-full">
                  <Form.Item
                    className="w-full"
                    label={
                      <span className="uppercase font-semibold text-sm">
                        LOCATION
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter the location",
                      },
                    ]}
                    name="location"
                  >
                    <Input placeholder="Enter Location" className="w-full" />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span className="uppercase font-semibold text-sm">
                        TAGS
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter tags",
                      },
                    ]}
                    name="tags"
                  >
                    <Select mode="tags" placeholder="Select or Enter Tags">
                      {tags.map((tag) => (
                        <Select.Option key={tag._id} value={tag._id}>
                          {tag._id}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </>
      )}

      {status !== "Finished" && (
        <div className="flex flex-col justify-end mb-2">
          {payload.footer.alert}
          <div className="flex items-center mt-4 w-full">
            <Button
              type="primary"
              size="large"
              block
              onClick={payload.footer.button.onClick}
              disabled={payload.footer.button.disabled}
              loading={payload.footer.button.loading}
            >
              {payload.footer.button.text}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default RegisterDevice;
