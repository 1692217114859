import RegisterDevice from "@views/RegisterDevice";
import { Layout } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import * as authActions from "../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Header from "@components/Header";
import { Store } from "@store/index";
import Logout from "../utils/logout";
import Campaigns from "@views/Campaigns";
import CompositionForm from "@views/Compositions/Form";
import CampaignForm from "@views/Campaigns/Form";

const { Content } = Layout;

export default function FullScreenLayout(props: any) {
  return (
    <Layout className="min-h-screen">
      {/* <Header /> */}
      <Content className="mt-4">
        <Routes>
          <Route path="/campaigns/*" element={<Campaigns />} />
          <Route path="/compositions/new" element={<CompositionForm />} />
          <Route path="/compositions/edit" element={<CompositionForm />} />
          <Route path="/campaigns/new" element={<CampaignForm />} />
          <Route path="/campaigns/edit" element={<CampaignForm />} />
        </Routes>
      </Content>
    </Layout>
  );
}
