import { useEffect, useState, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Input,
  Select,
  Table,
  Space,
  Popover,
  List,
  Checkbox,
  Form,
  Modal,
  Divider,
  Tag,
  Popconfirm,
  message,
  Dropdown,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
  ExportOutlined,
  LoadingOutlined,
  SyncOutlined,
  CalendarFilled,
  DownOutlined,
  FilterOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { FaRegCalendarCheck } from "react-icons/fa";
import { debounce } from "throttle-debounce";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { TITLE, BASE_PATH, StatusBadge } from ".";
import { IoArchiveOutline } from "react-icons/io5";
import { BASE_PATH as CAMPAIGN_BASE_PATH } from "../Campaigns";
import service from "@services/composition";
import deviceGroupService from "@services/deviceGroup";
import useSearchParamsTableState from "hooks/useSearchParamsTableState";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import * as compositionActions from "@store/compositionSlice";
import { TbColumns3 } from "react-icons/tb";
import useSelectedColumnsState from "@hooks/useSelectedColumnsState";
import saveAs from "file-saver";
import { titleCase } from "title-case";
import DatePicker from "@components/Core/DatePicker";
import { MdOutlineArchive, MdOutlineUnarchive } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const MASTER_KEYS = [{ value: "deviceGroups", service: deviceGroupService }];
const ADDITIONAL_COLUMNS = [
  {
    title: "Active",
    dataIndex: "isActive",
    key: "isActive",
    render: (text) => (text ? "Yes" : "No"),
  },
  { key: "remote", title: "Remote", dataIndex: "remote" },
  { key: "osVersion", title: "OS Version", dataIndex: "osVersion" },
  { key: "platformName", title: "Platform Name", dataIndex: "platformName" },

  {
    key: "launcherVersion",
    title: "Launcher Version",
    dataIndex: "launcherVersion",
  },
  { key: "contentType", title: "Content Type", dataIndex: "contentType" },
  { key: "stage", title: "Stage", dataIndex: "stage" },
  { key: "flag", title: "Flag", dataIndex: "flag" },
  {
    key: "recordVersion",
    title: "Record Version",
    dataIndex: "recordVersion",
  },
  {
    title: "Updated",
    dataIndex: "updatedAt",
    key: "updatedAt",

    render: (text, record, index) => dayjs(text).format("DD/MM/YYYY"),
  },
];

export default function Entities(props: any) {
  const breadcrumbsRef = useRef([{ label: TITLE[1], url: BASE_PATH }]);
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({
      onFetch: service.paginate,
      defaultFilters: { isArchived: [false] },
    });
  const dispatch = useDispatch();
  const dateFilterInput = useRef<any>(null);
  const compositionState = useSelector((state: any) => state.composition);
  const navigate = useNavigate();
  const setBreadcrumbs = useBreadcrumbs();
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [dateFilter, setDateFilter] =
    useState<[Dayjs | undefined, Dayjs | undefined]>();
  const { selectedColumns, setSelectedColumns } = useSelectedColumnsState({
    breadcrumbs: breadcrumbsRef.current,
    availableColumns: ADDITIONAL_COLUMNS,
    defaultSelected: [],
  });

  useEffect(() => {
    loadMastersDebounced();
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const [masters, setMasters] = useState<any>({});

  const getColumnsFilters = useCallback(
    (key: string) => {
      return masters[key]
        ? masters[key].map((entity) => ({
            text: entity.name,
            value: entity._id,
          }))
        : [];
    },
    [masters]
  );
  const loadMasters = useCallback(() => {
    const promises: any[] = [];
    for (let masterKey of MASTER_KEYS) {
      promises.push(
        masterKey.service
          .getAll()
          .then((p) => ({ key: masterKey.value, data: p }))
      );
    }
    Promise.all(promises).then((payload) => {
      const output: any = {};
      payload.forEach((m) => (output[m.key] = m.data));
      setMasters(output);
    });
  }, []);

  const loadMastersDebounced = useCallback(debounce(300, loadMasters), []);
  const handleArchive = async (id: string) => {
    try {
      setArchiveLoading(true);
      await service.archive(id);
      message.success("Archived successfully");
      onRefresh();
    } catch (error) {
      message.error("Failed to archive");
    } finally {
      setArchiveLoading(false);
    }
  };
  const handleUnarchive = async (id: string) => {
    try {
      setArchiveLoading(true);
      await service.unarchive(id);
      message.success("Un-Archival successful");
      onRefresh();
    } catch (error) {
      message.error("Failed to un-archive");
    } finally {
      setArchiveLoading(false);
    }
  };
  const columns: any[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (name, record) => (
        <div className="flex w-full justify-between">
          {name} {record.isArchived && <Tag color="gray">ARCHIVED</Tag>}
        </div>
      ),
    },
    {
      title: "Orientation",
      key: "orientation",
      dataIndex: "orientation",
      filters: [
        { text: "Portrait", value: "portrait" },
        { text: "Landscape", value: "landscape" },
      ],
      render: (text) => titleCase(text || ""),
    },
    {
      title: "Total Duration",
      key: "totalDuration",
      dataIndex: "assets",
      render: (text) => (
        <div>{text.reduce((acc, curr) => acc + curr.duration, 0)} secs</div>
      ),
    },
    {
      title: "Min. Resolution",
      key: "minimumResolution",
      filters: ["480p", "720p", "1080p", "1440p", "2160p"].map((res) => ({
        text: res,
        value: res,
      })),
      dataIndex: "minimumResolution",
    },
    {
      title: "Created At",
      key: "createdAt",
      dataIndex: "createdAt",
      filterIcon: (filtered: boolean) => (
        <CalendarFilled className={`${filtered ? "color-primary-500" : ""}`} />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => dateFilterInput.current?.focus(), 100);
        }
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div>
          <div className="pt-2 px-2">
            <RangePicker
              ref={dateFilterInput}
              value={selectedKeys.map((key) => dayjs(key)) as any}
              onChange={(value) => {
                setDateFilter(value);
                if (value) {
                  setSelectedKeys(value?.map((v) => v.toISOString()));
                }
              }}
            />
          </div>
          <Divider className="my-2" />
          <div className="pb-2 flex justify-between px-2">
            <Button
              size="small"
              type="link"
              disabled={!dateFilter || dateFilter[0] === undefined}
              onClick={() => {
                clearFilters();
                setDateFilter([undefined, undefined]);
              }}
            >
              Reset
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                confirm({ closeDropdown: true });
              }}
            >
              OK
            </Button>
          </div>
        </div>
      ),
      render: (text) => dayjs(text).format("DD/MM/YYYY"),
    },
    {
      title: "Actions",
      key: "actions",
      align: "right",
      render: (text, record, index) => (
        <Space>
          {record.isArchived ? (
            <Tooltip title="Unarchive">
              <Popconfirm
                title="Are you sure you want to un-archive this?"
                onConfirm={() => handleUnarchive(record._id)}
                okText="Yes"
                cancelText="No"
                placement="left"
              >
                <Button
                  icon={<MdOutlineUnarchive className="text-lg" />}
                  type="link"
                  size="small"
                />
              </Popconfirm>
            </Tooltip>
          ) : (
            <Tooltip title="Archive">
              <Popconfirm
                title="Are you sure you want to archive this?"
                onConfirm={() => handleArchive(record._id)}
                okText="Yes"
                cancelText="No"
                placement="left"
              >
                <Button
                  icon={<MdOutlineArchive className="text-lg" />}
                  type="link"
                  size="small"
                />
              </Popconfirm>
            </Tooltip>
          )}

          <Tooltip title="Schedule">
            <Button
              icon={<FaRegCalendarCheck />}
              onClick={() => handleSchedule(record._id)}
              type="link"
              size="small"
            />
          </Tooltip>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleForm(record._id)}
            type="link"
            size="small"
          />
          <Button
            icon={<ArrowRightOutlined />}
            onClick={() => handleView(record._id)}
            type="link"
            size="small"
          />
        </Space>
      ),
    },
    ...(selectedColumns as any[]),
  ];
  const handleSchedule = async (compositionId: string) => {
    navigate(`/fs${CAMPAIGN_BASE_PATH}/new?composition=${compositionId}`);
  };

  const handleForm = (entityId?: string) => {
    if (entityId) {
      navigate(`/fs${BASE_PATH}/edit?entity=${entityId}`);
    } else {
      navigate(`/fs${BASE_PATH}/new`);
    }
  };
  const handleView = (entityId?: string) => {
    navigate(`${BASE_PATH}/${entityId}`);
  };
  const handleBulk = () => {
    navigate(`${BASE_PATH}/bulk`);
  };
  return (
    <div>
      <Modal
        open={!!compositionState.isNewlyCreated}
        onOk={() => {
          dispatch(compositionActions.setNewlyCreated(null));
          handleSchedule(compositionState.isNewlyCreated);
        }}
        title="Schedule Now"
        onCancel={() => dispatch(compositionActions.setNewlyCreated(null))}
      >
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-2 items-center">
            <CheckCircleFilled className="text-green-500" />
            Composition created successfully!
          </div>
          <Text>Let's schedule this composition now?</Text>
        </div>
      </Modal>
      <div className="flex justify-between items-center">
        <div className="font-semibold text-xl">All {TITLE[1]}</div>
        <Space>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handleForm()}
          >
            New {TITLE[0]}
          </Button>
        </Space>
      </div>

      <div className="mt-4 bg-white rounded-lg">
        <div className="flex justify-between p-4">
          <Search
            placeholder={`Search ${TITLE[1]}`}
            enterButton={<SearchOutlined />}
            className="w-max"
            size="middle"
            onChange={(event) => {
              setInput(event.target.value);
            }}
            value={input}
          />
          {data?.queryCount !== data?.totalCount && (
            <Text className="flex items-center">
              Showing {data.queryCount} of {data?.totalCount} entries
              <Button
                type="link"
                onClick={() => {
                  setInput("");
                  setTableState({ ...tableState, filters: {} });
                }}
              >
                Clear Search
              </Button>
            </Text>
          )}
          <div className="flex gap-x-2 items-baseline">
            <Dropdown
              overlay={
                <div className="bg-white rounded-lg shadow-lg p-3">
                  <Checkbox.Group
                    onChange={(values) => {
                      setTableState({
                        ...tableState,
                        filters: {
                          ...tableState.filters,
                          isArchived: values.includes("includeArchived")
                            ? [true, false]
                            : [false],
                        },
                      });
                    }}
                    value={
                      tableState?.filters?.isArchived?.includes("true")
                        ? ["includeArchived"]
                        : []
                    }
                  >
                    <Checkbox value="includeArchived">
                      Include Archived Items
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              }
              trigger={["click"]}
            >
              <Button icon={<FilterOutlined />} />
            </Dropdown>
            <Button icon={<SyncOutlined />} onClick={onRefresh} />
          </div>
        </div>

        <Row className="mt-2">
          <Col span={24}>
            <Table
              loading={loading}
              dataSource={data?.list}
              columns={columns}
              pagination={{
                ...(tableState?.pagination || {}),
                total: data?.queryCount,
              }}
              bordered
              className="overflow-table"
              onChange={(pagination, filters, sorter) => {
                delete (sorter as any).column;
                setTableState({ pagination, filters, sorter });
              }}
              rowKey={(record) => record._id}
              size="small"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
