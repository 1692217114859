import { Store } from "@store/index";
import { Progress } from "antd";
import { memo } from "react";
import { useSelector } from "react-redux";

function TotalUsage(props: any) {
  const dashboardState = useSelector((state: Store) => state.dashboard);
  const usage =
    dashboardState.usage.total > 1000000000
      ? `${(dashboardState.usage.total / 1000000000).toFixed(2)} GB`
      : `${(dashboardState.usage.total / 1000000).toFixed(2)} MB`;
  return (
    <div>
      <Progress
        trailColor="#dedede"
        percent={(dashboardState.usage.total * 100) / 10000000000}
        format={(percent) => `${percent.toFixed(2)} %`}
      />
      {usage} used of 10 GB
    </div>
  );
}

export default memo(TotalUsage);
