export default function Android9(props: any) {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: 30 }}
    >
      <g clip-path="url(#clip0_93_84)">
        <path
          d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
          fill="#083042"
        />
        <path
          d="M416.233 236.621H405.562C404.097 236.621 402.909 235.433 402.909 233.968V224.12C402.909 222.655 404.097 221.467 405.562 221.467H428.934C430.399 221.467 431.587 222.655 431.587 224.12V291.016C431.587 292.481 430.399 293.669 428.934 293.669H418.886C417.421 293.669 416.233 292.481 416.233 291.016V236.621Z"
          fill="#F86734"
        />
        <path
          d="M455.512 236.621H444.84C443.375 236.621 442.187 235.433 442.187 233.968V224.12C442.187 222.655 443.375 221.467 444.84 221.467H468.212C469.677 221.467 470.865 222.655 470.865 224.12V291.016C470.865 292.481 469.677 293.669 468.212 293.669H458.164C456.699 293.669 455.511 292.481 455.511 291.016V236.621H455.512Z"
          fill="#F86734"
        />
        <path
          d="M364.119 408.929C366.515 408.929 368.458 406.986 368.458 404.59C368.458 402.194 366.515 400.251 364.119 400.251C361.722 400.251 359.78 402.194 359.78 404.59C359.78 406.986 361.722 408.929 364.119 408.929Z"
          fill="#D6F0FF"
        />
        <path
          d="M275.463 443.215C278.137 443.215 280.305 441.047 280.305 438.373C280.305 435.699 278.137 433.531 275.463 433.531C272.789 433.531 270.621 435.699 270.621 438.373C270.621 441.047 272.789 443.215 275.463 443.215Z"
          fill="#D6F0FF"
        />
        <path
          d="M184.516 429.547C187.477 429.547 189.878 427.146 189.878 424.185C189.878 421.224 187.477 418.823 184.516 418.823C181.554 418.823 179.154 421.224 179.154 424.185C179.154 427.146 181.554 429.547 184.516 429.547Z"
          fill="#D6F0FF"
        />
        <path
          d="M110.417 370.975C113.672 370.975 116.311 368.336 116.311 365.081C116.311 361.826 113.672 359.187 110.417 359.187C107.162 359.187 104.523 361.826 104.523 365.081C104.523 368.336 107.162 370.975 110.417 370.975Z"
          fill="#D6F0FF"
        />
        <path
          d="M75.9399 283.031C79.4938 283.031 82.3749 280.15 82.3749 276.596C82.3749 273.042 79.4938 270.161 75.9399 270.161C72.3859 270.161 69.5049 273.042 69.5049 276.596C69.5049 280.15 72.3859 283.031 75.9399 283.031Z"
          fill="#D6F0FF"
        />
        <path
          d="M89.4769 192.532C93.3341 192.532 96.4609 189.405 96.4609 185.548C96.4609 181.691 93.3341 178.564 89.4769 178.564C85.6198 178.564 82.4929 181.691 82.4929 185.548C82.4929 189.405 85.6198 192.532 89.4769 192.532Z"
          fill="#D6F0FF"
        />
        <path
          d="M147.972 118.619C152.135 118.619 155.51 115.244 155.51 111.081C155.51 106.918 152.135 103.543 147.972 103.543C143.809 103.543 140.434 106.918 140.434 111.081C140.434 115.244 143.809 118.619 147.972 118.619Z"
          fill="#D6F0FF"
        />
        <path
          d="M236.628 82.895C241.099 82.895 244.724 79.2703 244.724 74.799C244.724 70.3277 241.099 66.703 236.628 66.703C232.157 66.703 228.532 70.3277 228.532 74.799C228.532 79.2703 232.157 82.895 236.628 82.895Z"
          fill="#D6F0FF"
        />
        <path
          d="M327.091 95.6211C331.873 95.6211 335.749 91.7447 335.749 86.9631C335.749 82.1814 331.873 78.3051 327.091 78.3051C322.309 78.3051 318.433 82.1814 318.433 86.9631C318.433 91.7447 322.309 95.6211 327.091 95.6211Z"
          fill="#D6F0FF"
        />
        <path
          d="M401.052 155.409C406.146 155.409 410.275 151.28 410.275 146.186C410.275 141.092 406.146 136.963 401.052 136.963C395.958 136.963 391.829 141.092 391.829 146.186C391.829 151.28 395.958 155.409 401.052 155.409Z"
          fill="#D6F0FF"
        />
        <path
          d="M255.449 385.677C326.202 385.677 383.558 328.321 383.558 257.568C383.558 186.815 326.202 129.459 255.449 129.459C184.696 129.459 127.34 186.815 127.34 257.568C127.34 328.321 184.696 385.677 255.449 385.677Z"
          fill="#3DDB85"
        />
        <path
          d="M339.231 279.044C351.092 279.044 360.707 269.429 360.707 257.568C360.707 245.707 351.092 236.092 339.231 236.092C327.37 236.092 317.755 245.707 317.755 257.568C317.755 269.429 327.37 279.044 339.231 279.044Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_93_84">
          <rect width="512" height="512" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
