import React, { useEffect, useRef, useState } from "react";

function VideoPlayer(props: any) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [hasPlayed, setHasPlayed] = useState(false);
  useEffect(() => {
    if (props.currentSlide === props.index) {
      if (videoRef.current && hasPlayed) {
        videoRef.current.play();
      }
    } else {
      setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.currentTime = 0;
        }
      }, 1000);
    }
  }, [props.currentSlide, props.index]);
  return (
    <div className="flex items-center h-full">
      <video
        ref={videoRef}
        controls={false}
        preload="auto"
        className="w-full h-auto object-contain"
        autoPlay
        onEnded={() => setHasPlayed(true)}
      >
        <source src={props.src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoPlayer;
