import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import groupService from "../services/deviceGroup";
const TYPE = "composition";

export interface CompositionState {
  isNewlyCreated: string;
}

const INITIAL_STATE: CompositionState = {
  isNewlyCreated: "",
};

const slice = createSlice({
  name: TYPE,
  initialState: INITIAL_STATE,
  reducers: {
    setNewlyCreated: (state, action) => {
      state.isNewlyCreated = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setNewlyCreated } = slice.actions;

export default slice.reducer;
