export default function Android9(props: any) {
  return (
    <svg
      viewBox="0 0 100 100.06"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: 30 }}
    >
      <path
        d="M53.6 99.96c7.721-.552 15.396-2.946 21.899-6.83l1.19-.711 7.21 7.212 8.086-8.084-6.362-6.364.407-.387c1.364-1.295 4.17-4.78 5.696-7.078 4.582-6.895 7.335-14.698 8.126-23.03.145-1.532.173-2.716.13-5.52-.057-3.807-.23-5.655-.812-8.678C95.491 21.366 80.91 6.049 61.946 1.385 57.917.395 54.596.005 50.108 0c-3.271-.005-4.756.093-7.497.494-18.708 2.733-34.474 16.09-40.27 34.121C.562 40.145-.1 44.832.012 51.09c.065 3.633.228 5.323.808 8.395 1.367 7.228 4.433 14.223 8.896 20.296 2.87 3.907 7.083 8.057 11.117 10.953 7.583 5.445 16.643 8.67 26.04 9.268 1.391.089 5.24.065 6.726-.041z"
        fill="#77c360"
      />
      <path
        d="m76.257 92.077-7.79-7.713-1.833.847c-4.846 2.241-9.283 3.362-14.45 3.651-11.107.621-21.705-3.424-29.622-11.306-5.515-5.49-9.065-12.152-10.651-19.986-.563-2.778-.68-4.437-.602-8.534.068-3.621.163-4.54.73-7.074 1.374-6.133 3.975-11.533 7.917-16.435 1.24-1.54 4.099-4.383 5.665-5.632 5.79-4.616 12.02-7.257 19.661-8.335 2.014-.284 7.396-.285 9.437-.001 7.411 1.029 13.519 3.564 19.245 7.987 1.597 1.234 5.14 4.733 6.404 6.324a39.309 39.309 0 0 1 7.52 15.732c.764 3.37.883 4.53.872 8.527-.008 3.133-.044 3.86-.264 5.301-1.23 8.067-4.522 15.096-9.753 20.821-.473.518-.86.985-.86 1.037 0 .052 3.227 3.323 7.172 7.268l7.173 7.174-4.032 4.034c-2.218 2.218-4.102 4.098-4.134 4.096-.032-.002-3.52-3.541-7.805-7.783zM54.142 77.043c.603-.1 1.441-.258 1.864-.352.963-.214 3.466-.967 3.579-1.076.046-.045-4.988-5.153-11.187-11.352l-11.27-11.27-5.321 5.382c-2.927 2.96-5.321 5.435-5.321 5.501 0 .256 1.756 2.736 2.791 3.942 4.335 5.052 10.095 8.203 16.968 9.285 1.16.183 1.831.21 4.095.17 1.69-.03 3.12-.117 3.803-.23zm16.219-8.795c4.054-4.71 6.392-10.077 6.84-15.699.18-2.245.071-5.546-.237-7.26-1.157-6.421-4.754-12.538-9.739-16.558-2.998-2.419-6.834-4.325-10.604-5.27-2.11-.529-3.23-.666-5.947-.726-3.744-.084-6.28.285-9.495 1.383-10.897 3.72-18.496 14.163-18.496 25.416v1.574l14.463-14.462 16.19 16.19c8.905 8.906 16.228 16.191 16.272 16.191s.384-.351.753-.78z"
        fill="#fff"
      />
    </svg>
  );
}
