import { Row, Col, Space, Button, Typography } from "antd";

import { TITLE } from ".";

import { PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

export default function ListEntities(props: any) {
  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Title level={4}>All {TITLE[1]}</Title>
        </Col>
        <Col></Col>
        <Col>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                window.open(process.env.REACT_APP_MOSAIC_URL, "_blank")
              }
            >
              New {TITLE[0]}
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
}
