import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Form,
  Typography,
  Input,
  Select,
  Button,
  Space,
  Spin,
  Row,
  Col,
  Modal,
  Switch,
  Popconfirm,
  Checkbox,
  Radio,
  Divider,
  Table,
  Segmented,
  Tabs,
  Steps,
  Tooltip,
  message,
  Badge,
  Alert,
} from "antd";
import { TITLE, BASE_PATH } from ".";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import querystring from "query-string";
import assetService from "@services/asset";
import service from "@services/campaign";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import {
  CalendarOutlined,
  DeleteOutlined,
  EnterOutlined,
  RetweetOutlined,
  MenuOutlined,
  PlusOutlined,
  SearchOutlined,
  FilterOutlined,
  SyncOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  CalendarFilled,
  LeftOutlined,
} from "@ant-design/icons";
import DatePicker from "@components/Core/DatePicker";
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";
import dayjs, { Dayjs } from "dayjs";
import { nanoid } from "nanoid";
import deviceGroupService from "@services/deviceGroup";
import AssetSelector from "@components/AssetSelector";
import { EventWrapper } from "@components/EventWrapper";
import useCalendarComponents from "@hooks/useCalendarComponents";
import DnDScheduler from "@components/DnDScheduler";
import compositionService from "@services/composition";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {
  SortEnd,
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { titleCase } from "title-case";
import { MdPreview } from "react-icons/md";
import { debounce } from "throttle-debounce";
import PreviewPlaylist from "@components/PreviewPlaylist";
const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = dayjsLocalizer(dayjs); // or globalizeLocalizer
const { Title } = Typography;
const { Option } = Select;
const { TimePicker, RangePicker } = DatePicker;

const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
);
const SortableBody = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />
);

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const COMPOSITIONS_FILTER_CATEGORIES = [
  {
    label: "Min. Resolution",
    value: "resolution",
    selector: "checkbox",
    children: (props) => (
      <Checkbox.Group className="flex flex-col gap-y-3" {...props}>
        <Checkbox value="480" className="mx-0">
          480p
        </Checkbox>
        <Checkbox value="720" className="mx-0">
          720p
        </Checkbox>
        <Checkbox value="1080" className="mx-0">
          1080p
        </Checkbox>
        <Checkbox value="1440" className="mx-0">
          1440p
        </Checkbox>
        <Checkbox value="2160" className="mx-0">
          2160p
        </Checkbox>
      </Checkbox.Group>
    ),
  },
  {
    label: "Orientation",
    value: "orientation",
    selector: "checkbox",
    children: (props) => (
      <Checkbox.Group className="flex flex-col gap-y-3" {...props}>
        <Checkbox value="portrait" className="mx-0">
          Portrait
        </Checkbox>
        <Checkbox value="landscape" className="mx-0">
          Landscape
        </Checkbox>
      </Checkbox.Group>
    ),
  },
  {
    label: "Sort",
    value: "sort",
    selector: "radio",
    children: (props) => (
      <Radio.Group className="flex flex-col gap-y-3" {...props}>
        <Radio value="createdAt.1" className="mx-0">
          Created At - Earliest First
        </Radio>
        <Radio value="createdAt.-1" className="mx-0">
          Created At - Latest First
        </Radio>
      </Radio.Group>
    ),
  },
];

const GROUPS_FILTER_CATEGORIES = [
  {
    label: "Min. Resolution",
    value: "resolution",
    selector: "checkbox",
    children: (props) => (
      <Checkbox.Group className="flex flex-col gap-y-3" {...props}>
        <Checkbox value="480" className="mx-0">
          480p
        </Checkbox>
        <Checkbox value="720" className="mx-0">
          720p
        </Checkbox>
        <Checkbox value="1080" className="mx-0">
          1080p
        </Checkbox>
        <Checkbox value="1440" className="mx-0">
          1440p
        </Checkbox>
        <Checkbox value="2160" className="mx-0">
          2160p
        </Checkbox>
      </Checkbox.Group>
    ),
  },
  {
    label: "Orientation",
    value: "orientation",
    selector: "checkbox",
    children: (props) => (
      <Checkbox.Group className="flex flex-col gap-y-3" {...props}>
        <Checkbox value="PORTRAIT" className="mx-0">
          Portrait
        </Checkbox>
        <Checkbox value="LANDSCAPE" className="mx-0">
          Landscape
        </Checkbox>
      </Checkbox.Group>
    ),
  },
  {
    label: "Sort",
    value: "sort",
    selector: "radio",
    children: (props) => (
      <Radio.Group className="flex flex-col gap-y-3" {...props}>
        <Radio value="createdAt.1" className="mx-0">
          Created At - Earliest First
        </Radio>
        <Radio value="createdAt.-1" className="mx-0">
          Created At - Latest First
        </Radio>
      </Radio.Group>
    ),
  },
];

function CompositionsFilters(props: any) {
  const [category, setCategory] = useState<string>(
    COMPOSITIONS_FILTER_CATEGORIES[0].value
  );

  const filterCategory = COMPOSITIONS_FILTER_CATEGORIES.find(
    (fc) => fc.value === category
  );
  return (
    <Row className="border">
      <Col span={8} className="border-r">
        {COMPOSITIONS_FILTER_CATEGORIES.map((filter) => (
          <div
            className={`cursor-pointer flex items-center justify-between gap-x-2 px-2 pb-3 pt-3 ${
              category === filter.value ? "bg-gray-100" : ""
            }`}
            onClick={() => setCategory(filter.value)}
          >
            {filter.label}{" "}
            <Badge
              count={
                props.value?.[filter.value]
                  ? Array.isArray(props.value?.[filter.value])
                    ? props.value?.[filter.value].length
                    : 1
                  : 0
              }
            />
          </div>
        ))}
      </Col>
      <Col span={16} className="pl-4 pt-2 bg-gray-100">
        {filterCategory.children({
          value: props.value[category],
          onChange: (value) =>
            props.onChange({
              ...props.value,
              [category]:
                filterCategory.selector === "checkbox"
                  ? value
                  : value.target.value,
            }),
        })}
      </Col>
    </Row>
  );
}

function DeviceGroupsFilters(props: any) {
  const [category, setCategory] = useState<string>(
    GROUPS_FILTER_CATEGORIES[0].value
  );

  const filterCategory = GROUPS_FILTER_CATEGORIES.find(
    (fc) => fc.value === category
  );
  return (
    <Row className="border">
      <Col span={8} className="border-r">
        {GROUPS_FILTER_CATEGORIES.map((filter) => (
          <div
            className={`cursor-pointer flex items-center justify-between gap-x-2 px-2 pb-3 pt-3 ${
              category === filter.value ? "bg-gray-100" : ""
            }`}
            onClick={() => setCategory(filter.value)}
          >
            {filter.label}{" "}
            <Badge
              count={
                props.value?.[filter.value]
                  ? Array.isArray(props.value?.[filter.value])
                    ? props.value?.[filter.value].length
                    : 1
                  : 0
              }
            />
          </div>
        ))}
      </Col>
      <Col span={16} className="pl-4 pt-2 bg-gray-100">
        {filterCategory.children({
          value: props.value[category],
          onChange: (value) =>
            props.onChange({
              ...props.value,
              [category]:
                filterCategory.selector === "checkbox"
                  ? value
                  : value.target.value,
            }),
        })}
      </Col>
    </Row>
  );
}

function CompositionsFormControl(props: any) {
  const [selectedRowAddKeys, setSelectedRowAddKeys] = useState<string[]>([]);
  const [selectedRemoveRowKeys, setSelectedRemoveRowKeys] = useState<string[]>(
    []
  );
  const [compositionForPreview, setCompositionForPreview] = useState<any>();
  const [compositionForPreviewId, setCompositionForPreviewId] =
    useState<string>();
  const [compositionPreviewLoading, setCompositionPreviewLoading] =
    useState(false);
  const [compositionFilters, setCompositionFilters] = useState<any>({});
  const [compositionFiltersOpen, setCompositionFiltersOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [addedQuery, setAddedQuery] = useState("");
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [dataOpen, setDataOpen] = useState(false);
  const [dateFilter, setDateFilter] =
    useState<[Dayjs | undefined, Dayjs | undefined]>();
  const dateFilterInput = useRef<any>(null);

  useEffect(() => {
    handleLoadData();
  }, []);

  useEffect(() => {
    if (compositionForPreviewId) {
      setCompositionPreviewLoading(true);
      compositionService
        .get(compositionForPreviewId)
        .then((data) => {
          setCompositionForPreview(data);
        })
        .finally(() => setCompositionPreviewLoading(false));
    } else {
      setCompositionForPreview(undefined);
    }
  }, [compositionForPreviewId]);
  const handleLoadData = async () => {
    try {
      setLoading(true);
      const data = await compositionService.getAll();
      setData(data.map((item: any, index: number) => ({ ...item, index })));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        props.value.slice(),
        oldIndex,
        newIndex
      ).filter((el: any) => !!el);
      const updatedSelectedMedia = newData.map((item: any, index: number) => ({
        ...item,
        index,
      }));
      props.onChange(updatedSelectedMedia);
    }
  };

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<any> = ({
    className,
    style,
    ...restProps
  }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = props.value.findIndex(
      (x) => x._id === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} style={{ ...style }} />;
  };
  const handleMediaSelection = useCallback(() => {
    const addedMedia = data.filter((record) =>
      selectedRowAddKeys.includes(record._id)
    );
    const newSelectedMedia = [...addedMedia, ...(props.value || [])].map(
      (record, index) => ({
        ...record,
        index,
      })
    );
    props.onChange(newSelectedMedia);
    setSelectedRowAddKeys([]);
  }, [selectedRowAddKeys, data]);
  const handleMediaRemoval = useCallback(() => {
    const removedMedia = props.value.filter((record, index) =>
      selectedRemoveRowKeys.includes(record._id)
    );
    props.onChange(
      props.value.filter((record) => !removedMedia.includes(record))
    );
    setSelectedRemoveRowKeys([]);
  }, [selectedRemoveRowKeys, data]);
  const searchDebounced = useCallback(
    debounce(100, (value) => setQuery(value)),
    []
  );
  const addedSearchDebounced = useCallback(
    debounce(100, (value) => setAddedQuery(value)),
    []
  );

  return (
    <div>
      <Modal
        open={!!compositionForPreview}
        onCancel={() => setCompositionForPreview(false)}
        // title="Preview Composition"
        className="w-max"
        footer={null}
        closeIcon={<></>}
      >
        <Spin spinning={compositionPreviewLoading}>
          <PreviewPlaylist
            assets={compositionForPreview?.assets || []}
            orientation={compositionForPreview?.orientation || "landscape"}
          />
        </Spin>
      </Modal>
      <Modal
        open={compositionFiltersOpen}
        onCancel={() => setCompositionFiltersOpen(false)}
        title="Filters"
        footer={[
          <Button onClick={() => setCompositionFilters({})}>Reset</Button>,
          <Button
            onClick={() => setCompositionFiltersOpen(false)}
            type="primary"
          >
            Submit
          </Button>,
        ]}
      >
        <CompositionsFilters
          onChange={setCompositionFilters}
          value={compositionFilters}
        />
      </Modal>
      <div className="flex gap-x-2 w-full">
        <div className="w-1/2">
          <div className="flex gap-x-2 mb-2">
            <Input
              className="w-1/2"
              placeholder="Search Composition by Name"
              onChange={(e) => {
                searchDebounced(e.target.value);
              }}
              allowClear
              suffix={<SearchOutlined />}
            />
            <Tooltip title="Filter">
              <Badge
                count={
                  compositionFilters
                    ? Object.keys(compositionFilters).filter(
                        (key) => compositionFilters[key].length > 0
                      ).length
                    : 0
                }
              >
                <Button
                  type="default"
                  icon={<FilterOutlined />}
                  onClick={() => setCompositionFiltersOpen(true)}
                />{" "}
              </Badge>
            </Tooltip>
            <div className="flex justify-end gap-x-2 w-1/2">
              <Tooltip title="Refresh">
                <Button
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={async () => {
                    try {
                      await handleLoadData();
                      message.success("Compositions Refreshed");
                    } catch (error) {
                      message.error("Failed to refresh compositions");
                    }
                  }}
                />
              </Tooltip>

              <Tooltip title="Add Media">
                <Button
                  type="primary"
                  icon={<DoubleRightOutlined />}
                  onClick={() => handleMediaSelection()}
                  disabled={selectedRowAddKeys.length === 0}
                />
              </Tooltip>
            </div>
          </div>

          <Table
            dataSource={data
              ?.filter((record) => !record.isArchived)
              .filter(
                (record) => !props.value?.map((m) => m._id).includes(record._id)
              )
              .filter((record) => {
                if (
                  compositionFilters.type &&
                  compositionFilters.type.length > 0
                ) {
                  if (
                    !compositionFilters.type.includes(
                      record.mimeType.split("/")[0]
                    )
                  ) {
                    return false;
                  }
                }
                if (
                  compositionFilters.resolution &&
                  compositionFilters.resolution.length > 0
                ) {
                  if (
                    compositionFilters.resolution
                      .map((r) => +r)
                      .some(
                        (r) =>
                          r !==
                          +record.minimumResolution.substring(
                            0,
                            record.minimumResolution.length - 1
                          )
                      )
                  ) {
                    return false;
                  }
                }
                if (
                  compositionFilters.orientation &&
                  compositionFilters.orientation.length > 0
                ) {
                  if (
                    !compositionFilters.orientation.includes(record.orientation)
                  ) {
                    return false;
                  }
                }
                return true;
              })
              .filter((record) =>
                record.name.match(new RegExp(`${query}`, "i"))
              )
              .filter((record) =>
                dateFilter
                  ? dayjs(record.createdAt).isBetween(
                      dateFilter[0] || dayjs("1900-01-01"),
                      dateFilter[1] || dayjs()
                    )
                  : true
              )
              .sort((a, b) => {
                if (!compositionFilters.sort) {
                  return 1;
                }
                let [key, direction] = compositionFilters.sort.split(".");
                direction = +direction;
                if (a[key] > b[key]) {
                  return direction;
                }
              })}
            pagination={false}
            rowKey={"_id"}
            rowSelection={{
              selectedRowKeys: selectedRowAddKeys,
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRowAddKeys(selectedRowKeys as string[]);
              },
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  const isSelected = selectedRowAddKeys.includes(record._id);
                  setSelectedRowAddKeys(
                    isSelected
                      ? selectedRowAddKeys.filter((k) => k !== record._id)
                      : [...selectedRowAddKeys, record._id]
                  );
                },
              };
            }}
            size="small"
            columns={[
              {
                title: "Name",
                key: "name",
                dataIndex: "name",
                render: (name, record) => (
                  <div className="flex gap-x-1 items-center">
                    {name}
                    <Tooltip title="Preview Composition">
                      <Button
                        type="link"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCompositionForPreviewId(record._id);
                        }}
                      >
                        <MdPreview className="text-lg" />
                      </Button>
                    </Tooltip>
                  </div>
                ),
              },
              {
                title: "Orientation",
                key: "orientation",
                dataIndex: "orientation",
                render: (text) => titleCase(text || ""),
              },
              {
                title: "Total Duration",
                key: "totalDuration",
                dataIndex: "assets",
                render: (text) => (
                  <div>
                    {text?.reduce((acc, curr) => acc + curr.duration, 0)} secs
                  </div>
                ),
              },
              {
                title: "Min. Resolution",
                key: "minimumResolution",
                dataIndex: "minimumResolution",
              },
              {
                title: "Created At",
                key: "createdAt",
                dataIndex: "createdAt",
                filterIcon: (filtered: boolean) => (
                  <CalendarFilled
                    className={`${filtered ? "color-primary-500" : ""}`}
                  />
                ),
                onFilterDropdownVisibleChange: (visible) => {
                  if (visible) {
                    setTimeout(() => dateFilterInput.current?.focus(), 100);
                  }
                },
                filterDropdown: ({
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                }) => (
                  <div>
                    <div className="pt-2 px-2">
                      <RangePicker
                        ref={dateFilterInput}
                        value={selectedKeys.map((key) => dayjs(key)) as any}
                        onChange={(value) => {
                          setDateFilter(value);
                          if (value) {
                            setSelectedKeys(value?.map((v) => v.toISOString()));
                          }
                        }}
                      />
                    </div>
                    <Divider className="my-2" />
                    <div className="pb-2 flex justify-between px-2">
                      <Button
                        size="small"
                        type="link"
                        disabled={!dateFilter || dateFilter[0] === undefined}
                        onClick={() => {
                          clearFilters();
                          setDateFilter([undefined, undefined]);
                        }}
                      >
                        Reset
                      </Button>
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => {
                          confirm({ closeDropdown: true });
                        }}
                      >
                        OK
                      </Button>
                    </div>
                  </div>
                ),
                render: (text) => dayjs(text).format("DD/MM/YYYY"),
              },
            ]}
          />
        </div>
        <div className="w-1/2">
          <div className="flex items-center gap-x-2 mb-2">
            <div className="w-full flex items-center gap-x-2">
              <Tooltip title="Remove Media">
                <Button
                  type="primary"
                  icon={<DoubleLeftOutlined />}
                  onClick={() => handleMediaRemoval()}
                  disabled={selectedRemoveRowKeys.length === 0}
                />
              </Tooltip>
            </div>
            <Input
              placeholder="Search Composition by Name"
              onChange={(e) => {
                addedSearchDebounced(e.target.value);
              }}
              allowClear
              suffix={<SearchOutlined />}
            />
          </div>
          <Table
            pagination={false}
            size="small"
            rowKey={"_id"}
            rowSelection={{
              selectedRowKeys: selectedRemoveRowKeys,
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRemoveRowKeys(selectedRowKeys as string[]);
              },
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  const isSelected = selectedRemoveRowKeys.includes(record._id);
                  setSelectedRemoveRowKeys(
                    isSelected
                      ? selectedRemoveRowKeys.filter((k) => k !== record._id)
                      : [...selectedRemoveRowKeys, record._id]
                  );
                },
              };
            }}
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
            columns={[
              {
                title: "",
                dataIndex: "sort",
                width: 30,
                className: "drag-visible",
                render: () => <DragHandle />,
              },
              { title: "Name", key: "name", dataIndex: "name" },
              {
                title: "Orientation",
                key: "orientation",
                dataIndex: "orientation",
                render: (text) => titleCase(text || ""),
              },
              {
                title: "Min. Resolution",
                key: "minimumResolution",
                dataIndex: "minimumResolution",
              },
              {
                title: "Total Duration",
                key: "totalDuration",
                dataIndex: "assets",
                render: (text) => (
                  <div>
                    {text?.reduce((acc, curr) => acc + curr.duration, 0)} secs
                  </div>
                ),
              },
              {
                title: "Created At",
                key: "createdAt",
                dataIndex: "createdAt",
                render: (text) => dayjs(text).format("DD/MM/YYYY"),
              },
            ]}
            dataSource={props.value.filter((record) =>
              record.name.match(new RegExp(`${addedQuery}`, "i"))
            )}
          />
        </div>
      </div>
    </div>
  );
}

function DeviceGroupsFormControl(props: any) {
  const [selectedRowAddKeys, setSelectedRowAddKeys] = useState<string[]>([]);
  const [selectedRemoveRowKeys, setSelectedRemoveRowKeys] = useState<string[]>(
    []
  );
  const [groupFilters, setGroupFilters] = useState<any>({});
  const [groupFiltersOpen, setGroupFiltersOpen] = useState(false);

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [addedQuery, setAddedQuery] = useState("");
  useEffect(() => {
    handleLoadData();
  }, []);

  const handleLoadData = async () => {
    try {
      setLoading(true);
      const data = await deviceGroupService.getAll();
      setData(data.map((item: any, index: number) => ({ ...item, index })));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleMediaSelection = useCallback(() => {
    const addedMedia = data.filter((record) =>
      selectedRowAddKeys.includes(record._id)
    );
    const newSelectedMedia = [...addedMedia, ...(props.value || [])].map(
      (record, index) => ({
        ...record,
        index,
      })
    );
    props.onChange(newSelectedMedia);
    setSelectedRowAddKeys([]);
  }, [selectedRowAddKeys, data]);
  const handleMediaRemoval = useCallback(() => {
    const removedMedia = props.value.filter((record, index) =>
      selectedRemoveRowKeys.includes(record._id)
    );
    props.onChange(
      props.value.filter((record) => !removedMedia.includes(record))
    );
    setSelectedRemoveRowKeys([]);
  }, [selectedRemoveRowKeys, data]);
  const searchDebounced = useCallback(
    debounce(100, (value) => setQuery(value)),
    []
  );
  const addedSearchDebounced = useCallback(
    debounce(100, (value) => setAddedQuery(value)),
    []
  );
  return (
    <div>
      <Modal
        open={groupFiltersOpen}
        onCancel={() => setGroupFiltersOpen(false)}
        title="Filters"
        footer={[
          <Button onClick={() => setGroupFilters({})}>Reset</Button>,
          <Button onClick={() => setGroupFiltersOpen(false)} type="primary">
            Submit
          </Button>,
        ]}
      >
        <DeviceGroupsFilters onChange={setGroupFilters} value={groupFilters} />
      </Modal>
      <div className="flex gap-x-2">
        <div className="w-1/2">
          <div className="flex gap-x-2 mb-2">
            <Input
              className="w-1/2"
              placeholder="Search Composition by Name"
              onChange={(e) => {
                searchDebounced(e.target.value);
              }}
              suffix={<SearchOutlined />}
            />
            <Tooltip title="Filter">
              <Badge
                count={
                  groupFilters
                    ? Object.keys(groupFilters).filter(
                        (key) => groupFilters[key].length > 0
                      ).length
                    : 0
                }
              >
                <Button
                  type="default"
                  icon={<FilterOutlined />}
                  onClick={() => setGroupFiltersOpen(true)}
                />{" "}
              </Badge>
            </Tooltip>
            <div className="flex justify-end gap-x-2 w-1/2">
              <Tooltip title="Refresh">
                <Button
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={handleLoadData}
                />
              </Tooltip>

              <Tooltip title="Add Media">
                <Button
                  type="primary"
                  icon={<DoubleRightOutlined />}
                  onClick={() => handleMediaSelection()}
                  disabled={selectedRowAddKeys.length === 0}
                />
              </Tooltip>
            </div>
          </div>
          <Table
            pagination={false}
            size="small"
            rowKey={"_id"}
            rowSelection={{
              selectedRowKeys: selectedRowAddKeys,
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRowAddKeys(selectedRowKeys as string[]);
              },
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  const isSelected = selectedRowAddKeys.includes(record._id);
                  setSelectedRowAddKeys(
                    isSelected
                      ? selectedRowAddKeys.filter((k) => k !== record._id)
                      : [...selectedRowAddKeys, record._id]
                  );
                },
              };
            }}
            columns={[
              { title: "Name", key: "name", dataIndex: "name" },

              {
                title: "Orientation",
                key: "orientation",
                dataIndex: "orientation",
                render: (text) => titleCase(text || ""),
              },
              {
                title: "Min. Resolution",
                key: "minimumResolution",
                dataIndex: "minimumResolution",
              },
              {
                key: "deviceCount",
                title: "Screen Count",
                dataIndex: "devices",
                render: (devices) => devices.length,
              },
            ]}
            dataSource={data
              ?.filter(
                (record) => !props.value?.map((m) => m._id).includes(record._id)
              )
              .filter((record) => {
                if (groupFilters.type && groupFilters.type.length > 0) {
                  if (
                    !groupFilters.type.includes(record.mimeType.split("/")[0])
                  ) {
                    return false;
                  }
                }
                if (
                  groupFilters.resolution &&
                  groupFilters.resolution.length > 0
                ) {
                  console.log(
                    record.minimumResolution &&
                      +record.minimumResolution.substring(
                        0,
                        record.minimumResolution.length - 1
                      )
                  );
                  if (
                    groupFilters.resolution
                      .map((r) => +r)
                      .some(
                        (r) =>
                          !record.minimumResolution ||
                          r !==
                            +record.minimumResolution.substring(
                              0,
                              record.minimumResolution.length - 1
                            )
                      )
                  ) {
                    return false;
                  }
                }
                if (
                  groupFilters.orientation &&
                  groupFilters.orientation.length > 0
                ) {
                  if (!groupFilters.orientation.includes(record.orientation)) {
                    return false;
                  }
                }
                return true;
              })
              .filter((record) =>
                record.name.match(new RegExp(`${query}`, "i"))
              )}
          />
        </div>
        <div className="w-1/2">
          <div className="flex items-center gap-x-2 mb-2">
            <div className="w-full flex items-center gap-x-2">
              <Tooltip title="Remove Media">
                <Button
                  type="primary"
                  icon={<DoubleLeftOutlined />}
                  onClick={() => handleMediaRemoval()}
                  disabled={selectedRemoveRowKeys.length === 0}
                />
              </Tooltip>
            </div>
            <Input
              placeholder="Search Composition by Name"
              suffix={<SearchOutlined />}
              onChange={(e) => {
                addedSearchDebounced(e.target.value);
              }}
              allowClear
            />
          </div>
          <Table
            pagination={false}
            size="small"
            rowKey={"_id"}
            rowSelection={{
              selectedRowKeys: selectedRemoveRowKeys,
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRemoveRowKeys(selectedRowKeys as string[]);
              },
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  const isSelected = selectedRemoveRowKeys.includes(record._id);
                  setSelectedRemoveRowKeys(
                    isSelected
                      ? selectedRemoveRowKeys.filter((k) => k !== record._id)
                      : [...selectedRemoveRowKeys, record._id]
                  );
                },
              };
            }}
            columns={[
              { title: "Name", key: "name", dataIndex: "name" },
              {
                title: "Orientation",
                key: "orientation",
                dataIndex: "orientation",
                render: (text) => titleCase(text?.toLowerCase() || ""),
              },
              {
                title: "Description",
                key: "description",
                dataIndex: "description",
              },
              {
                key: "deviceCount",
                title: "Screen Count",
                dataIndex: "devices",
                render: (devices) => devices?.length,
              },
            ]}
            dataSource={props.value.filter((record) =>
              record.name.match(new RegExp(`${addedQuery}`, "i"))
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default function EntityForm(props: any) {
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "Campaign Form" },
  ]);
  const location = useLocation();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<any>({});
  const deviceGroups = Form.useWatch("deviceGroups", form);
  const compositions = Form.useWatch("compositions", form);
  const groups = Form.useWatch("groups", form);
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [simpleInsert, setSimpleInsert] = useState(false);
  const [loadLoading, setLoadLoading] = useState(false);
  const [existingCampaigns, setExistingCampaigns] = useState<any>([]);
  const [dependentsLoading, setDependentsLoading] = useState(false);
  const [masters, setMasters] = useState<any>({});
  const [defaultSlots, setDefaultSlots] = useState<any>([]);
  const setBreadcrumbs = useBreadcrumbs();
  const isEmergency = Form.useWatch("isEmergency", form);
  const [schedularMode, setSchedularMode] = useState("Simple");
  const [step, setStep] = useState(0);
  const [tags, setTags] = useState<any>([]);
  const preparePayload = (values: any) => {
    return {
      ...values,
      slots: values.slots.filter((slot: any) => !slot.isExisting),
    };
  };
  const handlePublish = async (values: any) => {
    try {
      await form.validateFields();
      setLoading(true);
      if (id) {
        service
          .update({ ...preparePayload(values), status: "PUBLISHED", _id: id })
          .then((response) => {
            if (!response.error) {
              navigate(BASE_PATH);
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      } else {
        service
          .create({ ...preparePayload(values), status: "PUBLISHED" })
          .then((response) => {
            if (!response.error) {
              navigate(BASE_PATH);
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (error) {}
  };
  const handleCancel = () => {
    navigate(BASE_PATH);
  };
  const slotsValidator = (rule: any, value: any) => {
    if (value.filter((e) => !e.isExisting).length === 0) {
      return Promise.reject("Slots are required");
    }
    if (value?.length > 0) {
      let overlappingSlots: any[] = [];
      const isOverlapping = (slots: any[]) => {
        return slots.some((slot, index) => {
          return slots.some((s, i) => {
            if (index !== i) {
              if (
                (slot.start < s.end && slot.start >= s.start) ||
                (slot.end > s.start && slot.end <= s.end) ||
                (slot.start <= s.start && slot.end >= s.end)
              ) {
                overlappingSlots.push([slot, s]);
                return true;
              }
            }
            return false;
          });
        });
      };
      if (isOverlapping(value)) {
        return Promise.reject(
          <div>
            The following events are overlapping:
            <div>
              {overlappingSlots.map((slots: any) => {
                return (
                  <div className="mb-4">
                    <div className="flex gap-x-2">
                      {slots[0].title}: {slots[0].start.toLocaleString()} -{" "}
                      {slots[0].end.toLocaleString()}
                    </div>
                    <div className="flex gap-x-2">
                      {slots[1].title}: {slots[1].start.toLocaleString()} -{" "}
                      {slots[1].end.toLocaleString()}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }

      return Promise.resolve();
    }
  };

  const handleSelectDefaultComposition = async (compositionId: string) => {
    try {
      const composition = await compositionService.get(compositionId);
      if (composition) {
        form.setFieldsValue({
          compositions: [composition],
        });
        setStep(1);
        message.success("Composition selected successfully");
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to select composition");
    }
  };
  const handleLoadTags = async () => {
    try {
      const tags = await service.getUniqueTags();
      setTags(tags);
    } catch (error) {
      message.error("Failed to load tags");
    }
  };
  useEffect(() => {
    const params: any = querystring.parse(location.search);
    if (!_.isEmpty(params) && params.composition) {
      handleSelectDefaultComposition(params.composition);
    }
    if (!_.isEmpty(params) && params.entity) {
      setId(params.entity);
      setLoadLoading(true);
      service
        .get(params.entity)
        .then((payload) => {
          form.setFieldsValue({
            ...payload,
          });
          setDefaultSlots(
            payload.slots.map((s) => ({ ...s, status: payload.status }))
          );
        })
        .finally(() => setLoadLoading(false));
    }
  }, [location]);
  useEffect(() => {
    handleLoadDependencies();
    handleLoadTags();
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const handleLoadCampaignsForGroups = async () => {
    try {
      setDependentsLoading(true);
      const response = await service.getCampaignsForGroups(
        groups.map((g) => g._id)
      );
      setExistingCampaigns(
        response.filter((r) => r._id !== id && r.isEmergency === isEmergency)
      );
    } catch (error) {
    } finally {
      setDependentsLoading(false);
    }
  };
  useEffect(() => {
    handleLoadCampaignsForGroups();
  }, [groups, isEmergency]);
  const handleLoadDependencies = async () => {
    try {
      setDependentsLoading(true);
      const promises = [];
      const [] = await Promise.all(promises);

      setMasters({});
    } catch (error) {
    } finally {
      setDependentsLoading(false);
    }
  };
  const handleSubmit = (values: any) => {
    // setLoading(true);
    if (id) {
      service
        .update({ ...preparePayload(values), status: "DRAFT", _id: id })
        .then((response) => {
          if (!response.error) {
            navigate(BASE_PATH);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      service
        .create({ ...preparePayload(values) })
        .then((response) => {
          if (!response.error) {
            navigate(BASE_PATH);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const Compositions = (
    <div className=" bg-white rounded-lg p-3 mt-3">
      {/* <div className="flex gap-x-2 items-center">
        <div className="bg-primary-500 h-8 w-8 rounded-full flex items-center justify-center">
          <div className=" text-white">{1}</div>
        </div>
        <div className="text-lg">Add Compositions</div>
      </div>
      <Divider className="my-2" /> */}
      <div>
        <Form.Item
          name="compositions"
          initialValue={[]}
          rules={[{ required: true, message: "Composition is required" }]}
        >
          <CompositionsFormControl onNext={() => setStep(1)} />
        </Form.Item>
      </div>
      <div className="flex justify-end">
        <div className="gap-x-2 items-end flex ">
          <Button
            type="primary"
            onClick={() => setStep(1)}
            disabled={!compositions || compositions.length === 0}
          >
            Next: Select Groups
          </Button>
        </div>
      </div>
    </div>
  );

  const DeviceGroups = (
    <div className=" bg-white rounded-lg p-3 mt-3">
      {/* <div className="flex gap-x-2 items-center">
        <div className="bg-primary-500 h-8 w-8 rounded-full flex items-center justify-center">
          <div className=" text-white">{2}</div>
        </div>
        <div className="text-lg">Add Device Groups</div>
      </div>
      <Divider className="my-2" /> */}
      <div>
        <Form.Item
          name="groups"
          initialValue={[]}
          preserve
          rules={[{ required: true, message: "Group is required" }]}
        >
          <DeviceGroupsFormControl />
        </Form.Item>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-end">
        <div className="gap-x-2 items-end flex ">
          <Button type="default" onClick={() => setStep(0)}>
            Back: Manage Compositions
          </Button>
          <Button
            type="primary"
            onClick={() => setStep(2)}
            disabled={!groups || groups.length === 0}
          >
            Next: Select Schedule
          </Button>
        </div>
      </div>
    </div>
  );

  const Schedule = (
    <div className=" bg-white rounded-lg p-3  mt-3">
      <div className="flex justify-between">
        {/* <div className="flex gap-x-2 items-center">
          <div className="bg-primary-500 h-8 w-8 rounded-full flex items-center justify-center">
            <div className=" text-white">{3}</div>
          </div>
          <div className="text-lg">Schedule Campaign</div>
        </div> */}
        <div>
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setSimpleInsert(true);
            }}
            type="link"
          >
            Simple Insert
          </Button>
        </div>
      </div>
      <Divider className="my-2" />
      <div>
        <Form.Item
          name="slots"
          rules={[
            {
              required: true,
              message: "Add atleast one slot",
            },
            {
              validator: slotsValidator,
            },
          ]}
        >
          <DnDScheduler
            defaultValues={defaultSlots}
            existingCampaigns={existingCampaigns}
            isEmergency={isEmergency}
            simpleInsert={simpleInsert}
            onSimpleInsertCancel={() => setSimpleInsert(false)}
          />
        </Form.Item>
      </div>
      <div className="flex justify-end">
        <div className="gap-x-2 items-end flex ">
          <Button type="default" onClick={() => setStep(1)}>
            Back: Manage Screen Groups
          </Button>
        </div>
      </div>
    </div>
  );
  const handleAutoGenerateName = async () => {
    let autogeneratedName = ``;
    const values = form.getFieldsValue();
    console.log(values);
    if (values.compositions?.length > 0) {
      autogeneratedName += `CM-${values.compositions.map(
        (c: { name: string }) =>
          c.name
            .split(" ")
            .map((n) => n[0])
            .join("")
      )}-`;
    }
    if (values.groups?.length > 0) {
      if (autogeneratedName.length > 0) {
        autogeneratedName += `${values.groups.map((c: { name: string }) =>
          c.name
            .split(" ")
            .map((n) => n[0])
            .join("")
        )}-`;
      } else {
        autogeneratedName += `CM-${values.groups.map((c: { name: string }) =>
          c.name
            .split(" ")
            .map((n) => n[0])
            .join("")
        )}-`;
      }
    }
    form.setFieldsValue({
      name:
        autogeneratedName.length > 0
          ? `${autogeneratedName}${dayjs().format("YYYYMMDD")}`
          : `Campaign-${dayjs().format("YYYYMMDD")}`,
    });
  };
  return (
    <div className="mx-auto container">
      <div
        className="flex gap-x-2 items-center cursor-pointer hover:text-primary-500 w-max transition-all"
        onClick={() => navigate(`${BASE_PATH}`)}
      >
        <LeftOutlined /> Back to {TITLE[1]}
      </div>
      <Title level={3} className="mt-4">
        {id ? "Update" : "New"} {TITLE[0]}
      </Title>
      <Spin spinning={loadLoading}>
        <div className="mt-4">
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={setErrors}
            preserve={true}
            // onValuesChange={() => {
            //   form.validateFields().catch((e) => {
            //     setErrors(e);
            //   });
            // }}
            initialValues={{
              type: "recurring",
              doesntEnd: true,
              isEmergency: false,
            }}
            scrollToFirstError
          >
            <div className="bg-white rounded-lg px-4 py-2">
              <div className=" flex gap-x-2">
                <Form.Item
                  name="name"
                  className="w-1/2"
                  label="Campaign Name"
                  extra={
                    <div
                      className="text-right text-blue-500 cursor-pointer"
                      onClick={handleAutoGenerateName}
                    >
                      Auto-generate name
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Campaign Name is required.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Campaign Name *"
                    className="bg-transparent"
                  />
                </Form.Item>
                <Form.Item name="tags" label="Tags" className="w-1/2">
                  <Select
                    mode="tags"
                    placeholder="Enter / Select Tags"
                    className="campaign-tags-transparent"
                    options={tags.map((tag: any) => ({
                      label: tag._id,
                      value: tag._id,
                    }))}
                  />
                </Form.Item>
              </div>
              <Form.Item name="isEmergency" valuePropName="checked">
                <Checkbox>Is Quick Play Campaign</Checkbox>
              </Form.Item>
            </div>
            {isEmergency && (
              <div className="mt-3">
                <Alert
                  type="warning"
                  message="Publishing this campaign will override all other campaigns on the selected screens for the selected duration."
                  showIcon
                />
              </div>
            )}
            <Steps
              items={[
                { title: "Add Compositions", subTitle: ``, disabled: true },
                { title: "Add Screen Groups", subTitle: ``, disabled: true },
                { title: "Add Schedule", subTitle: ``, disabled: true },
              ]}
              className="mt-3 bg-white rounded-lg px-4 py-2 rounded-lg"
              current={step}
              size="small"
              onChange={setStep}
            />
            <div className={`${step === 0 ? "" : "hidden"}`}>
              {Compositions}
            </div>
            <div className={`${step === 1 ? "" : "hidden"}`}>
              {DeviceGroups}
            </div>
            <div className={`${step === 2 ? "" : "hidden"}`}>{Schedule}</div>

            <div className="my-3">
              {errors &&
                errors.errorFields?.length > 0 &&
                errors.errorFields.map((e) => (
                  <Alert
                    type="error"
                    className="mb-2"
                    message={e.errors[0]}
                    showIcon
                  />
                ))}
            </div>

            <div className="mt-3">
              <Form.Item noStyle>
                <Space>
                  <Button type="link" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="default" htmlType="submit" loading={loading}>
                    Save as Draft
                  </Button>
                  <Button
                    type="primary"
                    loading={loading}
                    onClick={() => {
                      form.validateFields().catch((e) => {
                        setErrors(e);
                      });
                      handlePublish(form.getFieldsValue());
                    }}
                  >
                    Publish Campaign
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Spin>
    </div>
  );
}
