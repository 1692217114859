import { useEffect, useState, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Input,
  Select,
  Table,
  Space,
  Popover,
  List,
  Checkbox,
  Form,
  Modal,
  Divider,
  Tag,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
  ExportOutlined,
} from "@ant-design/icons";

import { debounce } from "throttle-debounce";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TITLE, BASE_PATH } from ".";
import service from "@services/device";
import deviceGroupService from "@services/deviceGroup";
import clientService from "@services/client";

import useSearchParamsTableState from "hooks/useSearchParamsTableState";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import { TbColumns3 } from "react-icons/tb";
import useSelectedColumnsState from "@hooks/useSelectedColumnsState";
import saveAs from "file-saver";
import { titleCase } from "title-case";
import useDevicesHealth from "@hooks/useDevicesHealth";
import _ from "lodash";
const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const MASTER_KEYS = [{ value: "clients", service: clientService }];
const ADDITIONAL_COLUMNS = [
  {
    title: "Active",
    dataIndex: "isActive",
    key: "isActive",
    render: (text) => (text ? "Yes" : "No"),
  },
  { key: "remote", title: "Remote", dataIndex: "remote" },
  { key: "osVersion", title: "OS Version", dataIndex: "osVersion" },
  { key: "platformName", title: "Platform Name", dataIndex: "platformName" },

  {
    key: "launcherVersion",
    title: "Launcher Version",
    dataIndex: "launcherVersion",
  },
  { key: "contentType", title: "Content Type", dataIndex: "contentType" },
  { key: "stage", title: "Stage", dataIndex: "stage" },
  { key: "flag", title: "Flag", dataIndex: "flag" },
  {
    key: "recordVersion",
    title: "Record Version",
    dataIndex: "recordVersion",
  },
  {
    title: "Updated",
    dataIndex: "updatedAt",
    key: "updatedAt",

    render: (text, record, index) => dayjs(text).format("DD/MM/YYYY"),
  },
];

export default function Entities(props: any) {
  const breadcrumbsRef = useRef([{ label: TITLE[1], url: BASE_PATH }]);
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({ onFetch: service.paginate });
  const navigate = useNavigate();
  const setBreadcrumbs = useBreadcrumbs();
  const [resetPasswordVisible, setResetPasswordVisible] = useState("");
  const [toggleLoading, setToggleLoading] = useState(false);
  const [toggleKey, setToggleKey] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const health = useDevicesHealth({
    devices: data?.list || [],
    ids: [],
    onUpdate: (sockets) => updateLastSeen(sockets),
  });
  const [lastSeenData, setLastSeenData] = useState<any>({});

  const { selectedColumns, setSelectedColumns } = useSelectedColumnsState({
    breadcrumbs: breadcrumbsRef.current,
    availableColumns: ADDITIONAL_COLUMNS,
    defaultSelected: [],
  });

  useEffect(() => {
    loadMastersDebounced();
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const [masters, setMasters] = useState<any>({});

  const getColumnsFilters = useCallback(
    (key: string) => {
      return masters[key]
        ? masters[key].map((entity) => ({
            text: entity.name,
            value: entity._id,
          }))
        : [];
    },
    [masters]
  );
  const updateLastSeen = (sockets) => {
    const newData: any[] = _.cloneDeep(data.list || []);
    if (sockets.length === 0) {
      return;
    }

    const newLastSeenData: any = {};

    for (let socket of sockets) {
      const recordIndex = data?.list.findIndex((d) => d.deviceId === socket.id);
      if (recordIndex !== -1) {
        newLastSeenData[newData[recordIndex]._id] = socket.lastSeen;
      }
    }
    setLastSeenData({ ...lastSeenData, ...newLastSeenData });
  };
  const loadMasters = useCallback(() => {
    const promises: any[] = [];
    for (let masterKey of MASTER_KEYS) {
      promises.push(
        masterKey.service
          .getAll()
          .then((p) => ({ key: masterKey.value, data: p }))
      );
    }
    Promise.all(promises).then((payload) => {
      const output: any = {};
      payload.forEach((m) => (output[m.key] = m.data));
      setMasters(output);
    });
  }, []);
  const loadMastersDebounced = useCallback(debounce(300, loadMasters), []);

  const columns: any[] = [
    {
      key: "device",
      align: "right",
      render: (_, record) => {
        if (health.payload?.[record.deviceId]) {
          const { Render } = health.payload[record.deviceId];
          return <Render lastSeen={lastSeenData[record._id]} />;
        }
      },
    },
    {
      key: "client",
      title: "Client",
      dataIndex: "client",
      filters:
        masters.clients?.map((client: any) => ({
          text: client.name,
          value: client._id,
        })) || [],
      render: (text) => text?.name,
    },
    {
      key: "group",
      title: "Group",
      dataIndex: "group",

      render: (group) =>
        group?.name || <span className="italic">No Group</span>,
    },
    { key: "deviceId", title: "Device ID", dataIndex: "deviceId" },
    {
      key: "resolution",
      title: "Max Resolution",
      filters: ["480p", "720p", "1080p", "1440p", "2160p"].map((res) => ({
        text: res,
        value: res.substring(0, res.indexOf("p")),
      })),
      dataIndex: "display",

      render: (display) => {
        return (
          <div>
            {display?.resolution} ({display?.refreshRate})
          </div>
        );
      },
    },

    {
      key: "orientation",
      title: "Orientation",
      dataIndex: "orientation",
      filters: ["LANDSCAPE", "PORTRAIT"].map((orientation) => ({
        text: titleCase(orientation.toLowerCase() || ""),
        value: orientation,
      })),
      render: (orientation) => titleCase(orientation.toLowerCase() || ""),
    },
    {
      key: "osVersion",
      title: "OS Version",
      dataIndex: "osVersion",
      render: (osVersion, record) => (
        <div>
          {osVersion} (API: {record.apiLevel})
        </div>
      ),
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      filters: ["REGISTERED", "ACTIVATED", "SUSPENDED"].map((status) => ({
        text: (
          <Tag
            color={
              status === "REGISTERED"
                ? "blue"
                : status === "ACTIVATED"
                ? "green"
                : "default"
            }
          >
            {status}
          </Tag>
        ),
        value: status,
      })),
      render: (status) => (
        <Tag
          color={
            status === "REGISTERED"
              ? "blue"
              : status === "ACTIVATED"
              ? "green"
              : "default"
          }
        >
          {status}
        </Tag>
      ),
    },
  ];

  const handleToggle = async (id: string) => {
    try {
      setToggleLoading(true);
      await service.toggleActive(id);
      onRefresh();
      setToggleKey("");
    } catch (error) {
      console.log(error);
    } finally {
      setToggleLoading(false);
    }
  };

  const handleForm = (entityId?: string) => {
    if (entityId) {
      navigate(`${BASE_PATH}/edit?entity=${entityId}`);
    } else {
      navigate(`${BASE_PATH}/new`);
    }
  };
  const handleView = (entityId?: string) => {
    navigate(`${BASE_PATH}/${entityId}`);
  };
  const handleBulk = () => {
    navigate(`${BASE_PATH}/bulk`);
  };
  const handleExport = async () => {
    try {
      setExportLoading(true);
      const file = await service.exportToXLSX();
      saveAs(file, `Devices-${dayjs().format("DDMMYYYYHHmm")}.xlsx`);
    } catch (error) {
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };
  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Title level={4}>All {TITLE[1]}</Title>
        </Col>
        <Col></Col>
        <Col>
          <Space>
            {/* <Popover
              overlayClassName="popover-content-w-full"
              content={
                <List
                  size="small"
                  dataSource={ADDITIONAL_COLUMNS}
                  renderItem={(item) => (
                    <List.Item className="flex items-center gap-x-2 justify-start">
                      <div className="w-max">
                        <Checkbox
                          checked={selectedColumns.some(
                            (c) => c.key === item.key
                          )}
                          onChange={(e) => {
                            setSelectedColumns(item);
                          }}
                          children={item.title}
                        />
                      </div>
                    </List.Item>
                  )}
                />
              }
              title="Select Columns"
              trigger="click"
            >
              <Button icon={<TbColumns3 />} />
            </Popover> */}
            <Button
              type="primary"
              icon={<ExportOutlined />}
              onClick={() => handleExport()}
            >
              Export XLSX
            </Button>
            <Divider type="vertical" />
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => handleBulk()}
            >
              New Bulk
            </Button>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => handleForm()}
            >
              New {TITLE[0]}
            </Button>
          </Space>
        </Col>
      </Row>
      <div className="mt-4 bg-white rounded-lg">
        <Row justify="space-between" className="p-4">
          <Col className="flex">
            <Search
              placeholder={`Search ${TITLE[1]}`}
              enterButton={<SearchOutlined />}
              size="middle"
              onChange={(event) => {
                setInput(event.target.value);
              }}
              value={input}
            />
          </Col>
          <Col>
            {data?.queryCount !== data?.totalCount && (
              <Text className="flex items-center">
                Showing {data.queryCount} of {data?.totalCount} entries
                <Button
                  type="link"
                  onClick={() => {
                    setInput("");
                  }}
                >
                  Clear Search
                </Button>
              </Text>
            )}
          </Col>
          <Col></Col>
        </Row>
        <Row className="mt-2">
          <Col span={24}>
            <Table
              loading={loading}
              dataSource={data?.list}
              columns={columns}
              pagination={{
                ...(tableState?.pagination || {}),
                current: +tableState?.pagination?.current || 1,
                total: data?.queryCount,
              }}
              bordered
              className="overflow-table"
              onChange={(pagination, filters, sorter) => {
                delete (sorter as any).column;
                setTableState({ pagination, filters, sorter });
              }}
              rowKey={(record) => record._id}
              size="small"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
