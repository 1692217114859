import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import groupService from "../services/deviceGroup";
import assetService from "../services/asset";
const TYPE = "dashboard";

interface GroupState {
  loading: boolean;
  groups: any;
  error: any;
  loaded: boolean;
}

export interface DashboardState {
  groups: GroupState;
  usage: { total: number; images: number; videos: number };
}

const GROUP_INITIAL_STATE: GroupState = {
  loading: false,
  groups: null,

  error: null,
  loaded: false,
};
const INITIAL_STATE: DashboardState = {
  groups: GROUP_INITIAL_STATE,
  usage: { total: 0, images: 0, videos: 0 },
};

export const getGroupsAsync = createAsyncThunk(
  `${TYPE}/GET_GROUPS`,
  async (payload: any, thunkAPI) => {
    try {
      return groupService.getAll();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUsageAsync = createAsyncThunk(
  `${TYPE}/GET_USAGE`,
  async (payload: any, thunkAPI) => {
    try {
      return assetService.getTotalUsage();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const slice = createSlice({
  name: TYPE,
  initialState: INITIAL_STATE,
  reducers: {
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroupsAsync.pending, (state) => {
      state.groups = GROUP_INITIAL_STATE;
      state.groups.loading = true;
    });
    builder.addCase(getGroupsAsync.fulfilled, (state, action) => {
      state.groups = action.payload;
      state.groups.loading = false;
      state.groups.loaded = true;
    });
    builder.addCase(getGroupsAsync.rejected, (state, action) => {
      state.groups.error = action.payload;
      state.groups.loading = false;
    });
    builder.addCase(getUsageAsync.pending, (state) => {});
    builder.addCase(getUsageAsync.fulfilled, (state, action) => {
      state.usage = action.payload;
    });
    builder.addCase(getUsageAsync.rejected, (state, action) => {});
  },
});

export const { setGroups } = slice.actions;

export default slice.reducer;
