import {
  Button,
  Divider,
  Steps,
  Typography,
  message,
  Upload,
  Table,
  Alert,
  Tag,
  Tooltip,
} from "antd";
import { TITLE, BASE_PATH } from ".";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import { unstable_usePrompt } from "react-router-dom";
import service from "@services/device";
import { useRef, useEffect, useState, useCallback } from "react";
import type { UploadProps } from "antd";
import saveAs from "file-saver";
import dayjs from "dayjs";
import { exportToXLSX } from "@services/client";
import { titleCase } from "title-case";
import {
  InboxOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
  InfoCircleFilled,
  ArrowRightOutlined,
  WarningOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import * as XLSX from "xlsx";
import { MAX_RESOLUTIONS } from "./Form";
const TEMPLATE_URL =
  "https://ottis-signage-uploads.s3.ap-south-1.amazonaws.com/Devices.xltx";
const { Title } = Typography;
const { Dragger } = Upload;

const REQUIRED_COLUMNS = [
  "MAC_ADDRESS",
  "REMOTE",
  "OS_VERSION",
  "PLATFORM_NAME",
  "VENDOR_NAME",
  "VENDOR_ID",
  "VENDOR_PRODUCT_ID",
  "PRODUCT_ID",
  "LAUNCHER_VERSION",
  "CONTENT_TYPE",
  "STAGE",
  "RECORD_VERSION",
  "MAX_RESOLUTION",
];

const UploadRender: React.FC<any> = ({ onChange }) => {
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [error, setError] = useState<any>({ title: "", message: "" });
  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
  };
  const validateColumns = (row: { [key: string]: any }): string[] => {
    return REQUIRED_COLUMNS.filter((column) => !(column in row));
  };
  const findEmptyRequiredValues = (
    data: Array<{ [key: string]: any }>
  ): any[] => {
    let emptyDetails: any[] = [];

    data.forEach((row, index) => {
      REQUIRED_COLUMNS.forEach((column) => {
        if (!(column in row) || row[column] === null || row[column] === "") {
          emptyDetails.push({ row: index + 2, column: column });
        }
      });
    });

    return emptyDetails;
  };

  const handleNext = useCallback(async () => {
    setUploading(true);

    const file = fileList?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        try {
          const workbook = XLSX.read(e.target.result, { type: "binary" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);

          if (jsonData.length === 0) {
            message.error("The sheet is empty.");
            setUploading(false);
            return;
          }

          const missingColumns = validateColumns(jsonData[0]);
          if (missingColumns.length > 0) {
            message.error(
              `Missing required columns: ${missingColumns.join(", ")}.`
            );
            setUploading(false);
            return;
          }

          const emptyDetails = findEmptyRequiredValues(jsonData);

          if (emptyDetails?.length > 0) {
            message.error(
              `Empty required values found at: ${emptyDetails
                .map((d) => `Row number: ${d.row} (Column: ${d.column})`)
                .join(", ")}.`
            );
            setUploading(false);
            return;
          }

          // File validation passed, continue with upload
          const formData = new FormData();
          formData.append("file.xlsx", file);

          try {
            const response = await service.uploadBulk(formData);
            onChange(response);
          } catch (uploadError) {
            message.error("File upload failed."); // Notify user about the error
          }
        } catch (parseError) {
          message.error("Error reading the file.");
        } finally {
          setUploading(false);
        }
      };
      reader.readAsBinaryString(file);
    } else {
      message.error("No file selected.");
      setUploading(false);
    }
  }, [fileList, onChange]);

  const handleExport = async () => {
    try {
      setExportLoading(true);
      const file = await exportToXLSX();

      saveAs(file, `Clients-${dayjs().format("DDMMYYYYHHmm")}.xlsx`, {
        autoBom: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };
  return (
    <div>
      <div>
        <div>Supporting Files: </div>
        <div className="flex flex-col items-start">
          <Button
            type="link"
            onClick={() => window.open(TEMPLATE_URL, "_blank")}
          >
            Bulk Upload Template
          </Button>
          <Button
            type="link"
            onClick={() => handleExport()}
            loading={exportLoading}
          >
            Clients XLSX Export
          </Button>
        </div>
      </div>
      <Divider className="my-2" />
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Dragger>
      {error.title && (
        <Alert
          message={error.title}
          description={error.message}
          type="error"
          showIcon
        />
      )}
      <Divider className="my-2" />
      <div className="flex justify-between ">
        <Button disabled>Previous</Button>
        <Button
          type="primary"
          onClick={handleNext}
          disabled={fileList.length === 0 || uploading}
          loading={uploading}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const ApproveProcess: React.FC<any> = ({
  data,
  onChange,
  onPrevious,
  loading,
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [confirmModal, setConfirmModal] = useState(false);
  const [hasError, setHasError] = useState("");
  useEffect(() => {
    const data = [
      ...devicesToBeCreated.map((d) => ({ ...d.device, action: "Create" })),
      ...devicesToBeUpdated.map((d) => ({ ...d.device, action: "Update" })),
    ];
    const hasError = data.some(
      (d) => !MAX_RESOLUTIONS.includes(d.maxResolution)
    );
    if (hasError) {
      setHasError(
        "The data contains unsupported resolutions. Supported resolutions: 480p, 720p, 1080p, 1440p, 2160p"
      );
    }
  }, [data]);
  const handleNext = useCallback(() => {
    onChange();
  }, [data, onChange]);
  const columns: any[] = [
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      filters: [
        { text: "Create", value: "Create" },
        { text: "Update", value: "Update" },
      ],
      onFilter: (value: string, record) => record.action === value,
      sorter: (a, b) => (a.action < b.action ? -1 : 1),
    },

    { key: "client", title: "Client", dataIndex: "client" },
    { key: "macAddress", title: "MAC Address", dataIndex: "macAddress" },
    {
      key: "maxResolution",
      title: "Max Resolution",
      dataIndex: "maxResolution",
      render: (maxResolution) => (
        <div className="flex gap-x-2">
          {maxResolution}{" "}
          {!MAX_RESOLUTIONS.includes(maxResolution) && (
            <Tooltip title="This resolution is not supported">
              <ExclamationCircleOutlined className="text-red-500" />
            </Tooltip>
          )}
        </div>
      ),
    },
    { key: "date", title: "Date", dataIndex: "date" },
    { key: "remote", title: "Remote", dataIndex: "remote" },
    { key: "osVersion", title: "OS Version", dataIndex: "osVersion" },
    { key: "platformName", title: "Platform Name", dataIndex: "platformName" },
    { key: "vendorName", title: "Vendor Name", dataIndex: "vendorName" },
    { key: "vendorId", title: "Vendor Id", dataIndex: "vendorId" },
    {
      key: "vendorProductId",
      title: "Vendor Product Id",
      dataIndex: "vendorProductId",
    },
    { key: "productId", title: "Product Id", dataIndex: "productId" },
    {
      key: "launcherVersion",
      title: "Launcher Version",
      dataIndex: "launcherVersion",
    },
    { key: "contentType", title: "Content Type", dataIndex: "contentType" },
    { key: "stage", title: "Stage", dataIndex: "stage" },
    { key: "flag", title: "Flag", dataIndex: "flag" },
    {
      key: "recordVersion",
      title: "Record Version",
      dataIndex: "recordVersion",
    },
  ];
  const { devicesToBeCreated, devicesToBeUpdated } = data;
  return (
    <div className="processed-data-table">
      <Table
        dataSource={[
          ...devicesToBeCreated.map((d) => ({ ...d.device, action: "Create" })),
          ...devicesToBeUpdated.map((d) => ({ ...d.device, action: "Update" })),
        ]}
        size="small"
        columns={columns}
      />
      {!!hasError && <Alert message={hasError} type="error" showIcon />}
      <Divider className="my-2" />
      <div className="flex justify-between">
        <Button onClick={onPrevious}>Previous</Button>
        <Button
          type="primary"
          onClick={handleNext}
          loading={loading}
          disabled={!!hasError}
        >
          Approve
        </Button>
      </div>
    </div>
  );
};

const Report = (props: any) => {
  const { data } = props;
  const columns: any[] = [
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      filters: [
        { text: "Create", value: "Create" },
        { text: "Update", value: "Update" },
      ],
      onFilter: (value: string, record) => record.action === value,
      sorter: (a, b) => (a.action < b.action ? -1 : 1),
    },
    {
      key: "result",
      title: "Result",
      dataIndex: "result",
      filters: [
        { text: "Success", value: "Sucess" },
        { text: "Error", value: "Error" },
      ],
      onFilter: (value: string, record) => record.action === value,
      sorter: (a, b) => (a.action < b.action ? -1 : 1),
    },
    {
      key: "client",
      title: "Client",
      dataIndex: "client",
      render: (client) => client?.code,
    },
    { key: "macAddress", title: "MAC Address", dataIndex: "macAddress" },
    {
      key: "maxResolution",
      title: "Max Resolution",
      dataIndex: "maxResolution",
    },
    { key: "date", title: "Date", dataIndex: "date" },
    { key: "remote", title: "Remote", dataIndex: "remote" },
    { key: "osVersion", title: "OS Version", dataIndex: "osVersion" },
    { key: "platformName", title: "Platform Name", dataIndex: "platformName" },
    { key: "vendorName", title: "Vendor Name", dataIndex: "vendorName" },
    { key: "vendorId", title: "Vendor Id", dataIndex: "vendorId" },
    {
      key: "vendorProductId",
      title: "Vendor Product Id",
      dataIndex: "vendorProductId",
    },
    { key: "productId", title: "Product Id", dataIndex: "productId" },
    {
      key: "launcherVersion",
      title: "Launcher Version",
      dataIndex: "launcherVersion",
    },
    { key: "contentType", title: "Content Type", dataIndex: "contentType" },
    { key: "stage", title: "Stage", dataIndex: "stage" },
    { key: "flag", title: "Flag", dataIndex: "flag" },
    {
      key: "recordVersion",
      title: "Record Version",
      dataIndex: "recordVersion",
    },
  ];
  const { creationResults, updationResults } = data || {};
  return (
    <div className="processed-data-table">
      {data && (
        <Table
          dataSource={[
            ...creationResults.map((d) => ({
              ...d.device,
              action: "Create",
              result: titleCase(d.status),
            })),
            ...updationResults.map((d) => ({
              ...d.device,
              action: "Update",
              result: titleCase(d.status),
            })),
          ]}
          size="small"
          columns={columns}
        />
      )}
    </div>
  );
};

export default function Bulk() {
  const [step, setStep] = useState(0);
  const [isBlocking, setIsBlocking] = useState(true);
  const [uploadResult, setUploadResult] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState<any>();
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "Bulk Process" },
  ]);
  const setBreadcrumbs = useBreadcrumbs();
  useEffect(() => {
    window.onbeforeunload = isBlocking ? () => true : undefined;
  }, [isBlocking]);
  useEffect(() => {
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  //   unstable_usePrompt({
  //     message: "Are you sure?",
  //     when: !!isBlocking,
  //   });
  //   const toggleBlocking = () => setIsBlocking(!isBlocking);

  const handleApprove = async () => {
    try {
      setLoading(true);
      const report = await service.processBulk(uploadResult);
      setReport(report);
      //   toggleBlocking();
      setStep(2);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const render = () => {
    switch (step) {
      case 0: {
        return (
          <UploadRender
            onChange={(data) => {
              setStep(1);
              setUploadResult(data);
            }}
          />
        );
      }
      case 1: {
        return (
          <ApproveProcess
            data={uploadResult}
            onChange={handleApprove}
            onPrevious={() => setStep(0)}
            loading={loading}
          />
        );
      }
      case 2: {
        return <Report data={report} onPrevious={() => setStep(0)} />;
      }
    }
  };
  return (
    <div>
      <Title level={3}>Bulk Device Process</Title>
      <div className="bg-white rounded-lg p-3">
        <Steps
          progressDot
          current={step}
          onChange={() => {}}
          items={[
            {
              title: "Upload File",
              description: "Upload the file.",
            },
            {
              title: "Approve & Process",
              description: "Review the data and process.",
            },
            {
              title: "Review Report",
              description: "Review report post completion.",
            },
          ]}
        />
      </div>
      <div className="bg-white rounded-lg mt-2 px-2 py-2">{render()}</div>
    </div>
  );
}
