export default function Android9(props: any) {
  return (
    <svg
      viewBox="0 0 196 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: 30 }}
    >
      <path
        d="M98 196C152.124 196 196 152.124 196 98C196 43.8761 152.124 0 98 0C43.8761 0 0 43.8761 0 98C0 152.124 43.8761 196 98 196Z"
        fill="#3DDC84"
      />
      <path
        d="M49.7656 52.2922C53.8108 52.3165 57.682 53.9405 60.5339 56.8095C63.3857 59.6785 64.9863 63.5594 64.9862 67.6047V143.708"
        stroke="white"
        stroke-width="6.13"
        stroke-linecap="round"
      />
      <path
        d="M146.234 143.708H85.3059V128.472C85.3059 120.39 88.5163 112.64 94.2309 106.925C99.9455 101.21 107.696 98 115.778 98H131.09C133.097 98 135.084 97.6033 136.936 96.8327C138.789 96.062 140.471 94.9327 141.886 93.5096C143.3 92.0864 144.419 90.3977 145.179 88.5404C145.938 86.6831 146.323 84.694 146.311 82.6875C146.313 75.4873 143.766 68.5188 139.121 63.0176C134.475 57.5163 128.032 53.8378 120.933 52.6342C113.834 51.4305 106.538 52.7796 100.339 56.4421C94.1399 60.1046 89.438 65.8439 87.0668 72.6425C86.4909 74.283 86.0505 75.9679 85.75 77.6803"
        stroke="white"
        stroke-width="6.13"
        stroke-linecap="round"
      />
    </svg>
  );
}
