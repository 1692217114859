import {
  Button,
  Descriptions,
  Divider,
  List,
  Spin,
  Typography,
  Slider as AntSlider,
  Dropdown,
  Space,
} from "antd";
import { useParams } from "react-router-dom";
import { TITLE, BASE_PATH, StatusBadge } from ".";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import { lazy, memo, useEffect, useMemo, useRef, useState } from "react";
import service from "@services/composition";
import { useNavigate } from "react-router-dom";

import { EditOutlined, DownOutlined } from "@ant-design/icons";
import getThumbnailUrl from "@utils/getThumbnailUrl";
import React from "react";
import Slider, { LazyLoadTypes, Settings } from "react-slick";
import VideoPlayer from "@components/VideoPlayer";
import Frame from "../../assets/frame_stripped.png";
import { columnRenderer } from "./Form";
import PreviewPlaylist from "@components/PreviewPlaylist";
const { Title } = Typography;
const settings: any = {
  dots: false,
  arrows: false,
  infinite: true,
  lazyLoad: `progressive`,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: false,
};

function Seeker(props: any) {
  const [hover, setHover] = useState("");
  const jumpToAsset = (id: string) => {
    const index = props.assets.findIndex((asset: any) => asset._id === id);
    const timeToBeSet = props.assets
      .slice(0, index)
      .reduce((acc: number, cur: any) => acc + cur.duration, 0);
    props.onJump(id, timeToBeSet);
  };
  return (
    <div
      className="w-full bg-gray-200 flex mt-8 relative"
      style={{ maxWidth: 600 }}
      onMouseLeave={() => setHover("")}
    >
      {props.assets?.map((asset: any) => {
        return (
          <div
            style={{
              width: `${(asset.duration * 100) / props.totalDuration}%`,
              minHeight: 10,
              transform: asset._id === hover && `scale(1.2)`,
              backgroundColor: asset._id === hover ? "#91d5ff" : "#1890ff",
            }}
            onClick={() => jumpToAsset(asset._id)}
            onMouseEnter={() => setHover(asset._id)}
            className={`border  cursor-pointer`}
          ></div>
        );
      })}
      <div
        className="py-2  bg-gray-100 absolute rounded border border-gray-500"
        style={{
          left: `-3.5px`,
          top: "-40%",
          width: 7,
          // transform: `translateX(20px)`,
          transform: `translateX(${
            (props.currentTime * 600) / props.totalDuration
          }px)`,
        }}
      ></div>
    </div>
  );
}

function PreviewMedia(props: any) {
  useEffect(() => {
    const interval = setInterval(() => {}, props);
  }, [props]);
  return (
    <div>
      {props.mimeType.includes("image") ? (
        <img
          src={getThumbnailUrl(props.mimeType, props.processedUrls)}
          alt={props.name}
          className="w-full h-auto object-contain"
        />
      ) : (
        <VideoPlayer src={props.originalUrl} />
      )}
    </div>
  );
}

function View(props: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const setBreadcrumbs = useBreadcrumbs();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const totalDuration = useMemo(() => {
    return data.assets?.reduce((acc, cur) => acc + cur.duration, 0) || 0;
  }, [data]);

  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "View Device" },
  ]);
  useEffect(() => {
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const handleForm = (entityId?: string) => {
    if (entityId) {
      navigate(`${BASE_PATH}/edit?entity=${entityId}`);
    } else {
      navigate(`${BASE_PATH}/new`);
    }
  };
  const loadData = (id: string) => {
    setLoading(true);
    service
      .get(id)
      .then((res) => {
        setData(res);
        breadcrumbsRef.current = [
          { label: TITLE[1], url: BASE_PATH },
          { label: res.name },
        ];
        setBreadcrumbs(breadcrumbsRef.current);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (id) {
      loadData(id);
    }
  }, [id]);

  return (
    <Spin spinning={loading}>
      <div className="flex justify-between">
        <Title level={3}>{data?.name}</Title>
        <div className="flex gap-x-2">
          <Button icon={<EditOutlined />} onClick={() => handleForm(id)}>
            Edit
          </Button>
        </div>
      </div>

      <div className="bg-white rounded-lg p-3">
        <div>
          <span className="font-semibold">Basic Information</span>
        </div>
        <Divider className="my-2" />
        <div>
          <Descriptions
            size="small"
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="Name" span={1}>
              {data?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Duration" span={2}>
              {totalDuration} seconds
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
      <div className="py-12 flex flex-col items-center ">
        <PreviewPlaylist assets={data.assets} orientation={data.orientation} />
      </div>
      <div className="bg-white rounded-lg p-3 mt-3">
        <div>
          <span className="font-semibold">Asset Information</span>
        </div>
        <Divider className="my-2" />
        <div className="flex flex-wrap gap-x-3 gap-y-3 bg-gray-100 p-4">
          {data.assets?.map((asset: any) => (
            <div className="bg-white rounded-lg p-4">
              <div>
                <img
                  style={{ height: 100, width: "auto" }}
                  alt="logo"
                  src={getThumbnailUrl(asset.mimeType, asset.processedUrls)}
                />
              </div>
              <div className="mt-4">
                {columnRenderer["displayName"](asset.displayName, asset)}
              </div>
            </div>
          ))}
        </div>
        {/* <List
          bordered
          dataSource={data.assets}
          renderItem={(item: any) => (
            <List.Item
              className=""
              extra={
                <img
                  style={{ height: 100, width: "auto" }}
                  alt="logo"
                  src={getThumbnailUrl(item.mimeType, item.processedUrls)}
                />
              }
            >
              <div>
                <div>{item.name}</div>
                <div>
                  {item.dimension.width} x {item.dimension.height}
                </div>
                {item.dimension.duration ? (
                  <div>{Math.round(item.dimension.duration)} seconds</div>
                ) : (
                  <div>{Math.round(item.duration)} seconds</div>
                )}
              </div>
            </List.Item>
          )}
        /> */}
      </div>
    </Spin>
  );
}
export default View;
