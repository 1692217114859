import { Tooltip } from "antd";
import useAndroidIcon from "./useAndroidIcon";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useEditable } from "@chakra-ui/react";
import service from "@services/device";
const DeviceHealth = (props: {
  device: any;
  lastSeen: string;
  socket?: any;
}) => {
  const { device, socket, lastSeen } = props;
  const getAndroidIcon = useAndroidIcon();

  return (
    <div
      style={{ minHeight: 50, minWidth: 75, maxHeight: 50, maxWidth: 75 }}
      className="relative bg-gray-600 rounded border-4 border-black flex justify-center items-center"
    >
      {getAndroidIcon(device.osVersion)}
      <div
        className="rounded-full bg-white absolute flex items-center justify-center"
        style={{ width: 25, height: 25, bottom: -7, right: -7 }}
      >
        {socket?.socket && socket.socket !== "OK" ? (
          <Tooltip title="Online">
            <div
              className="bg-green-500 rounded-full"
              style={{ width: 15, height: 15 }}
            />
          </Tooltip>
        ) : (
          <Tooltip
            title={
              lastSeen || device.lastSeen
                ? `Last seen: ${dayjs(lastSeen || device.lastSeen).format(
                    "DD/MM/YY hh:mm A"
                  )}`
                : `Not seen online yet`
            }
          >
            <div
              className="bg-red-500 rounded-full"
              style={{ width: 15, height: 15 }}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default function useDevicesHealth(props: {
  devices: any[];
  ids: string[];
  onUpdate: (sockets: any[]) => void;
}) {
  const { devices, ids } = props;
  const [sockets, setSockets] = useState<any[]>([]);
  const previousDevices = useRef<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const intervalRef = useRef<any>();
  const [payload, setPayload] = useState<any>();
  useEffect(() => {
    if (devices.length === 0 && ids.length > 0) {
      handleLoadDevices(ids);
    }
  }, [ids]);
  const areDevicesEqual = (a: any[], b: any[]) => {
    if (a.length !== b.length) return false;
    return a.every((d, i) => d.deviceId === b[i].deviceId);
  };
  useEffect(() => {
    if (
      devices.filter((d) => !!d).length > 0 &&
      !areDevicesEqual(devices, previousDevices.current)
    ) {
      previousDevices.current = devices;
      setData(devices);
      const payload: any = {};
      Object.keys(devices).forEach((key) => {
        payload[devices[key].deviceId] = {
          Render: (props: any) => (
            <DeviceHealth device={devices[key]} {...props} />
          ),
        };
      });
      setPayload(payload);
    }
  }, [devices]);
  const handleLoadDevices = async (ids: string[]) => {
    try {
      setLoading(true);
      const data = await Promise.all(ids.map((id) => service.get(id)));
      setData(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handlePing = async (data) => {
    try {
      const sockets = await service.pingDevices(
        data.map((d) => ({ id: d.deviceId, _id: d._id }))
      );
      props.onUpdate(sockets);
      setSockets(sockets);
    } catch (error) {}
  };

  const handleStartPing = async (data: any[]) => {
    try {
      intervalRef.current = setInterval(async () => {
        try {
          handlePing(data);
        } catch (error) {}
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (data?.length > 0) {
      handlePing(data);
      handleStartPing(data);
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [data]);
  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);
  useEffect(() => {
    const payload: any = {};
    data.forEach((d) => {
      const socket = sockets.find((s) => s.id === d.deviceId);
      payload[d.deviceId] = {
        socket: sockets.find((s) => s.id === d.deviceId),
        isConnected: !socket ? false : socket.socket !== "OK",
        Render: (props: any) => (
          <DeviceHealth
            device={d}
            socket={sockets.find((s) => s.id === d.deviceId)}
            {...props}
          />
        ),
      };
    });
    setPayload(payload);
  }, [sockets]);
  return { loading, payload };
}
