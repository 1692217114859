import { Menu, Layout, Drawer, Tag } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  DashboardOutlined,
  BlockOutlined,
  TeamOutlined,
  PictureOutlined,
  AppstoreOutlined,
  DesktopOutlined,
  SettingOutlined,
  PlayCircleOutlined,
  LayoutOutlined,
} from "@ant-design/icons";
import Logo from "./Vectors/Logo";
import LogoImage from "../assets/logo.png";
import LogoCImage from "../assets/logoC.png";
import MenuItem from "antd/lib/menu/MenuItem";
import { useSelector } from "react-redux";
import { Store } from "@store/index";
import useIsAdmin from "@hooks/useIsAdmin";

const { Sider } = Layout;
function getItem(
  label: React.ReactNode,
  key: React.Key,
  onClick: any,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
  } as any;
}
const ADMIN_NAV_ITEMS: any = [
  {
    id: "devices",
    title: "Devices",
    icon: <BlockOutlined />,
    link: "devices",
  },
  {
    id: "clients",
    title: "Clients",
    icon: <TeamOutlined />,
    link: "clients",
  },
];
const CLIENT_NAV_ITEMS: any = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <DashboardOutlined />,
    link: "dashboard",
  },
  {
    id: "compositions",
    title: "Compositions",
    icon: <DesktopOutlined />,
    link: "compositions",
  },
  {
    id: "campaigns",
    title: "Campaigns",
    icon: <PlayCircleOutlined />,
    link: "campaigns",
  },
  {
    id: "deviceGroups",
    title: "Screen Groups",
    icon: <AppstoreOutlined />,
    link: "device-groups",
  },
  {
    id: "registeredDevices",
    title: "Registered Screens",
    icon: <BlockOutlined />,
    link: "registered-devices",
  },
  {
    id: "assets",
    title: "Media",
    icon: <PictureOutlined />,
    link: "assets",
  },
  {
    id: "layouts",
    title: "Layouts",
    icon: <LayoutOutlined />,
    link: "layouts",
  },
  // {
  //   id: "settings",
  //   title: "Settings",
  //   icon: <SettingOutlined />,
  //   children: [
  //     {
  //       id: "defaultCampaign",
  //       title: "Default Campaign",
  //       link: "settings/default-campaign",
  //     },
  //   ],
  // },
];
export default function Navigation(props: {
  showNav: boolean;
  collapsed: boolean;
  onCollapse: any;
  onShowDrawer: any;
  showDrawer: boolean;
  onNavigate: any;
  authState: any;
}) {
  const uiState = useSelector((state: Store) => state.ui);
  const isAdmin = useIsAdmin();
  const [navItems, setNavItems] = useState(CLIENT_NAV_ITEMS);
  const [logo, setLogo] = useState(LogoImage);
  useEffect(() => {
    if (isAdmin) {
      setNavItems(ADMIN_NAV_ITEMS);
    } else {
      setNavItems(CLIENT_NAV_ITEMS);
    }
  }, [isAdmin]);
  useEffect(() => {
    if (props.collapsed) {
      setTimeout(() => {
        setLogo(LogoCImage);
      }, 300);
    } else {
      setLogo(LogoImage);
    }
  }, [props.collapsed]);
  const NavMenu = (
    <div>
      <Menu
        theme="light"
        mode="inline"
        className="bg-transparent"
        items={navItems
          .filter((navItem) =>
            navItem.isAdminOnly ? props.authState.user?.isAdmin : true
          )
          .map((item) =>
            getItem(
              item.title,
              item.id,
              item.link ? () => props.onNavigate(item.link) : undefined,
              item.icon,
              item.children?.map?.((c) =>
                getItem(c.title, c.link, () => props.onNavigate(c.link))
              )
            )
          )}
      ></Menu>
    </div>
  );
  return props.showNav && uiState.showSider ? (
    <Sider
      collapsible
      collapsed={props.collapsed}
      onCollapse={props.onCollapse}
      theme="light"
      className="bg-transparent"
    >
      <div
        className={`flex justify-center items-center ${
          props.collapsed ? "p-0 mt-2 h-8" : "p-4"
        }`}
      >
        <img src={logo} height="60" />
      </div>
      {NavMenu}
    </Sider>
  ) : (
    <Drawer
      placement="left"
      closable={false}
      onClose={() => props.onShowDrawer(false)}
      visible={props.showDrawer}
      key="left"
      bodyStyle={{
        padding: "0px",
        backgroundColor: "#fff",
      }}
      contentWrapperStyle={{ width: "200px" }}
    >
      {NavMenu}
    </Drawer>
  );
}
