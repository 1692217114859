import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Form,
  Typography,
  Input,
  Select,
  Button,
  Space,
  Spin,
  Divider,
  Upload,
  Modal,
  Alert,
  Empty,
} from "antd";
import { TITLE, BASE_PATH } from ".";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import querystring from "query-string";
import service from "@services/asset";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import UploadComponent from "@components/Upload";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import DraggerComponent from "@components/Dragger";
import UploadDirectory from "@components/UploadDirectory";
const { Title } = Typography;
const { Option } = Select;
const { Dragger } = Upload;

const DirectoryUploadModal = (props: any) => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [waitForUpload, setWaitForUpload] = useState(false);
  useEffect(() => {
    if (!uploading) setWaitForUpload(false);
  }, [uploading]);
  return (
    <Modal
      {...props}
      onOk={() => (uploading ? setWaitForUpload(true) : props.onOk(fileList))}
      maskClosable={false}
      closable={false}
    >
      <UploadDirectory
        onChange={(files) => setFileList(files)}
        onUploading={setUploading}
      />

      {waitForUpload && (
        <Alert
          showIcon
          className="mt-6"
          message="Please wait while the assets are being uploaded"
          type="warning"
        />
      )}
    </Modal>
  );
};
const MutipleAssetsUploadModal = (props: any) => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [waitForUpload, setWaitForUpload] = useState(false);
  useEffect(() => {
    if (!uploading) setWaitForUpload(false);
  }, [uploading]);
  return (
    <Modal
      {...props}
      onOk={() => (uploading ? setWaitForUpload(true) : props.onOk(fileList))}
      maskClosable={false}
      closable={false}
    >
      <DraggerComponent
        onChange={(files) => {
          setFileList(files);
        }}
        onUploading={setUploading}
      />

      {waitForUpload && (
        <Alert
          showIcon
          className="mt-6"
          message="Please wait while the assets are being uploaded"
          type="warning"
        />
      )}
    </Modal>
  );
};

export default function EntityForm(props: any) {
  // const breadcrumbsRef = useRef([
  //   { label: TITLE[1], url: BASE_PATH },
  //   { label: "Device Form" },
  // ]);
  const folder = props.folder;
  const [form] = Form.useForm();
  const images = Form.useWatch("images", form);
  const [id, setId] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadLoading, setLoadLoading] = useState(false);
  const [dependentsLoading, setDependentsLoading] = useState(false);
  const [masters, setMasters] = useState<any>({});
  // const setBreadcrumbs = useBreadcrumbs();
  const addFunctionRef = useRef<(defaultValue?: any) => void>(() => {});
  const [multipleAssetOpen, setMultipleAssetOpen] = useState(false);
  const [directoryAssetOpen, setDirectoryAssetOpen] = useState(false);
  const [errors, setErrors] = useState<any>({
    basic: false,
    material: false,
    fluid: false,
    test: false,
  });
  const handleSubmit = (values: any) => {
    const images = values.images.map((image) => ({
      originalUrl: image.asset.url,
      name: image.name,
      displayName: image.displayName,
      mimeType: image.asset.type,
      dimension: image.asset.dimension,
      size: image.asset.size,
    }));

    setLoading(true);
    if (id) {
      service
        .update({ ...values, _id: id })
        .then((response) => {
          if (!response.error) {
            navigate(BASE_PATH);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      service
        .createMultiple(images.map((i) => ({ ...i, folder })))
        .then((response) => {
          if (!response.error) {
            if (props.onSubmit) {
              props.onSubmit();
            } else {
              navigate(BASE_PATH);
            }
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleCancel = () => {
    props.onCancel();
  };
  useEffect(() => {
    const params: any = querystring.parse(location.search);
    if (!_.isEmpty(params) && params.entity) {
      setId(params.entity);
      setLoadLoading(true);
      service
        .get(params.entity)
        .then((payload) => {
          form.setFieldsValue({ ...payload });
        })
        .finally(() => setLoadLoading(false));
    }
  }, [location]);
  useEffect(() => {
    handleLoadDependencies();
    // setBreadcrumbs(breadcrumbsRef.current);
  }, []);

  const handleLoadDependencies = async () => {
    try {
      setDependentsLoading(true);
      const promises = [];
      const [] = await Promise.all(promises);
      setMasters({});
    } catch (error) {
    } finally {
      setDependentsLoading(false);
    }
  };
  const handleDefaultName = (index: number, images) => {
    const imageObject = images[index];
    if (!imageObject) {
      return;
    }
    if (!imageObject.name) {
      let name = `${imageObject.url.split("/").pop()}`;
      name = `${name.substring(0, name.lastIndexOf("."))}`;

      imageObject.name = name;

      form.setFieldsValue({ images });
    }
  };
  const handleDefaultNames = (images) => {
    for (let idx in images) {
      if (!images[idx] || !images[idx].asset) {
        continue;
      }

      if (!images[idx].name) {
        let name = `${images[idx].asset.url.split("/").pop()}`;
        name = `${name.substring(0, name.lastIndexOf("."))}`;

        images[idx].name = name;
      }
      if (!images[idx].displayName) {
        let displayName = `${images[idx].asset.name}`;

        images[idx].displayName = displayName;
      }
    }
    form.setFieldsValue({ images });
  };

  return (
    <div>
      <DirectoryUploadModal
        open={directoryAssetOpen}
        onCancel={() => setDirectoryAssetOpen(false)}
        onOk={(files) => {
          setDirectoryAssetOpen(false);
          for (let file of files) {
            addFunctionRef.current({ asset: file });
          }
        }}
        destroyOnClose
      />
      <MutipleAssetsUploadModal
        open={multipleAssetOpen}
        onCancel={() => setMultipleAssetOpen(false)}
        onOk={(files) => {
          setMultipleAssetOpen(false);
          for (let file of files) {
            addFunctionRef.current({ asset: file });
          }
        }}
        destroyOnClose
      />

      {/* {!props.hideTitle && (
        <Title level={3} className="mb-4">
          {id ? "Update" : "New"} {TITLE[0]}
        </Title>
      )} */}
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        onValuesChange={(changedValues, allValues) => {
          console.log(allValues);

          if (changedValues.images) {
            handleDefaultNames(allValues.images);
          }
        }}
      >
        <div>
          <Form.List name="images">
            {(fields, { add, remove }) => {
              addFunctionRef.current = add;

              return (
                <>
                  <div className="flex justify-between items-center">
                    <div className="flex gap-x-2">
                      <Button
                        type="link"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        className="w-max"
                      >
                        Add Single
                      </Button>
                      <Button
                        type="link"
                        onClick={() => setMultipleAssetOpen(true)}
                        block
                        icon={<PlusOutlined />}
                        className="w-max"
                      >
                        Add Multiple
                      </Button>
                      <Button
                        type="link"
                        onClick={() => setDirectoryAssetOpen(true)}
                        block
                        icon={<PlusOutlined />}
                        className="w-max"
                      >
                        Add Folder
                      </Button>
                    </div>
                  </div>
                  <Divider className="my-1" />
                  {(!images || images?.length === 0) && (
                    <div className="py-4">
                      <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{
                          height: 60,
                        }}
                        className="flex flex-col items-center justify-center"
                        description={
                          <span className="text-gray-500 italic">
                            No Assets Added Yet, Please add some assets
                          </span>
                        }
                      ></Empty>
                    </div>
                  )}
                  <div className="flex flex-col mt-3 px-4">
                    {fields.map((field, index) => (
                      <div key={field.key} className="flex gap-x-8">
                        <div>{index + 1}.</div>
                        <Form.Item
                          {...field}
                          name={[field.name, "displayName"]}
                          rules={[
                            { required: true, message: "Name is Required" },
                          ]}
                        >
                          <Input placeholder="Enter Name" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "asset"]}
                          valuePropName="fileList"
                          rules={[
                            {
                              required: true,
                              message:
                                "File is Required, please wait while it's uploading",
                            },
                          ]}
                        >
                          <UploadComponent />
                        </Form.Item>
                        <div>
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => remove(field.name)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              );
            }}
          </Form.List>
        </div>

        <div className="mt-3 px-4">
          <Form.Item noStyle>
            <Space>
              <Button type="default" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
