import { EventWrapper } from "@components/EventWrapper";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import useCalendarComponents from "@hooks/useCalendarComponents";
import {
  List,
  Tooltip as AntTooltip,
  Tabs,
  Tag,
  Typography,
  Select,
  Collapse,
  Checkbox,
  Segmented,
  Button,
  Divider,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Views, Calendar, dayjsLocalizer, View } from "react-big-calendar";
import service from "@services/campaign";
import deviceService from "@services/device";
import groupService from "@services/deviceGroup";
import { useNavigate } from "react-router-dom";
import { Line, Doughnut } from "react-chartjs-2";
import { debounce, throttle } from "throttle-debounce";
import AntCalendar from "@components/Core/Calendar";
import * as dashboardActions from "@store/dashboardSlice";
import type { CalendarMode } from "antd/es/calendar/generateCalendar";
import { Navigate, ToolbarProps } from "react-big-calendar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ScriptableContext,
} from "chart.js/auto";

import {
  ExclamationCircleOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import useDevicesHealth from "@hooks/useDevicesHealth";
import { useDispatch, useSelector } from "react-redux";
import { titleCase } from "title-case";
import PluginService from "chart.js/dist/core/core.plugins";
import status from "@utils/status";
import campaign from "@services/campaign";
import EllipsisMiddle from "@utils/EllipsisMiddle";
import Paragraph from "antd/lib/typography/Paragraph";
import { Store } from "@store/index";
const localizer = dayjsLocalizer(dayjs);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const { Text } = Typography;
const DEFAULT_OPTIONS: any = {
  datasets: {
    line: {
      borderWidth: 1.5,
      pointBackgroundColor: "blue",
      pointBorderWidth: 1.5,
      pointBorderColor: "white",
    },
  },
  interaction: {
    mode: "index",
    intersect: false,
  },
  // Hide Legend
  plugins: {
    legend: {
      display: false,
    },
  },
};

const CustomToolbar = (props: ToolbarProps) => {
  const dateRender = `${dayjs(props.date)
    .startOf("week")
    .format("Do MMMM")} - ${dayjs(props.date).endOf("week").format("Do MMMM")}`;
  return (
    <div>
      <div className="flex justify-between items-center px-1">
        <div
          className="text-blue-500 cursor-pointer"
          onClick={() => props.onNavigate(Navigate.TODAY)}
        >
          Today
        </div>

        <label>{dateRender}</label>
        <Segmented
          size="small"
          options={[Views.AGENDA, Views.DAY, Views.WEEK, Views.MONTH].map(
            titleCase
          )}
          defaultValue={"Agenda"}
          onChange={(value: any) => props.onView(value.toLowerCase())}
        />
      </div>
      <Divider className="my-2" />
    </div>
  );
};

function GlobalDashboard(props: any) {
  const [idleTime, setIdleTime] = useState<any[]>([]);
  const [groupHealth, setGroupHealth] = useState<any>({});
  const authState = useSelector((state: Store) => state.auth);
  const dashboardState = useSelector((state: Store) => state.dashboard);
  const dispatch = useDispatch();
  const health = useDevicesHealth({
    devices:
      props.groups
        ?.map((d) => d.devices)
        .reduce((acc, devices) => acc.concat(devices), []) || [],
    ids: [],
    onUpdate: (sockets) => {},
  });
  useEffect(() => {
    handleLoadIdleTime();
    dispatch(dashboardActions.getUsageAsync({}));
  }, []);
  useEffect(() => {
    if (props.groups && health?.payload) {
      handleLoadIdleTime();
      const groupHealth: any = {};
      props.groups.forEach((group) => {
        const devices = group.devices.map((d) => health.payload[d.deviceId]);
        const online = devices.filter((d) => d?.isConnected).length;
        const total = devices.length;
        groupHealth[group._id] = {
          online,
          total,
          health: total === 0 ? 0 : online / total,
        };
      });
      setGroupHealth(groupHealth);
    }
  }, [props.groups, health?.payload]);

  const handleLoadIdleTime = useCallback(
    throttle(1000, () => {
      deviceService.getActiveTime().then((activeTimes) =>
        setIdleTime(
          activeTimes
            .map((group) => ({
              ...group,
              idleTime: 1 - group.activeTime,
            }))
            .sort((a, b) => (a.idleTime > b.idleTime ? -1 : 1))
            .slice(0, 5)
        )
      );
    }),
    []
  );
  const activatedDeviceCount = authState.client?.devices?.filter(
    (d: any) => d.status === "ACTIVATED"
  ).length;
  const availableDeviceCount =
    authState.client?.licenseCount - activatedDeviceCount;

  const totalConnected = Object.values(health.payload || {}).filter(
    (d: any) => d.isConnected
  ).length;
  const totalDevices = Object.values(health.payload || {}).length;
  const usage =
    dashboardState.usage.total > 1000000000
      ? `${(dashboardState.usage.total / 1000000000).toFixed(2)} GB`
      : `${(dashboardState.usage.total / 1000000).toFixed(2)} MB`;
  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex gap-x-4">
        <div className="w-full flex  gap-y-4 gap-x-4">
          <div
            className="flex flex-col justify-between rounded-lg px-3 py-4"
            style={{ background: "#311c7f" }}
          >
            <div className="text-white font-light text-xs">
              License Utilization
            </div>
            <div className="flex justify-between items-end">
              <div>
                <div className="text-white text-4xl font-semibold mt-2">
                  {(
                    (activatedDeviceCount * 100) /
                    (availableDeviceCount + activatedDeviceCount)
                  ).toFixed(1)}{" "}
                  %
                </div>
                <div className="flex mt-2 font-light text-xs">
                  <Tag color="success">{activatedDeviceCount} Activated</Tag>
                  <Tag color="blue">{availableDeviceCount} Available</Tag>
                </div>
              </div>
              <div style={{ height: 80 }} className="ml-4">
                <Doughnut
                  data={{
                    labels: ["Activated", "Available"],
                    datasets: [
                      {
                        data: [activatedDeviceCount, availableDeviceCount],
                        backgroundColor: ["white", "transparent"],
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    datasets: {
                      doughnut: {
                        borderWidth: 1,
                        borderColor: "white",
                        hoverOffset: 5,
                      },
                    },
                    cutout: "70%",
                    plugins: {
                      legend: {
                        display: false,
                        position: "bottom",
                      },

                      title: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="flex flex-col justify-between rounded-lg px-4 py-4"
            style={{ background: "#C23185" }}
          >
            <div className="text-white font-light  text-xs">
              Connected Screens
            </div>
            <div className="flex justify-between items-end">
              <div>
                <div className="text-white text-4xl font-semibold mt-2">
                  {((totalConnected * 100) / (totalDevices || 1)).toFixed(1)} %
                </div>
                <div className="flex mt-2 font-light text-xs">
                  <Tag color="success">{totalConnected} Online</Tag>
                  <Tag color="default">
                    {totalDevices - totalConnected} Offline
                  </Tag>
                </div>
              </div>
              <div style={{ height: 80 }} className="ml-4">
                <Doughnut
                  data={{
                    labels: ["Online", "Offline"],
                    datasets: [
                      {
                        data: [totalConnected, totalDevices - totalConnected],
                        backgroundColor: ["white", "transparent"],
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    datasets: {
                      doughnut: {
                        borderWidth: 1,
                        borderColor: "white",
                        hoverOffset: 5,
                      },
                    },
                    cutout: "70%",
                    plugins: {
                      legend: {
                        display: false,
                        position: "bottom",
                      },

                      title: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="flex flex-col justify-between rounded-lg px-4 py-4"
            style={{ background: "#C23185" }}
          >
            <div className="text-white font-light  text-xs">Storage Usage</div>
            <div className="flex justify-between items-end">
              <div>
                <div className="text-white text-4xl font-semibold mt-2">
                  {((dashboardState.usage.total * 100) / 10000000000).toFixed(
                    2
                  )}{" "}
                  %
                </div>
                <div className="flex mt-2 font-light text-xs text-white py-1">
                  {usage} used of 10GB
                </div>
              </div>
              <div style={{ height: 80 }} className="ml-4">
                <Doughnut
                  data={{
                    labels: ["Used", "Available"],
                    datasets: [
                      {
                        data: [
                          Math.round(dashboardState.usage.total / 1000000),
                          Math.round(
                            (10000000000 - dashboardState.usage.total) / 1000000
                          ),
                        ],
                        backgroundColor: ["white", "transparent"],
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    datasets: {
                      doughnut: {
                        borderWidth: 1,
                        borderColor: "white",
                        hoverOffset: 5,
                      },
                    },
                    cutout: "70%",
                    plugins: {
                      legend: {
                        display: false,
                        position: "bottom",
                      },

                      title: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className="w-4/5 bg-white rounded-xl p-4 pb-8"
          style={{ height: 300 }}
        >
          <div>Device Utilization</div>
          <Line
            options={{
              ...DEFAULT_OPTIONS,
              radius: 5,
              maintainAspectRatio: false,
              interaction: {
                intersect: false,
              },
              scales: {
                x: {
                  grid: {
                    drawBorder: false,
                    display: false,
                  },
                },
                y: {
                  offset: true,
                  ticks: {
                    display: true,
                    stepSize: 25,
                    min: 0,
                    max: 100,
                  },
                  grid: {
                    drawBorder: false,
                    display: true,
                  },
                },
              },
            }}
            datasetIdKey="id"
            data={{
              labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
              datasets: [
                {
                  data: [0, 0, 0, 0, 0, 0, 0].map((e) => Math.random() * 100),
                  tension: 0.3,
                  fill: "start",
                  backgroundColor: (context: ScriptableContext<"line">) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                    gradient.addColorStop(0.1, "#1f3bb322");
                    gradient.addColorStop(0.5, "#1f3bb300");
                    return gradient;
                  },
                  borderColor: "#1f3bb3",
                },
              ],
            }}
          />
        </div> */}
      </div>
      <div className="flex gap-x-4">
        <div className="w-1/3 p-4 bg-white rounded-xl">
          <div>Group Health</div>
          <div className="mt-4">
            <List
              bordered={false}
              dataSource={props.groups}
              renderItem={(group: any) => {
                const deviceMetrics = groupHealth[group._id];
                const color = !deviceMetrics
                  ? "default"
                  : deviceMetrics.health > 0.8
                  ? "green"
                  : deviceMetrics.health > 0.5
                  ? "gold"
                  : "red";
                return (
                  <List.Item className="w-full">
                    <div
                      key={group._id}
                      className="flex justify-between w-full"
                    >
                      <div className="font-semibold">{group.name}</div>
                      {group.devices.length > 0 && (
                        <Tag color={color}>
                          {(deviceMetrics?.health * 100).toFixed(2)}% Screens
                          Online
                        </Tag>
                      )}
                    </div>
                  </List.Item>
                );
              }}
            />
          </div>
        </div>
        <div className="w-1/3 p-4 bg-white rounded-xl">
          <div>Group Idle Time</div>
          <div className="mt-4">
            <List
              bordered={false}
              dataSource={idleTime}
              renderItem={(group) => {
                const tagColor =
                  group.idleTime > 0.8
                    ? "red"
                    : group.idleTime > 0.5
                    ? "orange"
                    : "green";
                const tagIcon =
                  group.idleTime > 0.8 ? (
                    <ExclamationCircleOutlined />
                  ) : group.idleTime > 0.5 ? (
                    <WarningOutlined />
                  ) : (
                    <CheckCircleOutlined />
                  );
                return (
                  <List.Item className="w-full">
                    <div
                      key={group._id}
                      className="flex justify-between w-full"
                    >
                      <div className="font-semibold">{group.name}</div>
                      <Tag color={tagColor} icon={tagIcon}>
                        {(group.idleTime * 100).toFixed(2)} %
                      </Tag>
                    </div>
                  </List.Item>
                );
              }}
            />
          </div>
        </div>
        <div className="w-1/3 p-4 bg-white rounded-xl">
          <div>Screen Alerts</div>
        </div>
      </div>
    </div>
  );
}

function GroupWiseDashboard(props: any) {
  const calendarProps = useCalendarComponents();
  const [events, setEvents] = useState([]);
  const [duration, setDuration] = useState<any>({
    start: dayjs().startOf("day"),
    end: dayjs().endOf("week"),
  });
  const [idleTime, setIdleTime] = useState<any[]>([]);

  const [eventFilters, setEventFilters] = useState<any>({
    status: [],
    tags: [],
  });
  const [tags, setTags] = useState<any[]>([]);
  const [groupEvents, setGroupEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(Views.AGENDA);
  const onPanelChange = (value: Dayjs, mode: CalendarMode) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };
  const [selectedGroup, setSelectedGroup] = useState<any>();
  const health = useDevicesHealth({
    devices:
      selectedGroup?.devices.reduce(
        (acc, devices) => acc.concat(devices),
        []
      ) || [],
    ids: [],
    onUpdate: (sockets) => {},
  });
  useEffect(() => {
    handleLoadTags();
  }, []);

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  );
  useEffect(() => {
    setSelectedGroup(props.groups?.[0]);
  }, [props.groups]);
  useEffect(() => {
    handleLoadEvents(duration.start, duration.end);
  }, [localStorage.getItem("client"), duration]);
  const cProps = {
    ...calendarProps,
    components: {
      ...calendarProps.components,
      eventWrapper: EventWrapper,
      toolbar: CustomToolbar,
      agenda: {
        date: ({ day, label }) => {
          day = dayjs(day);
          const isToday = day.isSame(dayjs(), "day");

          return (
            <div className="flex gap-x-3 items-baseline">
              {isToday ? (
                <div className="relative">
                  <div
                    className="bg-primary-500 text-white text-lg flex items-center justify-center"
                    style={{ height: 30, width: 30, borderRadius: 30 }}
                  >
                    {day.get("date")}
                  </div>
                </div>
              ) : (
                <span className={`text-lg font-light`}>{day.get("date")}</span>
              )}

              <span className="text-xs uppercase font-semibold text-gray-400">
                {day.format("MMM, ddd")}
              </span>
            </div>
          );
        },
        time: ({ event, day, label }) => {
          return <span className="font-light text-xs">{label}</span>;
        },
        event: ({ event }) => {
          const color =
            event.status === "ACTIVE"
              ? "bg-green-500"
              : event.status === "DRAFT"
              ? "bg-gray-500"
              : event.status === "PUBLISHED"
              ? "bg-blue-500"
              : event.status === "CANCELLED"
              ? "bg-red-500"
              : event.status === "COMPLETED"
              ? "bg-yellow-500"
              : "bg-gray-500";
          return (
            <span className="cursor-pointer flex items-center gap-x-2">
              <AntTooltip title={titleCase(event.status?.toLowerCase() || "")}>
                <span className={`p-1 ${color} rounded-full`} />
              </AntTooltip>
              {event.campaignName}
              {event.isEmergency && (
                <AntTooltip title="Quick Play">
                  <Tag color="volcano">Q</Tag>
                </AntTooltip>
              )}
            </span>
          );
        },
      },
    },
  };
  const handleLoadEvents = async (start: Dayjs, end: Dayjs) => {
    try {
      setLoading(true);
      const campaigns = await service.getCampaignsForDateRange(
        null,
        start.toISOString(),
        end.toISOString()
      );
      setEvents(
        campaigns
          .map((e) =>
            e.slots.map((s) => ({
              ...s,
              campaignName: e.name,
              campaignCode: e.code,
              isEmergency: e.isEmergency,
              deviceGroups: e.groups,
              status: e.status,
              campaignId: e._id,
              tags: e.tags,
            }))
          )
          .flat()
          .map((e) => ({
            ...e,
            start: dayjs(e.start).toDate(),
            end: dayjs(e.end).toDate(),
          }))
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleLoadTags = async () => {
    try {
      const tags = await campaign.getUniqueTags();
      setTags(tags);
    } catch (error) {
      console.log(error);
    }
  };
  const data: any = health.payload;
  const totalConnected = Object.values(data || {}).filter(
    (d: any) => d.isConnected
  ).length;
  const totalDevices = Object.values(data || {}).length;
  const connectedPercent = (totalConnected / totalDevices) * 100;
  const handleLoadIdleTime = useCallback(
    throttle(1000, () => {
      deviceService
        .getActiveTimeForGroup(selectedGroup._id)
        .then((activeTimes) =>
          setIdleTime(
            activeTimes
              .map((activeTime) => ({
                ...activeTime,
                idleTime: 1 - (activeTime.activeTime || 0),
              }))
              .sort((a, b) => (a.idleTime > b.idleTime ? -1 : 1))
              .slice(0, 5)
          )
        );
    }),
    [selectedGroup]
  );
  useEffect(() => {
    if (selectedGroup) {
      const groupEvents = events.filter((e) =>
        e.deviceGroups?.find((g) => g._id === selectedGroup._id)
      );
      handleLoadIdleTime();
      setGroupEvents(groupEvents);
    }
  }, [events, selectedGroup]);
  useEffect(() => {
    // console.log(eventFilters);
    // console.log(groupEvents);
    // console.log(idleTime);
  }, [eventFilters, groupEvents, idleTime]);
  return (
    <div>
      <div className="bg-white rounded-lg mb-2 p-2">
        <div className="flex gap-x-2 items-baseline">
          <div>Screen Group:</div>
          <Select
            value={selectedGroup?._id}
            allowClear={false}
            onChange={(id) =>
              setSelectedGroup(props.groups.find((g) => g._id === id))
            }
          >
            {props.groups
              .map((group) => (
                <Select.Option key={group._id} value={group._id}>
                  {group.name}
                </Select.Option>
              ))
              .flat()}
          </Select>
        </div>
      </div>
      <div className="flex gap-x-2">
        <div className="w-3/12 flex flex-col gap-y-2">
          <div>
            <AntCalendar
              fullscreen={false}
              onPanelChange={onPanelChange}
              value={duration.start}
              onSelect={(date) =>
                setDuration({
                  start: date.startOf("day"),
                  end:
                    view === Views.AGENDA
                      ? date.add(30, "days")
                      : date.endOf(view),
                })
              }
            />
          </div>
          <div className="p-2 bg-white rounded-lg">
            <div>Filter Events</div>
            <div className="mt-4">
              <Collapse
                className="filters-collapse"
                bordered={false}
                defaultActiveKey={["1"]}
                expandIconPosition="end"
              >
                <Collapse.Panel header="By Status" key="1">
                  <Checkbox.Group
                    options={status.map((s) => ({ ...s, label: s.render }))}
                    onChange={(values) =>
                      setEventFilters({
                        ...eventFilters,
                        status: values,
                      })
                    }
                  />
                </Collapse.Panel>
                <Collapse.Panel header="By Tag" key="2">
                  <Checkbox.Group
                    options={tags.map((t) => ({
                      label: <Tag>{t._id}</Tag>,
                      value: t._id,
                    }))}
                    onChange={(values) =>
                      setEventFilters({
                        ...eventFilters,
                        tags: values,
                      })
                    }
                  />
                </Collapse.Panel>
              </Collapse>
            </div>
          </div>
        </div>
        <div className="w-6/12 bg-white p-2 rounded-lg">
          <Calendar
            {...cProps}
            views={{ agenda: true, week: true, day: true, month: true }}
            defaultDate={defaultDate}
            date={duration.start.toDate()}
            defaultView={view}
            events={groupEvents.filter((e) => {
              if (eventFilters.status.length > 0) {
                if (!eventFilters.status.includes(e.status)) {
                  return false;
                }
              }
              if (eventFilters.tags.length > 0) {
                if (!e.tags?.find((t) => eventFilters.tags.includes(t))) {
                  return false;
                }
              }
              return true;
            })}
            selectable
            scrollToTime={scrollToTime}
            onSelectEvent={(event) =>
              window.open(`/campaigns/${event.campaignId}`, "_blank")
            }
            style={{ height: "80vh" }}
            onNavigate={(newDate, view: any, action) => {
              setView(view);
              setDuration({
                start: dayjs(newDate).startOf(view),
                end:
                  view === Views.AGENDA
                    ? dayjs(newDate).add(30, "days")
                    : dayjs(newDate).endOf(view),
              });
            }}
          />
        </div>
        <div className="w-3/12 flex flex-col gap-y-2">
          <div className="p-2 bg-white rounded-lg">
            <div>Group Health</div>
            <div className="p-4">
              <Doughnut
                data={{
                  labels: ["Offline", "Online"],
                  datasets: [
                    {
                      data: [100 - connectedPercent, connectedPercent],
                      backgroundColor: ["rgb(255, 99, 132)", "#77bf38"],
                    },
                  ],
                }}
                plugins={[
                  {
                    id: "group_health",
                    beforeDatasetUpdate: function (chart) {
                      var width = chart.width,
                        height = chart.height,
                        ctx = chart.ctx;
                      ctx.restore();
                      var fontSize = (height / 200).toFixed(2);
                      ctx.font = fontSize + "em sans-serif";
                      ctx.textBaseline = "top";
                      var text = connectedPercent.toFixed(2) + "%",
                        textX = Math.round(
                          (width - ctx.measureText(text).width) / 2
                        ),
                        textY = height / 2;
                      ctx.fillText(text, textX, textY);
                      ctx.save();
                    },
                  },
                ]}
                options={{
                  responsive: true,
                  datasets: {
                    doughnut: {
                      borderWidth: 1,
                      borderColor: "white",
                      hoverOffset: 5,
                    },
                  },
                  cutout: "80%",
                  plugins: {
                    legend: {
                      display: true,
                      position: "bottom",
                    },

                    title: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className="p-2 bg-white rounded-lg">
            <div>Idle Screens</div>
            <div className="mt-4">
              <List
                bordered={false}
                dataSource={idleTime}
                renderItem={(device) => {
                  const tagColor =
                    device.idleTime > 0.8
                      ? "red"
                      : device.idleTime > 0.5
                      ? "orange"
                      : "green";
                  const tagIcon =
                    device.idleTime > 0.8 ? (
                      <ExclamationCircleOutlined />
                    ) : device.idleTime > 0.5 ? (
                      <WarningOutlined />
                    ) : (
                      <CheckCircleOutlined />
                    );
                  return (
                    <List.Item className="w-full flex items-center">
                      <div
                        key={device.device}
                        className="flex justify-between w-full items-center"
                      >
                        <div className="font-semibold">
                          {/* <Text ellipsis={true} style={{ width: "80%" }}>
                            {device.deviceId}
                          </Text> */}
                          <EllipsisMiddle suffixCount={16}>
                            {device.deviceId}
                          </EllipsisMiddle>
                        </div>
                        <Tag color={tagColor} icon={tagIcon}>
                          {(device.idleTime * 100).toFixed(2)} %
                        </Tag>
                      </div>
                    </List.Item>
                  );
                }}
              />
            </div>
          </div>
          <div className="p-2 bg-white rounded-lg">
            <div>Screen Alerts</div>
            <div className="mt-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Dashboard(props: any) {
  const breadcrumbsRef = useRef([]);
  const setBreadcrumbs = useBreadcrumbs();
  const calendarProps = useCalendarComponents();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("1");
  const [groups, setGroups] = useState<any[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    setBreadcrumbs(breadcrumbsRef.current);
    handleLoadEvents(dayjs().startOf("week"), dayjs().endOf("week"));
    handleLoadGroups();
  }, [localStorage.getItem("client")]);
  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  );
  const handleLoadGroups = async () => {
    try {
      const groups = await groupService.getAll();
      setGroups(groups);
    } catch (error) {
      console.log(error);
    }
  };
  const handleLoadEvents = async (start: Dayjs, end: Dayjs) => {
    try {
      setLoading(true);
      const campaigns = await service.getCampaignsForDateRange(
        null,
        start.toISOString(),
        end.toISOString()
      );
      setEvents(
        campaigns
          .map((e) =>
            e.slots.map((s) => ({
              ...s,
              campaignName: e.name,
              campaignCode: e.code,
              deviceGroups: e.deviceGroups,
              status: e.status,
              campaignId: e._id,
            }))
          )
          .flat()
          .map((e) => ({
            ...e,
            start: dayjs(e.start).toDate(),
            end: dayjs(e.end).toDate(),
          }))
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const cProps = {
    ...calendarProps,
    components: { ...calendarProps.components, eventWrapper: EventWrapper },
  };
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        onChange={setSelectedTab}
        items={[
          {
            label: `Global Dashboard`,
            key: "1",
          },
          {
            label: `Group Dashboard`,
            key: "2",
          },
        ]}
      />
      <div className="">
        {selectedTab === "1" && <GlobalDashboard groups={groups} />}
        {selectedTab === "2" && <GroupWiseDashboard groups={groups} />}
      </div>
    </div>
  );
}

export default Dashboard;
