import { getRequest, postRequest, putRequest } from "./config/request";
const SERVICE_URL = "/campaign";
export const create = (payload) => postRequest(`${SERVICE_URL}`, payload);
export const createDefault = (payload) =>
  postRequest(`${SERVICE_URL}/default`, payload);
export const update = (payload) => putRequest(`${SERVICE_URL}`, payload);
export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);
export const unarchive = (id: string) =>
  putRequest(`${SERVICE_URL}/unarchive/${id}`);
export const getAll = () => getRequest(`${SERVICE_URL}/all`);
export const get = (id) => getRequest(`${SERVICE_URL}/${id}`);
export const getDefault = () => getRequest(`${SERVICE_URL}/default`);
export const getCurrentCampaign = (macAddress: string) =>
  getRequest(`${SERVICE_URL}/current?macaddress=${macAddress}`);
export const checkCode = (name: string, id: string) =>
  getRequest(
    `${SERVICE_URL}/check-code?name=${encodeURIComponent(
      name
    )}&id=${encodeURIComponent(id)}`
  );
export const getCampaignsForGroups = (groups: string[]) =>
  getRequest(`${SERVICE_URL}/groups?groups=${groups.join(",")}`);
export const getCampaignsForDateRange = (
  deviceGroups: null | string[],
  start: string,
  end: string
) =>
  getRequest(
    `${SERVICE_URL}/date-range?start=${start}&end=${end}&deviceGroups=${
      deviceGroups?.length > 0 ? deviceGroups.join(",") : null
    }`
  );
export const updateStatus = (id: string, status: string) =>
  putRequest(`${SERVICE_URL}/status`, { _id: id, status });
export const archive = (id: string) =>
  putRequest(`${SERVICE_URL}/archive/${id}`);
export const getUniqueTags = () => getRequest(`${SERVICE_URL}/tags`);

export default {
  create,
  update,
  paginate,
  get,
  getAll,
  checkCode,
  getCampaignsForGroups,
  createDefault,
  getDefault,
  getCurrentCampaign,
  getCampaignsForDateRange,
  updateStatus,
  archive,
  unarchive,
  getUniqueTags,
};
