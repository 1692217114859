import { Form, Input, Button, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Store } from "../store";
import * as authActions from "../store/authSlice";
import qs from "qs";
import BackgroundImage from "../assets/background.png";
import LogoImage from "../assets/logo.png";
import X from "@components/Vectors/X";
import Facebook from "@components/Vectors/Facebook";
import Instagram from "@components/Vectors/Instagram";
export default function Login(props: any) {
  const navigate = useNavigate();
  const authState = useSelector((state: Store) => state.auth);
  const dispatch = useDispatch();
  const [loginForm] = Form.useForm();
  const [navigateTo, setNavigateTo] = useState<string>("/");
  useEffect(() => {
    let parsed = qs.parse(window.location.search);
    parsed = Object.keys(parsed)
      .map((key) => key.replace("?", ""))
      .reduce((acc, key) => {
        acc[key] = parsed[key] || parsed[`?${key}`];
        return acc;
      }, {});
    setNavigateTo((parsed?.redirect as string) || "/");
  }, []);
  const handleContinue = async (values: any) => {
    try {
      (
        dispatch(
          authActions.loginAsync({
            username: values.username,
            password: values.password,
          })
        ) as any
      ).then((response) => {
        if (!response.error) {
          navigate(navigateTo);
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <div className="flex w-full h-screen bg-white">
      <div className="w-full md:w-1/2 flex flex-col justify-center px-16">
        <div
          className="flex flex-col justify-between"
          style={{ height: "65vh" }}
        >
          <div>
            <img src={LogoImage} alt="logo" style={{ height: "75px" }} />
          </div>
          <div style={{ lineHeight: "45px" }}>
            <div className="font-light" style={{ fontSize: "48px" }}>
              Hello,
            </div>
            <div
              style={{ fontSize: "64px", color: "#780291" }}
              className="font-semibold"
            >
              welcome!
            </div>
          </div>
          <div className="mt-4">
            <Form form={loginForm} onFinish={handleContinue} className="">
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
                noStyle
              >
                <Input
                  size="large"
                  placeholder="Username"
                  style={{
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                    position: "relative",
                    bottom: "-1px",
                  }}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
                noStyle
              >
                <Input.Password
                  size="large"
                  placeholder="Password"
                  style={{
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                  }}
                />
              </Form.Item>
              <Form.Item noStyle>
                <div className="flex justify-between mt-2">
                  <div className="flex gap-x-2">
                    <Checkbox>Remember me</Checkbox>
                  </div>
                  <div>Forgot password?</div>
                </div>
              </Form.Item>
              <Form.Item className="mt-6">
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="px-8"
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="flex gap-x-8">
            <div className="font-bold text-gray-400 uppercase">FOLLOW</div>
            <div className="flex gap-x-4">
              <X /> <Facebook /> <Instagram />
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/2 hidden md:inline ">
        <img
          src={BackgroundImage}
          alt="background"
          className="w-full h-screen object-cover"
        />
      </div>
    </div>
  );
}
