import { useEffect, useState } from "react";
import UploadAdapter from "@utils/upload";
import { Upload } from "antd";
export default function UploadComponent(props: any) {
  const [fileList, setFileList] = useState([]);
  const [progress, setProgress] = useState<any>();
  const [uploading, setUploading] = useState(false);
  const [fileUrl, setFileUrl] = useState<any>();
  const onPreview = async (file) => {
    let src = file.url || file.thumbUrl || file.asset.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  useEffect(() => {
    if (fileList.length === 0 && props.fileList) {
      // setFileList([{ thumbUrl: props.flieList }]);
      setFileUrl(props.fileList.url);
    }
  }, [props.fileList]);
  const getVideoDimensions = async (file: File) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.onloadedmetadata = function () {
        resolve({
          width: this.videoWidth,
          height: this.videoHeight,
          duration: this.duration,
        });
      } as any;
      video.src = URL.createObjectURL(file);
    });
  };
  const getImageDimensions = async (file: File) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = function () {
        resolve({ width: this.width, height: this.height });
      } as any;
      img.src = URL.createObjectURL(file);
    });
  };
  return (
    <Upload
      accept="image/png, image/jpeg, image/gif, video/x-msvideo, video/mp4, video/mpeg, video/ogg, video/webm"
      listType={!props.children ? "picture-card" : undefined}
      onChange={({ fileList }) => {
        setFileList(fileList?.length > 0 ? [fileList?.[0]] : []);
      }}
      defaultFileList={props.fileList}
      beforeUpload={async (file) => {
        let dimension: any = {};
        if (file.type.startsWith("video/")) {
          dimension = await getVideoDimensions(file);
        }
        if (file.type.startsWith("image/")) {
          dimension = await getImageDimensions(file);
        }
        const uploadInstance = new UploadAdapter(
          setProgress,
          file.type.includes("video")
            ? process.env.REACT_APP_MC_PRESIGNED_URL_ENDPOINT
            : process.env.REACT_APP_PRESIGNED_URL_ENDPOINT,
          dimension
        );
        console.log(file);
        setUploading(true);
        uploadInstance
          .upload(file)
          .then((response: any) => {
            props.onChange({
              url: response.default,
              type: file.type,
              dimension,
              name: file.name,
              size: file.size,
            });
            setFileUrl(undefined);
            setUploading(false);
          })
          .catch(console.log);

        return false;
      }}
      fileList={fileList.map((file) => {
        return {
          ...file,
          thumbUrl: fileUrl,
          percent: progress ? (progress.loaded * 100) / progress?.total : 0,
          status: uploading ? "uploading" : undefined,
        };
      })}
      onPreview={onPreview}
    >
      {fileUrl && !uploading ? (
        <img
          src={fileUrl}
          alt="avatar"
          className="p-2 overflow-hidden h-full"
        />
      ) : fileList.length !== 0 ? undefined : (
        "+ Upload"
      )}
    </Upload>
  );
}
