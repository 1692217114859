import { useEffect, useState, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Input,
  Select,
  Table,
  Space,
  Popover,
  List,
  Checkbox,
  Form,
  Modal,
  Divider,
  Tag,
  Tooltip,
  Popconfirm,
  message,
  Dropdown,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
  ExportOutlined,
  LoadingOutlined,
  SyncOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { MdOutlineArchive, MdOutlineUnarchive } from "react-icons/md";

import { debounce } from "throttle-debounce";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TITLE, BASE_PATH, StatusBadge } from ".";
import service from "@services/campaign";
import deviceGroupService from "@services/deviceGroup";
import useSearchParamsTableState from "hooks/useSearchParamsTableState";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import { TbColumns3 } from "react-icons/tb";
import useSelectedColumnsState from "@hooks/useSelectedColumnsState";
import { filter } from "lodash";

const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const rowClassNames = {
  DRAFT: "draft-row",
  PUBLISHED: "published-row",
  ACTIVE: "active-row",
  COMPLETED: "completed-row",
  CANCELLED: "cancelled-row",
};

const MASTER_KEYS = [{ value: "deviceGroups", service: deviceGroupService }];
const ADDITIONAL_COLUMNS = [
  {
    title: "Active",
    dataIndex: "isActive",
    key: "isActive",
    render: (text) => (text ? "Yes" : "No"),
  },
  { key: "remote", title: "Remote", dataIndex: "remote" },
  { key: "osVersion", title: "OS Version", dataIndex: "osVersion" },
  { key: "platformName", title: "Platform Name", dataIndex: "platformName" },

  {
    key: "launcherVersion",
    title: "Launcher Version",
    dataIndex: "launcherVersion",
  },
  { key: "contentType", title: "Content Type", dataIndex: "contentType" },
  { key: "stage", title: "Stage", dataIndex: "stage" },
  { key: "flag", title: "Flag", dataIndex: "flag" },
  {
    key: "recordVersion",
    title: "Record Version",
    dataIndex: "recordVersion",
  },
  {
    title: "Updated",
    dataIndex: "updatedAt",
    key: "updatedAt",

    render: (text, record, index) => dayjs(text).format("DD/MM/YYYY"),
  },
];

export default function Entities(props: any) {
  const breadcrumbsRef = useRef([{ label: TITLE[1], url: BASE_PATH }]);
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({
      onFetch: service.paginate,
      defaultFilters: { isArchived: [false] },
    });
  const navigate = useNavigate();
  const setBreadcrumbs = useBreadcrumbs();
  const [resetPasswordVisible, setResetPasswordVisible] = useState("");
  const [toggleLoading, setToggleLoading] = useState(false);
  const [toggleKey, setToggleKey] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [videoProcessingStatus, setVideoProcessingStatus] = useState<any>({});
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [tags, setTags] = useState<any>([]);

  const [videoProcessingStatusLoading, setVideoProcessingStatusLoading] =
    useState(false);
  const { selectedColumns, setSelectedColumns } = useSelectedColumnsState({
    breadcrumbs: breadcrumbsRef.current,
    availableColumns: ADDITIONAL_COLUMNS,
    defaultSelected: [],
  });

  useEffect(() => {
    loadMastersDebounced();
    handleLoadTags();
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const [masters, setMasters] = useState<any>({});

  const getColumnsFilters = useCallback(
    (key: string) => {
      return masters[key]
        ? masters[key].map((entity) => ({
            text: entity.name,
            value: entity._id,
          }))
        : [];
    },
    [masters]
  );
  const loadMasters = useCallback(() => {
    const promises: any[] = [];
    for (let masterKey of MASTER_KEYS) {
      promises.push(
        masterKey.service
          .getAll()
          .then((p) => ({ key: masterKey.value, data: p }))
      );
    }
    Promise.all(promises).then((payload) => {
      const output: any = {};
      payload.forEach((m) => (output[m.key] = m.data));
      setMasters(output);
    });
  }, []);
  const handleLoadTags = useCallback(async () => {
    try {
      const tags = await service.getUniqueTags();
      setTags(tags.map((tag) => tag._id));
    } catch (error) {
      message.error("Failed to load tags");
    }
  }, []);

  const loadMastersDebounced = useCallback(debounce(300, loadMasters), []);
  const handleArchive = async (id: string) => {
    try {
      setArchiveLoading(true);
      await service.archive(id);
      message.success("Archived successfully");
      onRefresh();
    } catch (error) {
      message.error("Failed to archive");
    } finally {
      setArchiveLoading(false);
    }
  };
  const handleUnarchive = async (id: string) => {
    try {
      setArchiveLoading(true);
      await service.unarchive(id);
      message.success("Un-Archival successful");
      onRefresh();
    } catch (error) {
      message.error("Failed to un-archive");
    } finally {
      setArchiveLoading(false);
    }
  };
  const columns: any[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (name, record) => (
        <div className="flex w-full justify-between">
          {name} {record.isArchived && <Tag color="gray">ARCHIVED</Tag>}{" "}
          {record.isEmergency && (
            <Tooltip title="Quick Play">
              <Tag color="volcano">Q</Tag>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Device Groups",
      key: "groups",
      dataIndex: "groups",
      filters:
        masters.deviceGroups?.map((group: any) => ({
          text: group.name,
          value: group._id,
        })) || [],
      render: (groups) => groups.map((group: any) => group.name).join(", "),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      filters:
        ["DRAFT", "PUBLISHED", "ACTIVE", "COMPLETED", "CANCELLED"].map(
          (status) => ({
            text: <StatusBadge status={status} />,
            value: status,
          })
        ) || [],
      render: (status) => <StatusBadge status={status} />,
    },
    {
      title: "Start & End Date",
      key: "startEndDate",
      dataIndex: "startEndDate",
      render: (text, record, index) => {
        return (
          <Space>
            <Text>
              {dayjs(record.start).format("DD/MM/YYYY")} (
              {dayjs(record.start).format("hh:mm A")})
            </Text>
            -
            <Text>
              {dayjs(record.end).format("DD/MM/YYYY")} (
              {dayjs(record.end).format("hh:mm A")})
            </Text>
          </Space>
        );
      },
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      filters: tags.map((tag) => ({ text: tag, value: tag })),
      render: (tags) => {
        return tags ? (
          <Space>
            {tags.map((tag: any) => (
              <Tag key={tag} color="default">
                {tag}
              </Tag>
            ))}
          </Space>
        ) : (
          <></>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      align: "right",
      render: (text, record, index) => (
        <Space>
          {record.isArchived ? (
            <Tooltip title="Unarchive">
              <Popconfirm
                title="Are you sure you want to un-archive this?"
                onConfirm={() => handleUnarchive(record._id)}
                okText="Yes"
                cancelText="No"
                placement="left"
              >
                <Button
                  icon={<MdOutlineUnarchive className="text-lg" />}
                  type="link"
                  size="small"
                />
              </Popconfirm>
            </Tooltip>
          ) : (
            <Tooltip title="Archive">
              <Popconfirm
                title="Are you sure you want to archive this?"
                onConfirm={() => handleArchive(record._id)}
                okText="Yes"
                cancelText="No"
                placement="left"
              >
                <Button
                  icon={<MdOutlineArchive className="text-lg" />}
                  type="link"
                  size="small"
                  disabled={["ACTIVE", "PUBLISHED"].includes(record.status)}
                />
              </Popconfirm>
            </Tooltip>
          )}
          {["DRAFT", "CANCELLED"].includes(record.status) && (
            <Button
              icon={<EditOutlined />}
              onClick={() => handleForm(record._id)}
              type="link"
              size="small"
            />
          )}
          <Button
            icon={<ArrowRightOutlined />}
            onClick={() => handleView(record._id)}
            type="link"
            size="small"
          />
        </Space>
      ),
    },
    ...(selectedColumns as any[]),
  ];

  const handleForm = (entityId?: string) => {
    if (entityId) {
      navigate(`/fs${BASE_PATH}/edit?entity=${entityId}`);
    } else {
      navigate(`/fs${BASE_PATH}/new`);
    }
  };
  const handleView = (entityId?: string) => {
    navigate(`${BASE_PATH}/${entityId}`);
  };
  const handleBulk = () => {
    navigate(`${BASE_PATH}/bulk`);
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Title level={4}>All {TITLE[1]}</Title>
        </Col>
        <Col></Col>
        <Col>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => handleForm()}
            >
              New {TITLE[0]}
            </Button>
          </Space>
        </Col>
      </Row>
      <div className="mt-4 bg-white rounded-lg rounded-lg">
        <div className="flex justify-between p-4">
          <Search
            placeholder={`Search ${TITLE[1]}`}
            enterButton={<SearchOutlined />}
            className="w-max"
            size="middle"
            onChange={(event) => {
              setInput(event.target.value);
            }}
            value={input}
          />
          {data?.queryCount !== data?.totalCount && (
            <Text className="flex items-center">
              Showing {data.queryCount} of {data?.totalCount} entries
              <Button
                type="link"
                onClick={() => {
                  setInput("");
                }}
              >
                Clear Search
              </Button>
            </Text>
          )}
          <div className="flex gap-x-2 items-baseline">
            <Dropdown
              overlay={
                <div className="bg-white rounded-lg p-3">
                  <Checkbox.Group
                    onChange={(values) => {
                      setTableState({
                        ...tableState,
                        filters: {
                          ...tableState.filters,
                          isArchived: values.includes("includeArchived")
                            ? [true, false]
                            : [false],
                        },
                      });
                    }}
                    value={
                      tableState?.filters?.isArchived?.includes("true")
                        ? ["includeArchived"]
                        : []
                    }
                  >
                    <Checkbox value="includeArchived">
                      Include Archived Items
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              }
              trigger={["click"]}
            >
              <Button icon={<FilterOutlined />} />
            </Dropdown>
            <Button icon={<SyncOutlined />} onClick={onRefresh} />
          </div>
        </div>

        <Row className="mt-2">
          <Col span={24}>
            <Table
              loading={loading}
              dataSource={data?.list}
              columns={columns}
              pagination={{
                ...(tableState?.pagination || {}),
                total: data?.queryCount,
              }}
              // rowClassName={(record) => rowClassNames[record.status]}
              bordered
              className="overflow-table"
              onChange={(pagination, filters, sorter) => {
                delete (sorter as any).column;
                setTableState({ pagination, filters, sorter });
              }}
              rowKey={(record) => record._id}
              size="small"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
