import useCalendarComponents from "@hooks/useCalendarComponents";
import { Button, Checkbox, Form, Modal, Popconfirm, Radio } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { nanoid } from "nanoid";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Calendar,
  Views,
  DateLocalizer,
  dayjsLocalizer,
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import EventForm, { COLOR_OPTIONS } from "./EventForm";
import {
  CalendarOutlined,
  DeleteOutlined,
  RetweetOutlined,
  EnterOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import DatePicker from "./Core/DatePicker";
const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = dayjsLocalizer(dayjs);
const { TimePicker } = DatePicker;
function EventCard(props: any) {
  return (
    <div className="flex flex-col justify-between">
      <div>
        <div className="mt-4 ml-5">
          <div className="flex items-center gap-x-2">
            <CalendarOutlined />
            {props.data.repeat === "single" ? (
              <div>
                {dayjs(props.data.start).format("DD MMM, YY hh:mmA")} -{" "}
                {dayjs(props.data.end).format(" hh:mmA")}
              </div>
            ) : (
              <div>
                {dayjs(props.data.start).format("hh:mmA")} -{" "}
                {dayjs(props.data.end).format("hh:mmA")} (Repeats{" "}
                {props.data.repeat})
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-x-4 justify-end mt-8">
        {!props.data.isExisting && (
          <Popconfirm
            title="Are you sure to delete this task?"
            onConfirm={() => props.onDelete(props.data)}
            okText="Yes"
            cancelText="No"
          >
            <a href="#" className="flex items-center gap-x-2">
              <DeleteOutlined /> Delete{" "}
              {props.data.repeat !== "single" && "Series"}
            </a>
          </Popconfirm>
        )}
      </div>
    </div>
  );
}

function SchedularSelector(props: any) {
  return (
    <div className="flex justify-center gap-x-2">
      <div
        className={`border p-4 rounded w-1/3 flex justify-center flex-col items-center gap-y-2 cursor-pointer ${
          props.value === "recurring" ? "bg-blue-500 text-white" : ""
        }`}
        onClick={() => props.onChange("recurring")}
      >
        <RetweetOutlined className="text-lg" />
        Recurring
      </div>
      <div
        className={`border p-4 rounded w-1/3 flex justify-center flex-col items-center gap-y-2 cursor-pointer ${
          props.value === "one-time" ? "bg-blue-500 text-white" : ""
        }`}
        onClick={() => props.onChange("one-time")}
      >
        <EnterOutlined
          style={{ transform: "rotate(180deg)" }}
          className="text-lg"
        />
        One time only
      </div>
    </div>
  );
}

function SchedularDuration(props: any) {
  return (
    <div className="flex gap-x-2 items-center">
      <TimePicker
        placeholder="Select Start At"
        format="hh:mmA"
        onSelect={(time) => props.onChange([time, props.value?.[1] || null])}
        minuteStep={30}
        value={props.value && props.value[0]}
      />
      <TimePicker
        placeholder="Select End At"
        format="hh:mmA"
        onSelect={(time) => props.onChange([props.value?.[0] || null, time])}
        minuteStep={30}
        value={props.value && props.value[1]}
      />
    </div>
  );
}

const SimpleInsert = (props) => {
  const [form] = Form.useForm();
  const type = Form.useWatch("type", form);
  const duration: [Dayjs, Dayjs] = Form.useWatch("duration", form);
  const start = Form.useWatch("start", form);
  const end = Form.useWatch("end", form);
  const doesntEnd = Form.useWatch("doesntEnd", form);
  const weekdays = Form.useWatch("weekdays", form);
  const [slots, setSlots] = useState([]);
  const [isUnending, setIsUnending] = useState(false);
  const [overlappingError, setOverlappingError] = useState(false);
  useEffect(() => {
    handleSetSlots();
  }, [duration, start, end, doesntEnd, weekdays, type]);

  useEffect(() => {
    console.log(props.existingEvents);
  }, [props.existingEvents]);
  const handleSetSlots = async () => {
    if (!start || !duration) {
      return;
    }
    console.log(props.existingEvents);

    const slots: {
      start: Date;
      end: Date;
      id: string;
      repeatId: string;
      repeat: string;
      title: string;
      allDay: boolean;
    }[] = [...(props.existingEvents || [])];
    if (type === "one-time") {
      slots.push({
        start: dayjs(start)
          .set("hour", duration[0].get("hour"))
          .set("minute", duration[0].get("minute"))
          .set("second", 0)
          .set("millisecond", 0)
          .toDate(),
        end: dayjs(start)
          .set("hour", duration[1].get("hour"))
          .set("minute", duration[1].get("minute"))
          .set("second", 0)
          .set("millisecond", 0)
          .toDate(),
        id: nanoid(),
        repeatId: undefined,
        repeat: "single",
        title: "Single",
        allDay: false,
      });
    } else {
      if (end) {
        const repeatId = nanoid();
        for (let i = 0; i < end.diff(start, "days"); i++) {
          const date = dayjs(start).add(i, "days").set("second", 0);
          if (weekdays?.includes(date.day().toString())) {
            slots.push({
              start: date
                .set("hour", duration[0].get("hour"))
                .set("minute", duration[0].get("minute"))
                .set("second", 0)
                .set("millisecond", 0)
                .toDate(),
              end: date
                .set("hour", duration[1].get("hour"))
                .set("minute", duration[1].get("minute"))
                .set("second", 0)
                .set("millisecond", 0)
                .toDate(),
              id: nanoid(),
              repeatId,
              repeat: "weekly",
              title: "Weekly",
              allDay: false,
            });
          }
        }
      } else {
        const repeatId = nanoid();
        for (let i = 0; i < 90; i++) {
          const date = dayjs(start).add(i, "days").set("second", 0);
          if (weekdays?.includes(date.day().toString())) {
            slots.push({
              start: date
                .set("hour", duration[0].get("hour"))
                .set("minute", duration[0].get("minute"))
                .set("second", 0)
                .set("millisecond", 0)
                .toDate(),
              end: date
                .set("hour", duration[1].get("hour"))
                .set("minute", duration[1].get("minute"))
                .set("second", 0)
                .set("millisecond", 0)
                .toDate(),
              id: nanoid(),
              repeatId,
              repeat: "daily",
              title: "Daily",
              allDay: false,
            });
          }
        }
      }
    }
    try {
      await validateSlotsWithExistingSlots(props.existingEvents, slots);

      setOverlappingError(false);
      setSlots(slots);
    } catch (error) {
      setOverlappingError(true);
      console.log(error);
    }
  };
  const validateSlotsWithExistingSlots = async (existingEvents, slots) => {
    try {
      if (slots) {
        const existingSlots = existingEvents.map((e) => ({
          start: e.start,
          end: e.end,
        }));

        const newSlots = slots.map((e) => ({
          start: e.start,
          end: e.end,
        }));

        const isOverlapping = props.isEventOverlapping(newSlots, existingSlots);

        if (isOverlapping) {
          return Promise.reject("Slot is overlapping with existing slots");
        }
      }
      return Promise.resolve();
    } catch (error) {}
  };

  return (
    <div className="">
      <Form
        form={form}
        onFinish={() => {
          const color =
            COLOR_OPTIONS[Math.floor(Math.random() * COLOR_OPTIONS.length)];
          const slotsToBeInserted = slots.map((slot) => ({
            ...slot,
            color,
          }));
          if (slotsToBeInserted.length > 0) {
            props.onSubmit(slotsToBeInserted);
            props.onCancel();
          }
        }}
        initialValues={{ type: "recurring" }}
      >
        <Form.Item name="type">
          <SchedularSelector />
        </Form.Item>

        <Form.Item
          label="Time Duration"
          name="duration"
          rules={[{ required: true, message: "Time duration is required" }]}
        >
          <SchedularDuration />
        </Form.Item>

        {type !== "one-time" ? (
          <>
            <Form.Item
              name="start"
              label="Start"
              preserve={false}
              rules={[{ required: true, message: "Start Date is required" }]}
            >
              <DatePicker format="DD MMM, YY" />
            </Form.Item>
            <Form.Item
              name="weekdays"
              label={
                <div>
                  Recur On{" "}
                  {!weekdays || weekdays.length !== 7 ? (
                    <span
                      className="text-xs text-blue-500 cursor-pointer"
                      onClick={() => {
                        form.setFieldsValue({
                          weekdays: ["0", "1", "2", "3", "4", "5", "6"],
                        });
                      }}
                    >
                      (Select all)
                    </span>
                  ) : (
                    <span
                      className="text-xs text-blue-500 cursor-pointer"
                      onClick={() => {
                        form.setFieldsValue({
                          weekdays: [],
                        });
                      }}
                    >
                      (Unselect all)
                    </span>
                  )}
                </div>
              }
              rules={[
                { required: true, message: "Select atleast one weekday" },
              ]}
              preserve={false}
            >
              <Checkbox.Group>
                <div className="flex gap-x-4 items-center">
                  <Checkbox value="0">Sun</Checkbox>
                  <Checkbox value="1">Mon</Checkbox>
                  <Checkbox value="2">Tue</Checkbox>
                  <Checkbox value="3">Wed</Checkbox>
                  <Checkbox value="4">Thu</Checkbox>
                  <Checkbox value="5">Fri</Checkbox>
                  <Checkbox value="6">Sat</Checkbox>
                </div>
              </Checkbox.Group>
            </Form.Item>
            <Radio.Group
              defaultValue={false}
              onChange={(e) => setIsUnending(e.target.checked)}
            >
              <Form.Item
                label="End"
                preserve={false}
                name="doesntEnd"
                valuePropName="checked"
              >
                <Radio value={true}>Never - keep as on-going campaign</Radio>
              </Form.Item>
              <Radio value={false}>
                <div className="flex gap-x-4 items-baseline">
                  <div>On a specific date</div>
                  <Form.Item
                    name="end"
                    className=""
                    preserve={false}
                    rules={[
                      {
                        required: !isUnending,
                        message: "Please select a date",
                      },
                    ]}
                  >
                    <DatePicker format="DD MMM, YY" className="ml-2" />
                  </Form.Item>
                </div>
              </Radio>
            </Radio.Group>
          </>
        ) : (
          <Form.Item name="start" label="Date" preserve={false}>
            <DatePicker format="DD MMM, YY" />
          </Form.Item>
        )}
        <Form.Item noStyle>
          <div className="flex justify-between">
            <div className="flex justify-start gap-x-2">
              <Button
                htmlType="submit"
                type="default"
                onClick={() => props.onCancel()}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                disabled={overlappingError}
              >
                Add
              </Button>
            </div>
            {overlappingError && (
              <div className="text-red-500">
                The slots being inserted collide with existing events.
              </div>
            )}
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default function DnDScheduler(props: any) {
  const { isEmergency } = props;
  const [events, setEvents] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [eventVisible, setEventVisible] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<any>();
  const calendarProps = useCalendarComponents();

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const isExisting = event.isExisting;
      if (isExisting) {
        return;
      }
      if (!isEventOverlapping(event, events)) {
        setCurrentEvent({ start: dayjs(start), end: dayjs(end) });
        setFormVisible(true);
      } else {
        return alert(
          "Cannot create an event that overlaps with an existing event."
        );
      }
      const isRepeating = event.repeatId;
      if (isRepeating) {
        const movedEventIndex = events.findIndex((ev) => ev.id === event.id);
        const originalEvent = events[movedEventIndex];
        const timeDelta = dayjs(start).diff(
          dayjs(originalEvent.start),
          "minutes"
        );

        setEvents((prev) =>
          prev.map((ev) =>
            ev.repeatId !== event.repeatId
              ? ev
              : {
                  ...ev,
                  start: dayjs(ev.start).add(timeDelta, "minutes").toDate(),
                  end: dayjs(ev.end).add(timeDelta, "minutes").toDate(),
                }
          )
        );
      } else {
        setEvents((prev) => {
          const existing = prev.find((ev) => ev.id === event.id) ?? {};
          const filtered = prev.filter((ev) => ev.id !== event.id);
          return [...filtered, { ...existing, start, end }];
        });
      }
    },
    [events]
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      const isExisting = event.isExisting;
      if (isExisting) {
        return;
      }
      if (!isEventOverlapping(event, events)) {
        setCurrentEvent({ start: dayjs(start), end: dayjs(end) });
        setFormVisible(true);
      } else {
        return alert(
          "Cannot create an event that overlaps with an existing event."
        );
      }
      const isRepeating = event.repeatId;
      if (isRepeating) {
        setEvents((prev) =>
          prev.map((ev) =>
            ev.repeatId !== event.repeatId
              ? ev
              : {
                  ...ev,
                  start: dayjs(ev.start)
                    .set("hour", dayjs(start).get("hour"))
                    .toDate(),
                  end: dayjs(ev.end)
                    .set("hour", dayjs(end).get("hour"))
                    .toDate(),
                }
          )
        );
      } else {
        setEvents((prev) => {
          const existing = prev.find((ev) => ev.id === event.id) ?? {};
          const filtered = prev.filter((ev) => ev.id !== event.id);
          return [...filtered, { ...existing, start, end }];
        });
      }
    },
    [events]
  );

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  );
  const isEventOverlapping = (
    newEvent: any,
    existingEvents: any[]
  ): boolean => {
    return existingEvents.some((event) => {
      return (
        (newEvent.start < event.end && newEvent.start >= event.start) ||
        (newEvent.end > event.start && newEvent.end <= event.end) ||
        (newEvent.start <= event.start && newEvent.end >= event.end)
      );
    });
  };
  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const newEvent = {
        start: dayjs(start).toDate(),
        end: dayjs(end).toDate(),
        id: nanoid(),
        style: { backgroundColor: "black" },
      };
      if (!isEventOverlapping(newEvent, events)) {
        setCurrentEvent({ start: dayjs(start), end: dayjs(end) });
        setFormVisible(true);
      } else {
        alert("Cannot create an event that overlaps with an existing event.");
      }
    },
    [events]
  );
  const handleSelectEvent = useCallback((event) => {
    setCurrentEvent({
      ...event,
      start: dayjs(event.start),
      end: dayjs(event.end),
    });
    setEventVisible(true);
  }, []);
  const handleDelete = (event) => {
    if (event.repeatId) {
      setEvents(events.filter((e) => e.repeatId !== event.repeatId));
    }
    if (!event.repeatId) {
      setEvents(events.filter((e) => e.id !== event.id));
    }
  };
  const handleEventFormSubmit = (values) => {
    if (values.repeat === "daily") {
      const DAYS = 90;
      const dailyEvents = [];
      for (let i = 0; i < DAYS; i++) {
        dailyEvents.push({
          ...values,
          id: nanoid(),
          start: dayjs(values.start).add(i, "days").toDate(),
          end: dayjs(values.end).add(i, "days").toDate(),
          resizable: true,
        });
      }
      return setEvents((prev) => [...prev, ...dailyEvents]);
    }
    if (values.repeat === "weekly") {
      const WEEKS = 52;
      const weeklyEvents = [];
      for (let i = 0; i < WEEKS; i++) {
        weeklyEvents.push({
          ...values,
          id: nanoid(),
          start: dayjs(values.start).add(i, "weeks").toDate(),
          end: dayjs(values.end).add(i, "weeks").toDate(),
        });
      }
      return setEvents((prev) => [...prev, ...weeklyEvents]);
    }
    if (values.repeat === "monthly") {
      const MONTHS = 12;
      const monthlyEvents = [];
      for (let i = 0; i < MONTHS; i++) {
        monthlyEvents.push({
          ...values,
          id: nanoid(),
          start: dayjs(values.start).add(i, "months").toDate(),
          end: dayjs(values.end).add(i, "months").toDate(),
        });
      }
      return setEvents((prev) => [...prev, ...monthlyEvents]);
    }
    setEvents((prev) => [
      ...prev,
      { ...values, id: nanoid(), resizable: true },
    ]);
    setFormVisible(false);
  };
  useEffect(() => {
    props.onChange(events);
    setFormVisible(false);
    setEventVisible(false);
  }, [events]);
  useEffect(() => {
    const existingEvents = props.existingCampaigns
      .map((e) =>
        e.slots.map((s) => ({
          ...s,
          campaignName: e.name,
          campaignCode: e.code,
          status: e.status,
        }))
      )
      .flat()
      .map((e) => ({
        ...e,
        start: dayjs(e.start).toDate(),
        end: dayjs(e.end).toDate(),
        isExisting: true,
        disabled: true,
      }));
    const addedExisting = events.filter((e) => !e.isExisting);

    setEvents([...existingEvents, ...addedExisting]);
  }, [props.existingCampaigns]);
  useEffect(() => {
    if (props.defaultValues?.length > 0) {
      setEvents(
        props.defaultValues.map((e) => ({
          ...e,
          start: dayjs(e.start).toDate(),
          end: dayjs(e.end).toDate(),
        }))
      );
    }
  }, [props.defaultValues]);
  return (
    <Fragment>
      <Modal
        open={props.simpleInsert}
        onCancel={() => props.onSimpleInsertCancel(false)}
        footer={null}
        title="Simple Insert"
        destroyOnClose
        className="w-1/2"
      >
        <SimpleInsert
          onSubmit={(slots) => {
            setEvents(slots);
          }}
          existingEvents={events}
          onCancel={() => props.onSimpleInsertCancel(false)}
          isEventOverlapping={isEventOverlapping}
        />
      </Modal>
      <Modal
        open={formVisible}
        onCancel={() => setFormVisible(false)}
        footer={null}
        title="Edit Event"
        destroyOnClose
      >
        <EventForm
          onUpdate={(values) => {}}
          onSubmit={handleEventFormSubmit}
          defaultValues={currentEvent || null}
        />
      </Modal>
      <Modal
        open={eventVisible}
        onCancel={() => setEventVisible(false)}
        footer={null}
        title="Event Details"
        destroyOnClose
      >
        <EventCard data={currentEvent} onDelete={handleDelete} />
      </Modal>
      <div>
        <DragAndDropCalendar
          {...calendarProps}
          localizer={localizer}
          defaultDate={defaultDate}
          defaultView={Views.DAY}
          events={events}
          onNavigate={() => setEvents(_.cloneDeep(events))}
          onEventDrop={moveEvent}
          onEventResize={resizeEvent}
          onSelectEvent={handleSelectEvent}
          onDoubleClickEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          scrollToTime={scrollToTime}
          resizable
          selectable
          style={{ height: "50vh" }}
        />
      </div>
    </Fragment>
  );
}
