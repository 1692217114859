import { useCallback } from "react";
import { message } from "antd";
import { toast, Bounce, ToastOptions, Slide } from "react-toastify";

const TOAST_CONFIG: ToastOptions<{}> = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Slide,
};
function useAPIWrapper() {
  const handleRequest = useCallback(
    async (requestFunction: () => Promise<any>) => {
      try {
        const response = await requestFunction();
        const { message: responseMessage, data } = response;
        if (!data) {
          throw new Error(responseMessage);
        }
        toast.success(responseMessage, TOAST_CONFIG);
        message.success(responseMessage);
        return data;
      } catch (error) {
        console.error(error);
        toast.error(error.message, TOAST_CONFIG);
        throw error;
      }
    },
    []
  );
  return handleRequest;
}

export default useAPIWrapper;
