import { createSlice } from "@reduxjs/toolkit";

export interface UIState {
  showSider: boolean;
  showClientTesting: boolean;
}
const INITIAL_STATE: UIState = {
  showSider: true,
  showClientTesting: false,
};
const slice = createSlice({
  name: "ui",
  initialState: INITIAL_STATE,
  reducers: {
    setShowSider: (state, action) => {
      state.showSider = action.payload;
    },
    setShowClientTesting: (state, action) => {
      state.showClientTesting = action.payload;
    },
  },
  extraReducers: (builder) => {},
});
export const { setShowSider, setShowClientTesting } = slice.actions;
export default slice.reducer;
