import UploadAdapter from "@utils/upload";
import { Button, Upload } from "antd";
import { useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
function UploadDirectory(props: any) {
  const [progress, setProgress] = useState<any>();
  const [uploading, setUploading] = useState<any>();
  const [fileUrl, setFileUrl] = useState<any>();
  const [fileUrls, setFileUrls] = useState<any>([]);
  const [fileList, setFileList] = useState([]);

  const getVideoDimensions = async (file: File) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.onloadedmetadata = function () {
        resolve({
          width: this.videoWidth,
          height: this.videoHeight,
          duration: this.duration,
        });
      } as any;
      video.src = URL.createObjectURL(file);
    });
  };
  const getImageDimensions = async (file: File) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = function () {
        resolve({ width: this.width, height: this.height });
      } as any;
      img.src = URL.createObjectURL(file);
    });
  };
  useEffect(() => {
    const payload = fileList
      .filter(
        (file) => file.type.includes("image") || file.type.includes("video")
      )
      .map((file) => ({
        ...file,
        ...fileUrls.find((fileUrl) => fileUrl.uid === file.uid),
      }));
    props.onChange(payload);
  }, [fileList, fileUrls]);
  useEffect(() => {
    props.onUploading?.(
      Object.keys(uploading || {}).reduce(
        (acc, key) => acc || uploading[key],
        false
      )
    );
  }, [uploading]);
  return (
    <Dragger
      className="w-full"
      fileList={fileList.map((file) => {
        return {
          ...file,
          percent: progress?.[file.uid]
            ? (progress[file.uid].loaded * 100) / progress?.[file.uid].total
            : 0,
          status: uploading?.[file.uid] ? "uploading" : undefined,
        };
      })}
      onChange={(info) => {
        setFileList(info.fileList);
      }}
      beforeUpload={async (file) => {
        // Ignore non image and non video files
        if (!(file.type.includes("image") || file.type.includes("video"))) {
          return false;
          //   return Upload.LIST_IGNORE;
        }

        let dimension: any = {};
        if (file.type.startsWith("video/")) {
          dimension = await getVideoDimensions(file);
        }
        if (file.type.startsWith("image/")) {
          dimension = await getImageDimensions(file);
        }
        const uploadInstance = new UploadAdapter(
          (event) =>
            setProgress(
              (progress) => ({ ...progress, [file.uid]: event } || {})
            ),
          file.type.includes("video")
            ? process.env.REACT_APP_MC_PRESIGNED_URL_ENDPOINT
            : process.env.REACT_APP_PRESIGNED_URL_ENDPOINT,
          dimension
        );

        setUploading((uploading) => ({ ...uploading, [file.uid]: true } || {}));
        uploadInstance
          .upload(file)
          .then((response: any) => {
            setFileUrls((fileUrls) => [
              ...fileUrls,
              {
                uid: file.uid,
                name: file.name,
                url: response.default,
                type: file.type,
                size: file.size,
                dimension,
              },
            ]);
            // , url: response.default, type: file.type, dimension
            setFileUrl(undefined);
            setUploading(
              (uploading) => ({ ...uploading, [file.uid]: false } || {})
            );
          })
          .catch(console.log);

        return false;
      }}
      directory
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag folder to this area to upload
      </p>
      <p className="ant-upload-hint">
        Support for a single or bulk upload. Only image and video files are
        allowed.
      </p>
    </Dragger>
  );
}

export default UploadDirectory;
