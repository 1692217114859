import io, { Socket } from "socket.io-client";
import { SOCKET_URL } from "./apiUrl";
import { debounce } from "throttle-debounce";
import * as telemetryActions from "../../store/telemetrySlice";
import * as proofOfPlayActions from "../../store/proofOfPlaySlice";

export let socket: Socket;

export const initialize = debounce(500, async () => {
  if (!socket) {
    const { store } = await import("../../store");
    socket = io(SOCKET_URL);

    socket.on("TELEMETRY", (data) => {
      console.log("TELEMETRY", data);
      store.dispatch(telemetryActions.addMetric(data));
    });
    socket.on("PROOF_OF_PLAY", (data) => {
      console.log("PROOF_OF_PLAY", data);

      store.dispatch(proofOfPlayActions.addMetric(data));
    });
  }
});

export const terminate = () => {
  if (socket) {
    socket.removeAllListeners();
    socket.close();
    socket = undefined;
  }
};

export const getSocket = () => {
  return socket;
};
