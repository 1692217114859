import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/asset";
export const create = (payload) => postRequest(`${SERVICE_URL}`, payload);
export const createMultiple = (payload) =>
  postRequest(`${SERVICE_URL}/multiple`, payload);
export const update = (payload) => putRequest(`${SERVICE_URL}`, payload);
export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);

export const getAll = () => getRequest(`${SERVICE_URL}/all`);
export const get = (id) => getRequest(`${SERVICE_URL}/${id}`);
export const getVideoProcessingStatus = (keys: string[]) =>
  postRequest(`${SERVICE_URL}/video-processing-status/`, { keys });
export const getUniqueMimeTypes = () => getRequest(`${SERVICE_URL}/mime-types`);
export const getByFolder = (id) => getRequest(`${SERVICE_URL}/folder/${id}`);
export const move = (payload) => putRequest(`${SERVICE_URL}/move`, payload);
export const remove = (id) => deleteRequest(`${SERVICE_URL}/${id}`);
export const getTotalUsage = () => getRequest(`${SERVICE_URL}/usage`);
export default {
  create,
  update,
  paginate,
  get,
  createMultiple,
  getVideoProcessingStatus,
  getAll,
  getUniqueMimeTypes,
  getByFolder,
  move,
  remove,
  getTotalUsage,
};
