import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/device";
const CLIENT_SERVICE_URL = "/client-device";

export const create = (payload) => postRequest(`${SERVICE_URL}`, payload);
export const update = (payload) => putRequest(`${SERVICE_URL}`, payload);
export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);
export const subscribeToEvent = (deviceId: string, eventName: string) =>
  postRequest(`${CLIENT_SERVICE_URL}/subscribe`, { deviceId, eventName });
export const activateDevice = (id: string) =>
  putRequest(`${SERVICE_URL}/activate/${id}`);
export const suspendDevice = (id: string) =>
  putRequest(`${SERVICE_URL}/suspend/${id}`);
export const pingDevice = (id: string, _id: string) =>
  getRequest(`${CLIENT_SERVICE_URL}/ping/${id}/${_id}`);
export const pingDevices = (payload: { id: string; _id: string }[]) =>
  postRequest(`${CLIENT_SERVICE_URL}/ping`, payload);
export const get = (id) => getRequest(`${SERVICE_URL}/${id}`);
export const getByDeviceId = (deviceId: string) =>
  getRequest(`${SERVICE_URL}/get-by-device-id/${deviceId}`);
export const getSavedDevice = (deviceId: string) =>
  getRequest(`${SERVICE_URL}/get-saved-device/${deviceId}`);
export const getDeviceConnectionStatus = (deviceId: string) =>
  getRequest(`${SERVICE_URL}/get-device-connnection-status/${deviceId}`);
export const getForClient = (id) => getRequest(`${CLIENT_SERVICE_URL}/${id}`);
export const getAll = () => getRequest(`${SERVICE_URL}/all`);
export const toggleActive = (id: string) =>
  getRequest(`${SERVICE_URL}/${id}/toggle-active`);
export const checkMacAddress = (name: string, id: string) =>
  getRequest(
    `${SERVICE_URL}/check-mac-address?name=${encodeURIComponent(
      name
    )}&id=${encodeURIComponent(id)}`
  );
export const processBulk = (payload: any) =>
  postRequest(`${SERVICE_URL}/bulk-process`, payload);
export const uploadBulk = (formData: FormData) =>
  postRequest(`${SERVICE_URL}/bulk`, formData);
export const exportToXLSX = () => postBlobRequest(`${SERVICE_URL}/export`);
export const getAllForClient = () => getRequest(`${CLIENT_SERVICE_URL}/all`);
export const getAllForDeviceGroup = (id: string) =>
  getRequest(`${CLIENT_SERVICE_URL}/group/${id}`);
export const getUnAssociated = (group: string) =>
  getRequest(`${CLIENT_SERVICE_URL}/unassociated?group=${group}`);
export const paginateForClient = (qs: string) =>
  getRequest(`${CLIENT_SERVICE_URL}?${qs}`);
export const getTelemetry = (deviceId: string) =>
  getRequest(`${CLIENT_SERVICE_URL}/telemetry/${deviceId}`);
export const getProofOfPlay = (deviceId: string) =>
  getRequest(`${CLIENT_SERVICE_URL}/proof-of-play/${deviceId}`);
export const getUniqueTags = () => getRequest(`${SERVICE_URL}/tags`);
export const getActiveTime = () =>
  getRequest(`${CLIENT_SERVICE_URL}/active-time`);
export const getActiveTimeForGroup = (groupId: string) =>
  getRequest(`${CLIENT_SERVICE_URL}/group-active-time/${groupId}`);
export default {
  create,
  update,
  paginate,
  get,
  getAll,
  toggleActive,
  checkMacAddress,
  processBulk,
  uploadBulk,
  exportToXLSX,
  paginateForClient,
  getAllForClient,
  getUnAssociated,
  getForClient,
  getByDeviceId,
  getSavedDevice,
  getDeviceConnectionStatus,
  activateDevice,
  suspendDevice,
  pingDevice,
  pingDevices,
  subscribeToEvent,
  getAllForDeviceGroup,
  getTelemetry,
  getProofOfPlay,
  getUniqueTags,
  getActiveTime,
  getActiveTimeForGroup,
};
