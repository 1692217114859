import { useCallback, useEffect, useRef, useState } from "react";
import {
  Form,
  Typography,
  Input,
  Select,
  Button,
  Space,
  Divider,
  Table,
  Tooltip,
  InputNumber,
  message,
  Modal,
  Row,
  Col,
  Radio,
  Checkbox,
  Badge,
} from "antd";
import { TITLE, BASE_PATH } from ".";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import querystring from "query-string";
import assetService from "@services/asset";
import service from "@services/composition";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import { IoTabletLandscape, IoTabletPortrait } from "react-icons/io5";
import { FaVideo, FaImage } from "react-icons/fa";
import { FaSquareCheck } from "react-icons/fa6";
import { IoSettingsSharp } from "react-icons/io5";
import {
  CalendarFilled,
  SearchOutlined,
  FilterOutlined,
  SyncOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  MenuOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import DatePicker from "@components/Core/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import * as compositionActions from "@store/compositionSlice";
import getThumbnailUrl from "@utils/getThumbnailUrl";
import { titleCase } from "title-case";
import { debounce } from "throttle-debounce";
import type { SortableContainerProps, SortEnd } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

export const columnRenderer: any = {
  processedUrls: (value, record) => {
    return (
      <img
        src={getThumbnailUrl(record.mimeType, value)}
        style={{ height: 50, width: "auto" }}
      />
    );
  },
  displayName: (value, record) => {
    const status = record.status;
    const orientation =
      record.dimension.width > record.dimension.height
        ? "landscape"
        : "portrait";
    return (
      <div className="flex flex-col gap-y-2">
        <div>{value}</div>
        <div className="flex gap-x-1">
          <Tooltip title={record.mimeType}>
            {record.mimeType.includes("image") ? (
              <FaImage className="text-gray-500" />
            ) : (
              <FaVideo className="text-gray-500" />
            )}
          </Tooltip>
          <Tooltip title={status}>
            {status === "Processing" ? (
              <IoSettingsSharp className="text-gray-500" />
            ) : (
              <FaSquareCheck className="text-gray-500" />
            )}
          </Tooltip>
          <Tooltip
            title={
              <div>
                {record.dimension.width} x {record.dimension.height}
              </div>
            }
          >
            <span
              style={{ fontSize: "8px", borderRadius: "2px" }}
              className="bg-gray-500 text-white font-bold px-1 flex items-center "
            >
              {record.dimension.width} x {record.dimension.height}
            </span>
          </Tooltip>
          {record.mimeType.includes("video") && (
            <Tooltip
              title={
                <div>
                  {record.dimension.duration
                    ? `${Math.round(record.dimension.duration)}s`
                    : ""}
                </div>
              }
            >
              <span
                style={{ fontSize: "8px", borderRadius: "2px" }}
                className="bg-gray-500 text-white font-bold px-1 flex items-center "
              >
                {record.dimension.duration
                  ? `${Math.round(record.dimension.duration)}s`
                  : ""}
              </span>
            </Tooltip>
          )}
          <Tooltip title={titleCase(orientation)}>
            <span
              style={{ fontSize: "8px", borderRadius: "2px" }}
              className="bg-gray-500 text-white font-bold px-1 flex items-center "
            >
              {titleCase(orientation).substring(0, 1)}
            </span>
          </Tooltip>
        </div>
      </div>
    );
  },
  createdAt: (value) => (
    <Text type="secondary">{dayjs(value).format("DD MMM, YYYY")}</Text>
  ),
};

const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
);
const SortableBody = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />
);

const FILTER_CATEGORIES = [
  {
    label: "Type",
    value: "type",
    selector: "checkbox",
    children: (props) => (
      <Checkbox.Group className="flex flex-col gap-y-3" {...props}>
        <Checkbox value="image" className="mx-0">
          Image
        </Checkbox>
        <Checkbox value="video" className="mx-0">
          Video
        </Checkbox>
      </Checkbox.Group>
    ),
  },
  {
    label: "Min. Resolution",
    value: "resolution",
    selector: "checkbox",
    children: (props) => (
      <Checkbox.Group className="flex flex-col gap-y-3" {...props}>
        <Checkbox value="480" className="mx-0">
          480p
        </Checkbox>
        <Checkbox value="720" className="mx-0">
          720p
        </Checkbox>
        <Checkbox value="1080" className="mx-0">
          1080p
        </Checkbox>
        <Checkbox value="1440" className="mx-0">
          1440p
        </Checkbox>
        <Checkbox value="2160" className="mx-0">
          2160p
        </Checkbox>
      </Checkbox.Group>
    ),
  },
  {
    label: "Orientation",
    value: "orientation",
    selector: "checkbox",
    children: (props) => (
      <Checkbox.Group className="flex flex-col gap-y-3" {...props}>
        <Checkbox value="portrait" className="mx-0">
          Portrait
        </Checkbox>
        <Checkbox value="landscape" className="mx-0">
          Landscape
        </Checkbox>
      </Checkbox.Group>
    ),
  },
  {
    label: "Sort",
    value: "sort",
    selector: "radio",
    children: (props) => (
      <Radio.Group className="flex flex-col gap-y-3" {...props}>
        <Radio value="createdAt.1" className="mx-0">
          Upload Date - Earliest First
        </Radio>
        <Radio value="createdAt.-1" className="mx-0">
          Upload Date - Latest First
        </Radio>
      </Radio.Group>
    ),
  },
];

function AssetFilters(props: any) {
  const [category, setCategory] = useState<string>(FILTER_CATEGORIES[0].value);

  const filterCategory = FILTER_CATEGORIES.find((fc) => fc.value === category);
  return (
    <Row className="border">
      <Col span={8} className="border-r">
        {FILTER_CATEGORIES.map((filter) => (
          <div
            className={`cursor-pointer flex items-center justify-between gap-x-2 px-2 pb-3 pt-3 ${
              category === filter.value ? "bg-gray-100" : ""
            } transition-all`}
            onClick={() => setCategory(filter.value)}
          >
            {filter.label}{" "}
            <Badge
              count={
                props.value?.[filter.value]
                  ? Array.isArray(props.value?.[filter.value])
                    ? props.value?.[filter.value].length
                    : 1
                  : 0
              }
            />
          </div>
        ))}
      </Col>
      <Col span={16} className="pl-4 pt-2 bg-gray-100">
        {filterCategory.children({
          value: props.value[category],
          onChange: (value) =>
            props.onChange({
              ...props.value,
              [category]:
                filterCategory.selector === "checkbox"
                  ? value
                  : value.target.value,
            }),
        })}
      </Col>
    </Row>
  );
}

const MediaFormControl = (props: any) => {
  const [loading, setLoading] = useState(false);
  const dateFilterInput = useRef<any>(null);
  const [selectedRowAddKeys, setSelectedRowAddKeys] = useState<string[]>([]);
  const [selectedRemoveRowKeys, setSelectedRemoveRowKeys] = useState<number[]>(
    []
  );
  const selectedInputRef = useRef<any>(null);
  const [data, setData] = useState<any>([]);
  const [query, setQuery] = useState<string>("");
  const [addedQuery, setAddedQuery] = useState("");
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [mimeTypes, setMimeTypes] = useState<string[]>([]);
  const [dependentsLoading, setDependentsLoading] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<any[]>([]);
  const [duration, setDuration] = useState<number>(0);
  const [dateFilter, setDateFilter] =
    useState<[Dayjs | undefined, Dayjs | undefined]>();
  useEffect(() => {
    onRefresh();
    handleLoadDependencies();
  }, []);
  const onRefresh = async () => {
    try {
      setLoading(true);
      const data = await assetService.getAll();
      setData(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const calculateTotalDuration = (selectedMedia: any[]) => {
    return selectedMedia.reduce((acc, media) => acc + media.duration, 0);
  };
  const handleLoadDependencies = async () => {
    try {
      setDependentsLoading(true);
      const promises = [assetService.getUniqueMimeTypes()];
      const [mimeTypes] = await Promise.all(promises);
      setMimeTypes(mimeTypes);
    } catch (error) {
    } finally {
      setDependentsLoading(false);
    }
  };
  const handleMediaSelection = useCallback(() => {
    const addedMedia = data.filter((record) =>
      selectedRowAddKeys.includes(record._id)
    );
    const newSelectedMedia = [...addedMedia, ...(selectedMedia || [])].map(
      (record, index) => ({
        ...record,
        index,
        duration: record.dimension.duration
          ? Math.round(record.dimension.duration)
          : 30,
      })
    );
    setSelectedMedia(newSelectedMedia);
    setDuration(calculateTotalDuration(newSelectedMedia));
    setSelectedRowAddKeys([]);
  }, [selectedRowAddKeys, data]);
  const handleMediaRemoval = useCallback(() => {
    const removedMedia = selectedMedia.filter((record, index) =>
      selectedRemoveRowKeys.includes(record._id)
    );
    setSelectedMedia(
      selectedMedia.filter((record) => !removedMedia.includes(record))
    );
    setSelectedRemoveRowKeys([]);
  }, [selectedRemoveRowKeys, data]);
  const searchDebounced = useCallback(
    debounce(100, (value) => setQuery(value)),
    []
  );
  const addedSearchDebounced = useCallback(
    debounce(100, (value) => setAddedQuery(value)),
    []
  );
  const setSelectedMediaDebounced = useCallback(
    debounce(1000, (value) => {
      setSelectedMedia(value);
      setDuration(calculateTotalDuration(value));
    }),
    []
  );
  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        selectedMedia.slice(),
        oldIndex,
        newIndex
      ).filter((el: any) => !!el);
      const updatedSelectedMedia = newData.map((item: any, index: number) => ({
        ...item,
        index,
      }));
      setSelectedMedia(updatedSelectedMedia);
    }
  };

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<any> = ({
    className,
    style,
    ...restProps
  }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = selectedMedia.findIndex(
      (x) => x._id === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} style={{ ...style }} />;
  };

  useEffect(() => {
    if (selectedMedia.length !== 0) {
      props.onChange(selectedMedia);
    }
  }, [selectedMedia]);

  useEffect(() => {
    if (props.value && selectedMedia.length !== props.value.length) {
      console.log("Setting selected media");

      setSelectedMedia(props.value);
    }
  }, [props.value]);

  return (
    <div className="flex items-stretch w-full mt-4">
      <Modal
        open={filtersOpen}
        onCancel={() => setFiltersOpen(false)}
        title="Filters"
        footer={[
          <Button onClick={() => setFilters({})}>Clear all</Button>,
          <Button onClick={() => setFiltersOpen(false)} type="primary">
            Close
          </Button>,
        ]}
      >
        <AssetFilters onChange={setFilters} value={filters} />
      </Modal>
      <div className="w-1/2 px-2">
        <div className="flex gap-x-2 mb-2">
          <div className="flex gap-x-2 w-1/2">
            <Input
              className="w-full"
              placeholder="Search Available Media by Name"
              onChange={(e) => {
                searchDebounced(e.target.value);
              }}
              allowClear
              suffix={<SearchOutlined />}
            />
            <Tooltip title="Filter">
              <Badge
                count={
                  filters
                    ? Object.keys(filters).filter(
                        (key) => filters[key].length > 0
                      ).length
                    : 0
                }
              >
                <Button
                  type="default"
                  icon={<FilterOutlined />}
                  onClick={() => setFiltersOpen(true)}
                />{" "}
              </Badge>
            </Tooltip>
          </div>
          <div className="flex justify-end gap-x-2 w-1/2">
            <Tooltip title="Refresh">
              <Button
                type="default"
                icon={<SyncOutlined />}
                onClick={async () => {
                  try {
                    await onRefresh();
                    message.success("Refreshed Assets");
                  } catch (error) {
                    message.error("Failed to refresh assets");
                  }
                }}
              />
            </Tooltip>

            <Tooltip title="Add Media">
              <Button
                type="primary"
                icon={<DoubleRightOutlined />}
                onClick={() => handleMediaSelection()}
                disabled={selectedRowAddKeys.length === 0}
              />
            </Tooltip>
          </div>
        </div>
        <Table
          pagination={{ defaultPageSize: 5, showSizeChanger: true }}
          loading={loading}
          className="overflow-table"
          size="small"
          rowKey={(record) => record._id}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                const isSelected = selectedRowAddKeys.includes(record._id);
                setSelectedRowAddKeys(
                  isSelected
                    ? selectedRowAddKeys.filter((k) => k !== record._id)
                    : [...selectedRowAddKeys, record._id]
                );
              },
            };
          }}
          rowSelection={{
            selectedRowKeys: selectedRowAddKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRowAddKeys(selectedRowKeys as string[]);
            },
            getCheckboxProps: (record: any) => ({
              disabled: record.status !== "Completed", // Column configuration not to be checked
            }),
          }}
          columns={[
            {
              title: "",
              dataIndex: "processedUrls",
              width: "20%",
              render: columnRenderer["processedUrls"],
            },
            {
              title: "Name",
              dataIndex: "displayName",
              render: columnRenderer["displayName"],
            },
            {
              title: "Upload Date",
              dataIndex: "createdAt",
              filterIcon: (filtered: boolean) => (
                <CalendarFilled
                  className={`${filtered ? "color-primary-500" : ""}`}
                />
              ),
              onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                  setTimeout(() => dateFilterInput.current?.focus(), 100);
                }
              },
              filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
              }) => (
                <div>
                  <div className="pt-2 px-2">
                    <RangePicker
                      ref={dateFilterInput}
                      value={selectedKeys.map((key) => dayjs(key)) as any}
                      onChange={(value) => {
                        setDateFilter(value);
                        if (value) {
                          setSelectedKeys(value?.map((v) => v.toISOString()));
                        }
                      }}
                    />
                  </div>
                  <Divider className="my-2" />
                  <div className="pb-2 flex justify-between px-2">
                    <Button
                      size="small"
                      type="link"
                      disabled={!dateFilter || dateFilter[0] === undefined}
                      onClick={() => {
                        clearFilters();
                        setDateFilter([undefined, undefined]);
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        confirm({ closeDropdown: true });
                      }}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              ),
              render: columnRenderer["createdAt"],
            },
          ]}
          dataSource={data
            ?.filter(
              (record) => !selectedMedia?.map((m) => m._id).includes(record._id)
            )
            .filter((record) => {
              if (filters.type && filters.type.length > 0) {
                if (!filters.type.includes(record.mimeType.split("/")[0])) {
                  return false;
                }
              }
              if (filters.resolution && filters.resolution.length > 0) {
                if (
                  filters.resolution
                    .map((r) => +r)
                    .some(
                      (r) =>
                        r >
                        Math.min(
                          record.dimension.width,
                          record.dimension.height
                        )
                    )
                ) {
                  return false;
                }
              }
              if (filters.orientation && filters.orientation.length > 0) {
                if (
                  !filters.orientation.includes(
                    record.dimension.width > record.dimension.height
                      ? "landscape"
                      : "portrait"
                  )
                ) {
                  return false;
                }
              }
              return true;
            })
            .filter((record) =>
              record.displayName.match(new RegExp(`${query}`, "i"))
            )
            .filter((record) =>
              dateFilter
                ? dayjs(record.createdAt).isBetween(
                    dateFilter[0] || dayjs("1900-01-01"),
                    dateFilter[1] || dayjs()
                  )
                : true
            )
            .sort((a, b) => {
              if (!filters.sort) {
                return 1;
              }
              let [key, direction] = filters.sort.split(".");
              direction = +direction;
              if (a[key] > b[key]) {
                return direction;
              }
            })}
        />
      </div>
      <Divider type="vertical" />
      <div className="w-1/2 px-2">
        <div className="flex items-center gap-x-2 mb-2">
          <div className="w-full flex items-center gap-x-2">
            <Tooltip title="Remove Media">
              <Button
                type="primary"
                icon={<DoubleLeftOutlined />}
                onClick={() => handleMediaRemoval()}
                disabled={selectedRemoveRowKeys.length === 0}
              />
            </Tooltip>
            Total Media Duration: {duration}s
          </div>
          <Input
            placeholder="Search Selected Media by Name"
            suffix={<SearchOutlined />}
            onChange={(e) => {
              addedSearchDebounced(e.target.value);
            }}
            allowClear
          />
        </div>
        <Table
          pagination={{ defaultPageSize: 5, showSizeChanger: true }}
          size="small"
          rowKey={(record) => record._id}
          rowSelection={{
            selectedRowKeys: selectedRemoveRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRemoveRowKeys(selectedRowKeys as number[]);
            },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                const isSelected = selectedRemoveRowKeys.includes(record._id);
                setSelectedRemoveRowKeys(
                  isSelected
                    ? selectedRemoveRowKeys.filter((k) => k !== record._id)
                    : [...selectedRemoveRowKeys, record._id]
                );
              },
            };
          }}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
          columns={[
            {
              title: "",
              dataIndex: "sort",
              width: 30,
              className: "drag-visible",
              render: () => <DragHandle />,
            },
            {
              title: "",
              dataIndex: "processedUrls",
              width: "20%",
              render: columnRenderer["processedUrls"],
            },
            {
              title: "Name",
              dataIndex: "displayName",
              render: columnRenderer["displayName"],
            },
            {
              title: "Duration",
              dataIndex: "duration",
              render: (duration, record) => {
                return (
                  <InputNumber
                    size="small"
                    addonAfter="sec"
                    // value={duration}
                    defaultValue={duration}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(value: number) => {
                      const selectedMediaIndex = selectedMedia.findIndex(
                        (media) => media._id === record._id
                      );
                      const updatedMedia = [...selectedMedia];
                      updatedMedia[selectedMediaIndex] = {
                        ...record,
                        duration: value,
                      };
                      setSelectedMediaDebounced(updatedMedia);
                    }}
                  />
                );
              },
            },
          ]}
          dataSource={selectedMedia.filter((record) =>
            record.displayName.match(new RegExp(`${addedQuery}`, "i"))
          )}
        />
      </div>
    </div>
  );
};

const OrientationFormControl = (props: any) => {
  return (
    <div className="flex gap-x-6 items-center justify-center">
      <div
        className={`border-4 rounded-xl cursor-pointer flex items-center gap-x-4 w-max p-4 ${
          props.value === "landscape" ? "border-primary-500 bg-primary-50" : ""
        }`}
        onClick={() => props.onChange("landscape")}
      >
        <IoTabletLandscape
          className={
            props.value === "landscape"
              ? "text-6xl text-primary-500"
              : "text-6xl "
          }
        />
        <div
          className={
            props.value === "landscape"
              ? "border-primary-500 text-lg text-primary-500 font-semibold"
              : "text-lg text-gray-500"
          }
        >
          Landscape
        </div>
      </div>
      <div
        className={`border-4 rounded-xl cursor-pointer flex items-center gap-x-4 w-max p-4 ${
          props.value === "portrait" ? "border-primary-500 bg-primary-50" : ""
        }`}
        onClick={() => props.onChange("portrait")}
      >
        <IoTabletPortrait
          className={
            props.value === "portrait"
              ? "text-6xl text-primary-500"
              : "text-6xl "
          }
        />
        <div
          className={
            props.value === "portrait"
              ? "border-primary-500 text-lg text-primary-500 font-semibold"
              : "text-lg text-gray-500"
          }
        >
          Portrait
        </div>
      </div>
    </div>
  );
};

export default function EntityForm(props: any) {
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "Composition Form" },
  ]);
  const [form] = Form.useForm();
  const [id, setId] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadLoading, setLoadLoading] = useState(false);
  const [dependentsLoading, setDependentsLoading] = useState(false);

  const setBreadcrumbs = useBreadcrumbs();

  const preparePayload = (values: any) => {
    return {
      ...values,
      assets: values.assets.map((asset: any) => ({
        asset: asset._id,
        duration: asset.duration,
      })),
    };
  };
  const handleCancel = () => {
    navigate(BASE_PATH);
  };

  useEffect(() => {
    const params: any = querystring.parse(location.search);
    if (!_.isEmpty(params) && params.entity) {
      setId(params.entity);
      setLoadLoading(true);
      service
        .get(params.entity)
        .then((payload) => {
          form.setFieldsValue({
            ...payload,
          });
        })
        .finally(() => setLoadLoading(false));
    }
  }, [location]);
  useEffect(() => {
    handleLoadDependencies();
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);

  const handleLoadDependencies = async () => {
    try {
      setDependentsLoading(true);
    } catch (error) {
    } finally {
      setDependentsLoading(false);
    }
  };
  const handleSubmit = (values: any) => {
    setLoading(true);

    if (id) {
      service
        .update({ ...preparePayload(values), _id: id })
        .then((response) => {
          if (!response.error) {
            navigate(BASE_PATH);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      service
        .create({ ...preparePayload(values) })
        .then((response) => {
          console.log(response);

          if (!response.error) {
            dispatch(compositionActions.setNewlyCreated(response._id));
            navigate(BASE_PATH);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleAutoGenerateName = () => {
    const values = form.getFieldsValue();
    const assets = values.assets.map((asset: any) => asset.displayName);
    form.setFieldsValue({
      name: `COMP-${values.orientation}-${dayjs().format("YYYYMMDDHHmmss")}`,
    });
  };

  return (
    <div className="mx-auto container pb-4">
      <div
        className="flex gap-x-2 items-center cursor-pointer hover:text-primary-500 w-max transition-all"
        onClick={() => navigate(`${BASE_PATH}`)}
      >
        <LeftOutlined /> Back to {TITLE[1]}
      </div>
      <Title level={3} className="mt-4">
        {id ? "Update" : "New"} {TITLE[0]}
      </Title>

      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={{ orientation: "landscape", assets: [] }}
        className="mt-3"
      >
        <div className=" bg-white rounded-lg p-3 mt-3">
          <div className="flex gap-x-2 items-center">
            <div className="bg-primary-500 h-8 w-8 rounded-full flex items-center justify-center">
              <div className=" text-white">{1}</div>
            </div>
            <div className="text-lg">Select Orientation</div>
          </div>
          <Divider className="my-2" />
          <div className="pt-6">
            <Form.Item name="orientation">
              <OrientationFormControl />
            </Form.Item>
          </div>
        </div>
        <div className=" bg-white rounded-lg p-3 mt-3">
          <div className="flex gap-x-2 items-center">
            <div className="bg-primary-500 h-8 w-8 rounded-full flex items-center justify-center">
              <div className=" text-white">{2}</div>
            </div>
            <div className="text-lg">Add Media</div>
          </div>
          <div className="w-full">
            <Form.Item
              name="assets"
              rules={[
                {
                  required: true,
                  message: "Please add atleast one media file",
                },
              ]}
            >
              <MediaFormControl />
            </Form.Item>
          </div>
        </div>
        <div className=" bg-white rounded-lg p-3 mt-3">
          <div className="flex gap-x-2 items-center">
            <div className="bg-primary-500 h-8 w-8 rounded-full flex items-center justify-center">
              <div className=" text-white">{3}</div>
            </div>
            <div className="text-lg">Enter Name</div>
          </div>
          <Divider className="my-2" />
          <div className="pt-6">
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "Composition Name is required." },
              ]}
              extra={
                <div
                  className="text-right text-blue-500 cursor-pointer"
                  onClick={handleAutoGenerateName}
                >
                  Auto-generate name
                </div>
              }
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </div>
        </div>
        <Form.Item noStyle>
          <div className="mt-3 ">
            <Space>
              <Button type="link" onClick={handleCancel}>
                Cancel
              </Button>

              <Button type="primary" loading={loading} htmlType="submit">
                Submit
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}
