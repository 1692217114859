export default function Android9(props: any) {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 372.6 500"
      style={{ height: 30, width: 60 }}
    >
      <g>
        <path
          fill="#75BE5E"
          d="M156.8,500H0V186.3C0,110.7,45.1,43.2,115,14.3c69.8-28.9,149.5-13.1,203,40.4
           c53.5,53.5,69.3,133.1,40.4,203c-28.9,69.8-96.5,115-172.1,115c0,0,0,0,0,0h-29.4V500z M156.8,215.7h29.4c0,0,0,0,0,0
           c11.9,0,22.6-7.1,27.2-18.2c4.6-11,2.1-23.6-6.4-32c-8.4-8.4-21-10.9-32-6.4c-11,4.6-18.2,15.2-18.2,27.2V215.7z"
        />
        <path
          fill="#FFFFFF"
          d="M117.6,500H39.2V186.3c0-59.7,35.6-113,90.8-135.8c55.1-22.8,118-10.3,160.2,31.9
           c42.2,42.2,54.7,105.1,31.9,160.2c-22.8,55.1-76.2,90.8-135.8,90.8c0,0,0,0,0,0h-29.4V255h29.4c0,0,0,0,0,0
           c27.9,0,52.7-16.6,63.4-42.4c10.7-25.7,4.8-55.1-14.9-74.8c-19.7-19.7-49-25.5-74.8-14.9c-25.7,10.7-42.4,35.5-42.4,63.4V500z"
        />
      </g>
    </svg>
  );
}
