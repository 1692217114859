import { getRequest, postRequest, putRequest } from "./config/request";
const SERVICE_URL = "/composition";
export const create = (payload) => postRequest(`${SERVICE_URL}`, payload);

export const update = (payload) => putRequest(`${SERVICE_URL}`, payload);
export const archive = (id: string) =>
  putRequest(`${SERVICE_URL}/archive/${id}`);
export const unarchive = (id: string) =>
  putRequest(`${SERVICE_URL}/unarchive/${id}`);
export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);

export const getAll = () => getRequest(`${SERVICE_URL}/all`);
export const get = (id) => getRequest(`${SERVICE_URL}/${id}`);

export default {
  create,
  update,
  paginate,
  get,
  getAll,
  archive,
  unarchive,
};
