import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import authReducer, { AuthState } from "./authSlice";
import urlReducer, { UrlState } from "./urlSlice";
import uiReducer, { UIState } from "./uiSlice";
import telemetryReducer, { TelemetryState } from "./telemetrySlice";
import proofOfPlayReducer, { ProofOfPlayState } from "./proofOfPlaySlice";
import dashboardReducer, { DashboardState } from "./dashboardSlice";
import compositionReducer, { CompositionState } from "./compositionSlice";
import canvasReducer, { CanvasState } from "./canvasSlice";
export interface MasterEntityState {
  pagination: {
    list: any[];
    queryCount: number;
    totalCount: number;
  };
  entities: any[];
  current: any;
  viewLoading: boolean;
  viewError: any;
  loadLoading: boolean;
  loadError: any;
  formLoading: boolean;
  formError: any;
  progress: { percent: any; text: any };
}

export interface Store {
  auth: AuthState;
  dashboard: DashboardState;
  url: UrlState;
  ui: UIState;
  telemetry: TelemetryState;
  proofOfPlay: ProofOfPlayState;
  composition: CompositionState;
  canvas: CanvasState;
}

const rootReducer = {
  auth: authReducer,
  dashboard: dashboardReducer,
  url: urlReducer,
  ui: uiReducer,
  telemetry: telemetryReducer,
  proofOfPlay: proofOfPlayReducer,
  composition: compositionReducer,
  canvas: canvasReducer,
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistCombinedReducers = persistCombineReducers(
  persistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistCombinedReducers,
  middleware: [thunk],
  devTools: true,
});

export const persistor = persistStore(store);
