import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Typography,
  Input,
  Select,
  Button,
  Space,
  Spin,
  Divider,
  Table,
} from "antd";
import { TITLE, BASE_PATH } from ".";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import querystring from "query-string";
import service from "@services/deviceGroup";
import deviceService from "@services/device";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useDraggable,
  rectIntersection,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { arrayMove } from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { useDroppable } from "@dnd-kit/core";
import KanbanBoard from "./Kanban/KanbanBoard";
import useClient from "@hooks/useClient";
import dayjs from "dayjs";
const { Title } = Typography;
const { Option } = Select;

function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });
  const style = {
    color: isOver ? "green" : undefined,
  };

  return (
    <div ref={setNodeRef} style={style} className="flex flex-col">
      {props.children}
    </div>
  );
}

function Draggable(props) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <button
      ref={setNodeRef}
      className={`${props.id === "0" ? "invisible" : "unset"}`}
      style={style}
      {...listeners}
      {...attributes}
    >
      {props.children}
    </button>
  );
}

const AssociateFormControl = (props) => {
  const [associationSelectedRowKeys, setAssociationSelectedRowKeys] = useState<
    React.Key[]
  >([]);
  const [dissociationSelectedRowKeys, setDissociationSelectedRowKeys] =
    useState<React.Key[]>([]);
  const columns: any[] = [
    { key: "macAddress", title: "MAC Address", dataIndex: "macAddress" },
    { key: "platformName", title: "Platform Name", dataIndex: "platformName" },
    { key: "vendorName", title: "Vendor Name", dataIndex: "vendorName" },
    { key: "vendorId", title: "Vendor Id", dataIndex: "vendorId" },
    {
      key: "vendorProductId",
      title: "Vendor Product Id",
      dataIndex: "vendorProductId",
    },
    { key: "productId", title: "Product Id", dataIndex: "productId" },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => (a > b ? 1 : -1),
      render: (text, record, index) => dayjs(text).format("DD/MM/YYYY"),
    },
  ];
  const resetSelection = () => {
    setAssociationSelectedRowKeys([]);
    setDissociationSelectedRowKeys([]);
  };

  const handleAssociate = () => {
    props.onChange(
      Array.from(
        new Set([...(props.value || []), ...associationSelectedRowKeys])
      )
    );
    resetSelection();
  };
  const handleDissociate = () => {
    if (props.value?.length > 0) {
      props.onChange(
        props.value.filter((d) => !dissociationSelectedRowKeys.includes(d))
      );
    }
    resetSelection();
  };
  const associationRowSelection = {
    associationSelectedRowKeys,
    onChange: setAssociationSelectedRowKeys,
  };
  const dissociationRowSelection = {
    dissociationSelectedRowKeys,
    onChange: setDissociationSelectedRowKeys,
  };
  return (
    <div className="flex flex-col gap-y-4">
      <Table
        showHeader
        title={(currentPageData) => {
          return (
            <span className="font-semibold">
              Available Devices ({currentPageData.length})
            </span>
          );
        }}
        pagination={{ position: ["topRight"] }}
        size="small"
        bordered
        rowKey={(record) => record._id}
        rowSelection={associationRowSelection}
        columns={[...columns]}
        dataSource={props.data.filter((d) =>
          !props.value || props.value.length === 0
            ? true
            : !props.value.includes(d._id)
        )}
      />
      <div className="flex gap-x-2 justify-center my-3">
        <Button
          size="small"
          icon={<DownOutlined />}
          onClick={handleAssociate}
          children="Associate"
          disabled={associationSelectedRowKeys.length === 0}
        />
        <Button
          size="small"
          icon={<UpOutlined />}
          onClick={handleDissociate}
          children="Dissociate"
          disabled={dissociationSelectedRowKeys.length === 0}
        />
      </div>
      <Table
        size="small"
        title={(currentPageData) => {
          return (
            <span className="font-semibold">
              Associated Devices ({currentPageData.length})
            </span>
          );
        }}
        pagination={{ position: ["topRight"] }}
        rowKey={(record) => record._id}
        rowSelection={dissociationRowSelection}
        bordered
        columns={[...columns]}
        dataSource={props.data.filter(
          (d) => props.value?.length > 0 && props.value.includes(d._id)
        )}
      />
    </div>
  );
};

export default function EntityForm(props: any) {
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "Device Form" },
  ]);
  const client = useClient();
  const [form] = Form.useForm();
  const [id, setId] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadLoading, setLoadLoading] = useState(false);
  const [dependentsLoading, setDependentsLoading] = useState(false);
  const [masters, setMasters] = useState<any>({});
  const setBreadcrumbs = useBreadcrumbs();

  const handleSubmit = (values: any) => {
    values = { ...values, client };
    setLoading(true);
    if (id) {
      service
        .update({ ...values, _id: id })
        .then((response) => {
          if (!response.error) {
            navigate(BASE_PATH);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      service
        .create({ ...values })
        .then((response) => {
          if (!response.error) {
            navigate(BASE_PATH);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleCancel = () => {
    navigate(BASE_PATH);
  };
  useEffect(() => {
    const params: any = querystring.parse(location.search);
    if (!_.isEmpty(params)) {
      setId(params.entity);
      setLoadLoading(true);
      service
        .get(params.entity)
        .then((payload) => {
          form.setFieldsValue({ ...payload });
        })
        .finally(() => setLoadLoading(false));
    }
  }, [location]);
  useEffect(() => {
    handleLoadDependencies();
    setBreadcrumbs(breadcrumbsRef.current);
  }, [client]);
  const handleLoadDependencies = async () => {
    try {
      setDependentsLoading(true);
      const promises = [deviceService.getUnAssociated(id)];
      const [devices] = await Promise.all(promises);
      setMasters({ devices });
    } catch (error) {
    } finally {
      setDependentsLoading(false);
    }
  };

  return (
    <div>
      <Title level={3}>
        {id ? "Update" : "New"} {TITLE[0]}
      </Title>
      <Spin spinning={loadLoading}>
        <Form layout="vertical" form={form} onFinish={handleSubmit} preserve>
          <div className="mt-4 bg-white rounded-lg px-2 py-4">
            <div className="flex flex-wrap ">
              <Form.Item
                className="w-full px-2"
                label="Name"
                rules={[{ required: true, message: "Name is required" }]}
                name={["name"]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
              <Form.Item
                className="w-full px-2"
                label="Orientation"
                rules={[{ required: true, message: "Orientation is required" }]}
                name="orientation"
              >
                <Select placeholder="Select Orientation">
                  <Option value="PORTRAIT">Portrait</Option>
                  <Option value="LANDSCAPE">Landscape</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="mt-2 bg-white rounded-lg px-4 py-4">
            <div className="">
              <Form.Item noStyle>
                <Space>
                  <Button type="default" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );
}
