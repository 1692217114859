import RegisterDevice from "@views/RegisterDevice";
import { Layout } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import * as authActions from "../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Header from "@components/Header";
import { Store } from "@store/index";
import Logout from "../utils/logout";

const { Content } = Layout;

export default function SetupLayout(props: any) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const authState = useSelector((state: Store) => state.auth);
  const [showNav, setShowNav] = useState<boolean>(true);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  useEffect(() => {
    dispatch(authActions.getUserAsync());
  }, []);
  return (
    <Layout className="min-h-screen">
      <Header
        showNav={showNav}
        onShowDrawer={setShowDrawer}
        onLogout={() => Logout()}
        onProfile={() => navigate("/profile")}
        user={authState.user}
      />
      <Content>
        <Routes>
          <Route path="/:id" element={<RegisterDevice />} />
        </Routes>
      </Content>
    </Layout>
  );
}
