import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/asset/folder";

export const create = (payload) => postRequest(`${SERVICE_URL}`, payload);
export const update = (payload) => putRequest(`${SERVICE_URL}`, payload);
export const getAll = () => getRequest(`${SERVICE_URL}/all`);
export const getChildren = (parent: string) =>
  getRequest(`${SERVICE_URL}/children/${parent}`);
export const get = (id) => getRequest(`${SERVICE_URL}/${id}`);
export const remove = (id) => deleteRequest(`${SERVICE_URL}/${id}`);
export const checkFolderName = (parent: string, name: string) =>
  getRequest(`${SERVICE_URL}/check/${parent}/${name}`);
export const rename = (id: string, name: string) =>
  putRequest(`${SERVICE_URL}/rename`, { name, id });
export const move = (payload) => putRequest(`${SERVICE_URL}/move`, payload);
export default {
  create,
  update,
  get,
  getAll,
  remove,
  getChildren,
  checkFolderName,
  rename,
  move,
};
