import { API_URL } from "./apiUrl";
import axios from "axios";

import logout from "../../utils/logout";
import { socket, getSocket } from "./progress";

function getClient() {
  return localStorage.getItem("client") || "";
}

const handleError = (err: any) => {
  if (err.status) {
    if (err.status === 401) {
      logout();
    }
  }
  let error = { statusCode: 0, statusText: "" };
  if (!err.response) {
    error.statusCode = 1000;
    error.statusText =
      "Cannot reach server. Please check your internet connection.";
  } else {
    error.statusCode = err.response.status;
    error.statusText = err.response.data.error.message;
  }
  throw err;
};

export const getRequest = async (
  url: string,
  headers = { Client: getClient() }
) => {
  const socket = getSocket();

  try {
    const res = await axios.get(API_URL + url, {
      headers: Object.assign({ Socket: socket && socket.id }, headers),
      withCredentials: true,
    });
    return res && res.data && (res.data.result || res.data);
  } catch (err) {
    handleError(err);
  }
};

export const getListRequest = async (
  url: string,
  headers = { Client: getClient() }
) => {
  try {
    const res = await axios.get(API_URL + url, {
      headers: Object.assign({}, headers),
      withCredentials: true,
    });
    return res && res.data && (res.data.result || res.data);
  } catch (err) {
    handleError(err);
  }
};

export const postRequest = async (
  url: string,
  data = {},
  headers = { Client: getClient() }
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      headers: Object.assign({ Socket: socket && socket.id }, headers),
      data,
      withCredentials: true,
    });
    return res && res.data && (res.data.result || res.data);
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

export const putRequest = async (
  url: string,
  data = {},
  headers = { Client: getClient() }
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "PUT",
      headers: Object.assign({ Socket: socket && socket.id }, headers),
      data,
      withCredentials: true,
    });
    return res && res.data && (res.data.result || res.data);
  } catch (err) {
    handleError(err);
  }
};

export const deleteRequest = async (
  url: string,
  headers = { Client: getClient() }
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "DELETE",
      headers: Object.assign({ Socket: socket && socket.id }, headers),
      withCredentials: true,
    });
    return res && res.data && (res.data.result || res.data);
  } catch (err) {
    handleError(err);
  }
};

export const postRequestBinary = async (
  url: string,
  data = {},
  headers = { Client: getClient() }
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      headers: Object.assign({ Socket: socket && socket.id }, headers),
      data,
      withCredentials: true,
      responseType: "blob",
    });
    return res && res.data && (res.data.result || res.data);
  } catch (err) {
    handleError(err);
  }
};

export const getBlobRequest = async (
  url: string,
  headers = { Client: getClient() }
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "GET",
      headers: Object.assign({ Socket: socket && socket.id }, headers),
      responseType: "blob",
      withCredentials: true,
    });
    return res && res.data && (res.data.result || res.data);
  } catch (err) {
    handleError(err);
  }
};
export const postBlobRequest = async (
  url: string,
  data = {},
  headers = { Client: getClient() }
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      headers: Object.assign({ Socket: socket && socket.id }, headers),
      responseType: "blob",
      data: data,
      withCredentials: true,
    });
    return res && res.data && (res.data.result || res.data);
  } catch (err) {
    handleError(err);
  }
};

export const api = {
  deleteRequest,
  getListRequest,
  getRequest,
  postRequest,
  putRequest,
  postRequestBinary,
  getBlobRequest,
  postBlobRequest,
};
