export default function Android9(props: any) {
  return (
    <svg
      width="457"
      height="457"
      viewBox="0 0 457 457"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: 30 }}
    >
      <g clip-path="url(#clip0_93_130)">
        <path
          d="M228.5 428.693C339.063 428.693 428.693 339.064 428.693 228.5C428.693 117.936 339.063 28.307 228.5 28.307C117.936 28.307 28.3069 117.936 28.3069 228.5C28.3069 339.064 117.936 428.693 228.5 428.693Z"
          fill="url(#paint0_linear_93_130)"
        />
        <mask
          id="mask0_93_130"
          maskUnits="userSpaceOnUse"
          x="28"
          y="28"
          width="401"
          height="401"
        >
          <path
            d="M228.5 428.693C339.063 428.693 428.693 339.064 428.693 228.5C428.693 117.936 339.063 28.307 228.5 28.307C117.936 28.307 28.3069 117.936 28.3069 228.5C28.3069 339.064 117.936 428.693 228.5 428.693Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_93_130)">
          <path
            d="M228.949 226.654C366.301 226.654 477.647 115.308 477.647 -22.044C477.647 -159.396 366.301 -270.742 228.949 -270.742C91.5969 -270.742 -19.749 -159.396 -19.749 -22.044C-19.749 115.308 91.5969 226.654 228.949 226.654Z"
            fill="url(#paint1_radial_93_130)"
          />
          <path
            d="M167.712 160.076L169.967 153.381C183.893 231.582 220.86 438.04 220.86 203.76C220.86 202.786 221.584 201.948 222.477 201.948H235.184C236.081 201.948 236.809 202.776 236.809 203.754C236.607 614.056 167.712 160.076 167.712 160.076Z"
            fill="#F8FF5C"
          />
          <path
            d="M131.181 153.105L97.9831 210.606C96.0761 213.908 97.2081 218.131 100.51 220.038C103.812 221.945 108.035 220.813 109.942 217.511L143.56 159.285C197.882 183.636 260.016 183.636 314.338 159.285L347.956 217.511C349.93 220.773 354.175 221.818 357.437 219.844C360.606 217.927 361.697 213.85 359.911 210.606L326.715 153.105C383.722 122.102 422.715 64.378 428.42 -3.81H29.4771C35.1821 64.378 74.1751 122.103 131.181 153.105Z"
            fill="black"
          />
          <path
            d="M222.759 160.09H234.68C235.855 160.09 236.807 161.042 236.807 162.217V190.315C236.807 191.49 235.855 192.442 234.68 192.442H222.759C221.584 192.442 220.632 191.49 220.632 190.315V162.217C220.632 161.043 221.585 160.09 222.759 160.09Z"
            fill="#F8FF5C"
          />
          <path
            d="M222.61 142.703H234.83C235.922 142.703 236.808 143.589 236.808 144.681V148.813C236.808 149.905 235.922 150.791 234.83 150.791H222.61C221.518 150.791 220.632 149.905 220.632 148.813V144.681C220.632 143.589 221.518 142.703 222.61 142.703Z"
            fill="#F8FF5C"
          />
          <path
            d="M148.38 189.285H144.336V193.329H148.38V189.285Z"
            fill="white"
          />
          <path
            d="M165.281 248.246H161.237V252.29H165.281V248.246Z"
            fill="white"
          />
          <path
            d="M345.693 310.213H341.649V314.257H345.693V310.213Z"
            fill="white"
          />
          <path
            d="M262.499 224.717H258.455V228.761H262.499V224.717Z"
            fill="white"
          />
          <path
            d="M295.565 191.307H291.521V195.351H295.565V191.307Z"
            fill="white"
          />
          <path
            d="M270.636 261.086H266.592V265.13H270.636V261.086Z"
            fill="white"
          />
          <path
            d="M311.479 245.827H303.391V253.915H311.479V245.827Z"
            fill="white"
          />
          <path
            d="M165.281 271.49H161.237V275.534H165.281V271.49Z"
            fill="white"
          />
          <path
            d="M200.694 211.425H192.606V219.513H200.694V211.425Z"
            fill="white"
          />
          <path
            d="M252.703 291.463H244.615V299.551H252.703V291.463Z"
            fill="white"
          />
          <path
            d="M273.962 321.838H265.874V329.926H273.962V321.838Z"
            fill="white"
          />
          <path
            d="M141.549 226.739H133.461V234.827H141.549V226.739Z"
            fill="white"
          />
          <path
            d="M360.979 163.313H352.891V171.401H360.979V163.313Z"
            fill="white"
          />
          <path
            d="M118.344 296.267H110.256V304.355H118.344V296.267Z"
            fill="white"
          />
        </g>
        <path
          d="M228.5 457C167.465 457 110.084 433.232 66.926 390.074C23.768 346.916 0 289.534 0 228.5C0 167.465 23.768 110.084 66.926 66.926C110.084 23.768 167.465 0 228.5 0C289.534 0 346.916 23.768 390.074 66.926C433.232 110.084 457 167.465 457 228.5C457 289.534 433.232 346.916 390.074 390.074C346.916 433.232 289.534 457 228.5 457ZM228.5 56.615C133.722 56.615 56.615 133.722 56.615 228.5C56.615 323.278 133.722 400.385 228.5 400.385C323.278 400.385 400.385 323.278 400.385 228.5C400.385 133.722 323.278 56.615 228.5 56.615Z"
          fill="#F86734"
        />
        <path
          d="M65.6719 88.72L69.5229 84.353L99.1909 94.12L94.7509 99.155L88.5159 97.054L80.6529 105.972L83.5179 111.895L79.0789 116.93L65.6719 88.72ZM82.7789 95.122L73.9069 92.098L78.0179 100.521L82.7789 95.122Z"
          fill="white"
        />
        <path
          d="M126.011 73.196L104.279 66.986L114.665 81.382L109.451 85.145L92.6538 61.861L97.1458 58.621L118.821 64.821L108.458 50.458L113.705 46.672L130.502 69.954L126.011 73.196Z"
          fill="white"
        />
        <path
          d="M133.863 35.453L142.863 31.605C150.302 28.424 158.838 31.725 161.971 39.051C165.103 46.377 161.59 54.83 154.151 58.009L145.151 61.857L133.863 35.453ZM151.83 52.581C156.293 50.673 157.795 45.852 155.982 41.612C154.169 37.372 149.647 35.127 145.184 37.035L142.097 38.354L148.743 53.9L151.83 52.581Z"
          fill="white"
        />
        <path
          d="M176.668 19.861L187.775 17.663C193.091 16.611 197.255 19.744 198.181 24.425C198.904 28.075 197.422 30.799 194.104 32.775L206.517 43.221L198.942 44.72L187.281 34.95L186.25 35.154L188.55 46.776L182.243 48.024L176.67 19.861H176.668ZM189.235 28.998C191.099 28.63 192.236 27.291 191.915 25.665C191.577 23.959 190.032 23.235 188.168 23.603L184.122 24.405L185.189 29.799L189.235 28.998Z"
          fill="white"
        />
        <path
          d="M216.777 27.897C216.91 19.73 223.649 13.167 231.855 13.301C240.021 13.433 246.584 20.212 246.451 28.378C246.318 36.545 239.539 43.107 231.373 42.974C223.166 42.841 216.645 36.064 216.777 27.897ZM239.942 28.273C240.017 23.624 236.403 19.602 231.753 19.527C227.063 19.451 223.321 23.353 223.245 28.002C223.169 32.652 226.783 36.673 231.473 36.749C236.122 36.824 239.866 32.922 239.941 28.273H239.942Z"
          fill="white"
        />
        <path
          d="M266.915 17.124L273.227 18.355L267.73 46.539L261.418 45.308L266.915 17.124Z"
          fill="white"
        />
        <path
          d="M294.492 23.71L303.636 27.195C311.194 30.076 315.04 38.379 312.202 45.823C309.365 53.267 300.967 56.904 293.411 54.023L284.266 50.538L294.493 23.709L294.492 23.71ZM295.513 48.507C300.047 50.236 304.477 47.812 306.119 43.504C307.761 39.197 306.068 34.44 301.534 32.713L298.398 31.518L292.377 47.312L295.513 48.507Z"
          fill="white"
        />
        <path
          d="M354.021 61.568L349.809 58.354L353.464 53.564L362.532 60.481L345.116 83.308L340.26 79.604L354.021 61.568Z"
          fill="white"
        />
        <path
          d="M370.586 98.74L361.02 88.474L364.628 85.111L387.448 83.856L391.197 87.88L378.831 99.404L381.34 102.096L377.257 105.901L374.749 103.209L370.193 107.454L366.03 102.987L370.586 98.74ZM380.467 89.532L370.175 90.113L374.668 94.936L380.467 89.532Z"
          fill="white"
        />
        <path
          d="M228.5 98.421C221.8 98.421 216.368 103.696 216.368 110.203V124.736C216.368 125.992 217.386 127.011 218.643 127.011C219.9 127.011 220.918 125.993 220.918 124.736V120.818C220.918 118.306 222.955 116.269 225.467 116.269H231.533C234.045 116.269 236.082 118.306 236.082 120.818V124.736C236.082 125.992 237.1 127.011 238.357 127.011C239.614 127.011 240.632 125.993 240.632 124.736V110.203C240.632 103.696 235.201 98.421 228.5 98.421Z"
          fill="#3DDC84"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_93_130"
          x1="228.5"
          y1="-5.91439"
          x2="228.5"
          y2="323.014"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#4283F2" />
          <stop offset="1" stop-color="#073042" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_93_130"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(228.949 -22.0439) scale(248.698)"
        >
          <stop offset="0.828" stop-color="#ACC6F2" />
          <stop offset="1" stop-color="#4283F2" stop-opacity="0" />
        </radialGradient>
        <clipPath id="clip0_93_130">
          <rect width="457" height="457" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
