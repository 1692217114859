export default function Android9(props: any) {
  return (
    <svg
      width="512"
      height="509"
      viewBox="0 0 512 509"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: 30 }}
    >
      <path
        d="M194.193 507.591C185.549 505.554 178.315 500.791 168.191 490.471C151.28 473.232 137.557 466.055 113.704 461.974C88.9592 457.742 77.9632 448.602 71.3525 426.773C63.1921 399.827 54.6632 386.922 35.9238 373.168C14.6655 357.565 9.21116 343.806 14.3998 318.871C19.2057 295.777 17.3654 279.357 7.60497 258.246C-4.04052 233.058 -2.60294 219.598 13.9371 198.962C29.1004 180.043 33.8596 167.538 36.1305 140.647C38.4222 113.511 46.0199 102.378 68.396 93.368C87.0707 85.8486 103.046 72.7952 111.904 57.819C122.146 40.5009 123.641 38.5157 129.987 33.8109C140.228 26.2186 148.507 24.1145 164.276 25.0971C191.668 26.8039 206.349 23.5357 224.744 11.6363C248.728 -3.87875 263.27 -3.87875 287.254 11.6363C305.648 23.5357 320.33 26.8039 347.721 25.0971C373.624 23.483 384.403 30.2402 400.192 57.9918C408.016 71.7449 424.01 85.1298 441.514 92.5736C466.444 103.176 473.69 112.853 475.529 138C477.52 165.234 483.14 180.461 498.027 198.962C514.603 219.562 516.049 233.034 504.393 258.246C494.632 279.357 492.792 295.777 497.598 318.871C502.786 343.806 497.332 357.565 476.074 373.168C457.334 386.922 448.806 399.827 440.645 426.773C434.034 448.602 423.038 457.742 398.294 461.974C374.39 466.063 360.651 473.263 343.806 490.525C325.133 509.662 311.747 512.819 285.641 504.244C264.289 497.23 247.849 497.2 226.582 504.137C211.685 508.995 203.744 509.842 194.193 507.591Z"
        fill="#3DDC84"
      />
      <path
        d="M260.76 392.407L265.72 380.413C392.236 367.843 395.705 268.683 315.842 233.768L293.28 228.991L314.66 185.667L335.417 145.277L301.301 144.152H262.944V136.322V128.492H310.809L365.619 129.057L341.602 175.408L322.059 216.719L323.705 218.498L329.825 220.278C449.605 245.901 397.775 436.178 260.76 392.407ZM192.24 269.432V144.152H162.598H132.956V136.322V128.492H168.751H204.545V263.839V396.949H198.393H192.24V269.432Z"
        fill="white"
      />
      <path
        d="M254.385 408.259L260.76 392.407C325.568 412.681 386.118 371.835 386.118 307.137C386.118 263.759 353.309 228.08 316.617 218.456L338.147 174.488L360.02 130.461H311.645H262.943V121.501V112.832H325.865H389.114L365.069 160.911L341.024 208.991C383.462 230.348 403.51 268.301 403.51 307.5C403.51 382.902 328.492 436.663 254.385 408.259ZM174.343 278.94V160.93H153.65H132.956V151.982V143.033H162.598H192.24V269.991V396.949H183.292H174.343V278.94ZM204.545 263.839V130.729H168.751H132.956V121.781V112.832H177.699H222.442V254.89V396.949H213.493H204.545V263.839ZM265.72 380.413L271.935 363.588C314.867 378.283 354.262 348.583 354.727 308.625C354.727 264.863 320.981 247.208 290.278 245.071L270.554 242.637L290.602 201.343L310.793 160.975L287.031 160.673L262.943 160.371V151.702V143.033H301.257H339.897L318.649 185.519L297.4 228.005C342.63 234.619 373.313 266.389 373.313 306.904C373.313 361.686 320.762 399.307 265.72 380.413Z"
        fill="#073042"
      />
    </svg>
  );
}
