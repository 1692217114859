import {
  Button,
  Descriptions,
  Divider,
  List,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import { useParams } from "react-router-dom";
import { TITLE, BASE_PATH, StatusBadge } from ".";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import service from "@services/campaign";
import { useNavigate } from "react-router-dom";

import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
  ExportOutlined,
  CarryOutOutlined,
} from "@ant-design/icons";
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";
import dayjs from "dayjs";
import getThumbnailUrl from "@utils/getThumbnailUrl";
import { EventWrapper } from "@components/EventWrapper";
import useCalendarComponents from "@hooks/useCalendarComponents";
import PreviewPlaylist from "@components/PreviewPlaylist";
import { titleCase } from "title-case";
import { Tab } from "@chakra-ui/react";
import useDevicesHealth from "@hooks/useDevicesHealth";
const { Title } = Typography;
const localizer = dayjsLocalizer(dayjs); // or globalizeLocalizer

function View(props: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const setBreadcrumbs = useBreadcrumbs();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const calendarProps = useCalendarComponents();
  const [groupHealth, setGroupHealth] = useState<any>({});

  const [assets, setAssets] = useState<any[]>([]);
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "View Device" },
  ]);
  const health = useDevicesHealth({
    devices:
      data?.groups
        ?.map((d) => d.devices)
        .reduce((acc, devices) => acc.concat(devices), []) || [],
    ids: [],
    onUpdate: (sockets) => {},
  });
  useEffect(() => {
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const handleForm = (entityId?: string) => {
    if (entityId) {
      navigate(`${BASE_PATH}/edit?entity=${entityId}`);
    } else {
      navigate(`${BASE_PATH}/new`);
    }
  };
  const loadData = (id: string) => {
    setLoading(true);
    service
      .get(id)
      .then((res) => {
        const { compositions } = res;
        const assets = compositions.reduce((acc, comp) => {
          const { assets } = comp;
          return acc.concat(assets);
        }, []);
        setAssets(assets);
        setData(res);
        breadcrumbsRef.current = [
          { label: TITLE[1], url: BASE_PATH },
          { label: res.name },
        ];
        setBreadcrumbs(breadcrumbsRef.current);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (id) {
      loadData(id);
    }
  }, [id]);
  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  );
  const components = useMemo(
    () => ({
      event: (props) => {
        return (
          <div className="flex flex-col gap-y-2 mt-2">
            <div>{props.event.campaignName}</div>
            <div className="italic">{props.event.title}</div>
          </div>
        );
      },
      eventWrapper: memo(EventWrapper),
    }),
    []
  );

  const handlePublish = (id: string) => {
    setLoading(true);
    service
      .updateStatus(id, "PUBLISHED")
      .then((res) => {
        loadData(id);
      })
      .finally(() => setLoading(false));
  };
  const handleCancel = (id: string) => {
    setLoading(true);
    service
      .updateStatus(id, "CANCELLED")
      .then((res) => {
        loadData(id);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (data?.groups && health?.payload) {
      const groupHealth: any = {};
      data.groups.forEach((group) => {
        if (!group.devices) return;
        const devices = group.devices.map((d) => health.payload[d.deviceId]);
        const online = devices.filter((d) => d?.isConnected).length;
        const total = devices.length;
        groupHealth[group._id] = {
          online,
          total,
          health: total === 0 ? 0 : online / total,
        };
      });
      setGroupHealth(groupHealth);
    }
  }, [data?.groups, health?.payload]);

  return (
    <Spin spinning={loading}>
      <div className="flex justify-between">
        <div className="flex gap-x-2 items-baseline ">
          <Title level={3}>{data?.name}</Title>
          <StatusBadge status={data.status} />
        </div>
        <div className="flex gap-x-2">
          {data?.status === "DRAFT" && (
            <div className="flex gap-x-2">
              <Button
                icon={<CarryOutOutlined />}
                onClick={() => handlePublish(id)}
              >
                Publish Campaign
              </Button>
              <Button icon={<EditOutlined />} onClick={() => handleForm(id)}>
                Edit
              </Button>
            </div>
          )}
          {["PUBLISHED", "ACTIVE"].includes(data?.status) && (
            <div className="flex gap-x-2">
              <Button
                icon={<CarryOutOutlined />}
                onClick={() => handleCancel(id)}
              >
                Cancel Campaign
              </Button>
              <Button icon={<EditOutlined />} onClick={() => handleForm(id)}>
                Edit
              </Button>
            </div>
          )}
          {["CANCELLED", "COMPLETED"].includes(data?.status) && (
            <div className="flex gap-x-2">
              <Button icon={<EditOutlined />} onClick={() => handleForm(id)}>
                Edit
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center">
        <PreviewPlaylist
          assets={assets}
          orientation={data.compositions?.[0].orientation}
        />
      </div>
      <div className="bg-white rounded-lg p-3 mt-3">
        <div>
          <span className="font-semibold">Compositions</span>
        </div>
        <Divider className="my-2" />
        <Table
          size="small"
          pagination={false}
          bordered
          dataSource={data.compositions}
          columns={[
            {
              title: "Name",
              key: "name",
              dataIndex: "name",
              render: (name, record) => (
                <div className="flex w-full justify-between">
                  {name} {record.isArchived && <Tag color="gray">ARCHIVED</Tag>}
                </div>
              ),
            },
            {
              title: "Orientation",
              key: "orientation",
              dataIndex: "orientation",
              render: (text) => titleCase(text || ""),
            },
            {
              title: "Total Duration",
              key: "totalDuration",
              dataIndex: "assets",
              render: (text) => (
                <div>
                  {text.reduce((acc, curr) => acc + curr.duration, 0)} secs
                </div>
              ),
            },
            {
              title: "Min. Resolution",
              key: "minimumResolution",

              dataIndex: "minimumResolution",
            },
          ]}
        />
      </div>
      <div className="bg-white rounded-lg p-3 mt-3">
        <div>
          <span className="font-semibold">Device Groups</span>
        </div>
        <Divider className="my-2" />
        <Table
          dataSource={data.groups}
          pagination={false}
          size="small"
          bordered
          columns={[
            {
              key: "name",
              title: "Name",
              dataIndex: "name",
            },
            {
              key: "deviceCount",
              title: "Device Count",
              dataIndex: "devices",
              render: (devices, record) => {
                if (!devices) {
                  return <></>;
                }
                const deviceMetrics = groupHealth[record._id];
                const color = !deviceMetrics
                  ? "default"
                  : deviceMetrics.health > 0.8
                  ? "green"
                  : deviceMetrics.health > 0.5
                  ? "gold"
                  : "red";
                return (
                  <div>
                    {devices?.length}{" "}
                    {devices?.length > 0 && (
                      <Tag color={color}>
                        {(deviceMetrics?.health * 100).toFixed(2)}% Screens
                        Online
                      </Tag>
                    )}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
      <div className="bg-white rounded-lg p-3 mt-3">
        <div>
          <span className="font-semibold">Schedule Information</span>
        </div>
        <Divider className="my-2" />
        <Calendar
          {...calendarProps}
          views={{ day: true, week: true, month: true, agenda: true }}
          defaultDate={defaultDate}
          defaultView={Views.AGENDA}
          events={data.slots?.map((slot) => ({
            ...slot,
            start: new Date(slot.start),
            end: new Date(slot.end),
            status: data.status,
          }))}
          selectable
          style={{ height: 600 }}
        />
      </div>
    </Spin>
  );
}
export default View;
