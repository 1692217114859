export default function Android9(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="45 45 810 810"
      style={{ height: 30 }}
    >
      <path
        fill="#eec749"
        d="M 432,852.93657 C 358.24301,849.55594 287.43866,826.40465 226.76861,785.83093 181.86061,755.79825 146.01098,720.14915 115.67,675.35375 79.658014,622.18571 58.308526,564.96786 49.308069,497.5 c -2.482992,-18.61263 -2.482992,-76.38737 0,-95 7.447905,-55.82985 23.705562,-105.45421 49.469788,-151 6.949453,-12.28518 26.236333,-40.90333 34.707863,-51.5 19.141,-23.94268 41.67464,-46.53461 65.51428,-65.6838 11.35567,-9.12144 39.60438,-28.24358 52.5,-35.538343 45.54579,-25.764226 95.17015,-42.021883 151,-49.469788 18.61263,-2.482992 76.38737,-2.482992 95,0 55.82985,7.447905 105.45421,23.705562 151,49.469788 12.28518,6.949453 40.90333,26.236333 51.5,34.707863 83.50937,66.76168 136.58718,160.55499 150.00828,265.07825 14.119,109.95837 -16.15978,217.51536 -85.83381,304.90095 -12.43369,15.5944 -45.35616,48.51669 -60.67447,60.67414 -60.12643,47.71961 -129.41549,77.24464 -202.5,86.28807 -12.36036,1.52946 -43.69708,3.68797 -49.5,3.40962 -1.65,-0.0792 -10.425,-0.48423 -19.5,-0.90018 z m 50.18826,-240.85352 c 21.50125,-4.32486 42.45719,-13.07268 61.68063,-25.74786 11.62243,-7.66338 34.80292,-30.84387 42.4663,-42.4663 12.98205,-19.68884 22.17167,-42.17766 26.30308,-64.36889 2.78451,-14.95659 2.5628,-43.0804 -0.45926,-58.2554 -5.62413,-28.24112 -16.94295,-52.04702 -36.33496,-76.42023 C 554.60773,318.13311 518.10517,297.02891 481,289.98964 c -11.41359,-2.16529 -29.34183,-3.02384 -41.83281,-2.0033 -23.06556,1.8845 -41.33705,6.94339 -61.66719,17.07399 -16.09655,8.02099 -28.75711,17.19678 -42,30.43967 -6.05,6.05 -14.0285,15.275 -17.73001,20.5 -32.71973,46.1868 -39.4595,107.58241 -17.4544,159 10.63291,24.84508 26.40979,46.44314 44.35641,60.72254 28.15249,22.39981 58.09986,35.14532 90.828,38.65612 9.22566,0.98966 37.19154,-0.3854 46.68826,-2.29561 z"
      />
      <path
        fill="#edb406"
        d="M 431.11869,851.01165 C 362.66419,847.31291 300.33477,828.42454 243.11409,794.03857 196.54837,766.05554 153.52276,725.95086 121.18381,680.38591 93.974786,642.04892 71.65263,592.86825 60.045324,545.68424 47.499859,494.6866 45.463085,434.7465 54.469152,381.58348 66.002962,313.49913 95.125411,250.12019 140.57213,194.19828 150.72523,181.70496 181.70496,150.72523 194.19828,140.57213 242.41679,101.38582 294.23556,75.248387 353.02833,60.458106 404.49369,47.511155 464.51361,45.337744 518.41652,54.469152 632.26606,73.755802 734.18396,143.51182 794.03857,243.11409 818.9588,284.58311 836.88253,332.0796 844.94041,378 c 5.50214,31.35577 8.02731,72.09455 6.12146,98.75818 -10.40691,145.59676 -96.0302,272.36273 -226.50779,335.3466 -60.40473,29.15844 -126.77797,42.50851 -193.43539,38.90687 z m 52.34515,-117.61098 c 7.13011,-0.78559 13.35743,-1.82194 13.83849,-2.303 0.49954,-0.49954 -28.64827,-30.42909 -67.96384,-69.78661 -65.50362,-65.57354 -74.15513,-74.37767 -71.83849,-73.10585 0.55,0.30194 5.275,2.99778 10.5,5.99074 66.42353,38.04846 146.30271,27.61057 200.05462,-26.14133 36.82599,-36.826 54.07933,-86.78821 47.44562,-137.393 C 612.32152,406.41297 605.16588,386.47859 590.85349,362 c -2.88979,-4.94242 -2.97465,-5.29725 -0.83709,-3.5 1.30829,1.1 33.12872,32.7125 70.71206,70.25 37.58335,37.5375 68.74906,68.25 69.25714,68.25 0.50807,0 1.16916,-1.575 1.46909,-3.5 0.29992,-1.925 0.77551,-4.99403 1.05687,-6.82006 2.86248,-18.57787 2.39648,-54.55269 -0.99732,-76.99218 C 723.62644,357.5344 702.07155,309.84532 667.55255,268.17581 662.84865,262.49751 659,257.20347 659,256.41129 659,254.42108 643.71091,239.54922 630.67741,228.8616 533.65981,149.30608 395.04358,142.69985 291.15557,212.68054 169.55774,294.59085 129.28516,453.20157 197.12838,583 c 11.67796,22.34241 30.66326,48.3926 48.48398,66.52606 19.51768,19.86019 48.76273,41.29133 73.88764,54.14577 51.70344,26.45259 105.37542,36.18404 163.96384,29.72884 z"
      />
      <path
        fill="#ec9500"
        d="M 447.5,851.0169 C 445.32547,850.08584 269.01949,674 270.2618,674 c 0.41664,0 4.03894,2.42671 8.04954,5.3927 40.78861,30.16459 86.28976,48.71429 135.05139,55.0571 25.17735,3.27503 59.16526,2.88357 79.13727,-0.91147 12.71981,-2.41698 38.81532,-9.33849 50.5,-13.39448 C 635.20551,688.1374 705.04081,611.3716 726.89409,518 c 1.73779,-7.425 3.79603,-15.975 4.57386,-19 4.18135,-16.2614 5.31569,-58.48358 2.20585,-82.1052 -7.11199,-54.02085 -27.73247,-102.68172 -61.1754,-144.36369 -4.39912,-5.48289 -8.62105,-10.88289 -9.38207,-12 C 662.3553,259.414 704.12742,300.8 755.94325,352.5 c 51.81583,51.7 94.6395,95.07734 95.16371,96.3941 0.60841,1.52825 0.57635,9.03216 -0.0886,20.74852 C 846.74817,544.87813 825.18661,610.42407 784.4522,672 760.30134,708.50751 727.06374,743.94594 692.23933,770.31875 628.37636,818.68271 552.569,846.26301 470.5,850.99215 c -19.87896,1.14551 -20.38115,1.14605 -23,0.0247 z"
      />
    </svg>
  );
}
