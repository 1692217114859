const rowClassNames = {
  DRAFT: "draft-row",
  PUBLISHED: "published-row",
  ACTIVE: "active-row",
  COMPLETED: "completed-row",
  CANCELLED: "cancelled-row",
};
export function EventWrapper(props: any) {
  return (
    <div className={`${rowClassNames[props.event.status]}  cursor-pointer`}>
      {props.children}
    </div>
  );
}
