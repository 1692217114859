import { Button, Descriptions, Divider, Spin, Typography } from "antd";
import { useParams } from "react-router-dom";
import { TITLE, BASE_PATH } from ".";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import { useEffect, useMemo, useRef, useState } from "react";
import service from "@services/deviceGroup";
import deviceService from "@services/device";
import campaignService from "@services/campaign";
import { useNavigate } from "react-router-dom";
import useCalendarComponents from "@hooks/useCalendarComponents";
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";

import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { titleCase } from "title-case";
import { DevicesTable } from "@views/Clients/View";
const { Title } = Typography;

function View(props: any) {
  const calendarProps = useCalendarComponents();

  const navigate = useNavigate();
  const { id } = useParams();
  const setBreadcrumbs = useBreadcrumbs();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState<any[]>([]);
  const [devicesLoading, setDevicesLoading] = useState(false);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [events, setEvents] = useState<any[]>([]);
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "View Device" },
  ]);
  useEffect(() => {
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const handleForm = (entityId?: string) => {
    if (entityId) {
      navigate(`${BASE_PATH}/edit?entity=${entityId}`);
    } else {
      navigate(`${BASE_PATH}/new`);
    }
  };
  useEffect(() => {
    if (id) {
      setLoading(true);
      service
        .get(id)
        .then((res) => {
          setData(res);
          onDevicesRefresh(id);
          onEventsRefresh(id);
          breadcrumbsRef.current = [
            { label: TITLE[1], url: BASE_PATH },
            { label: res.name },
          ];
          setBreadcrumbs(breadcrumbsRef.current);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);
  const onDevicesRefresh = (id: string) => {
    setDevicesLoading(true);
    deviceService
      .getAllForDeviceGroup(id)
      .then((res) => {
        setDevices(res);
      })
      .finally(() => {
        setDevicesLoading(false);
      });
  };
  const onEventsRefresh = (id: string) => {
    setEventsLoading(true);
    campaignService
      .getCampaignsForGroups([id])
      .then((res) => {
        setEvents(res);
      })
      .finally(() => {
        setEventsLoading(false);
      });
  };
  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  );

  return (
    <Spin spinning={loading}>
      <div className="flex justify-between">
        <Title level={3}>
          {data?.name} ({titleCase(data?.orientation?.toLowerCase() || "")})
        </Title>
        <Button icon={<EditOutlined />} onClick={() => handleForm(id)}>
          Edit
        </Button>
      </div>
      <div className="bg-white rounded-lg p-3 mt-3">
        <div>
          <span className="font-semibold">Registered Screens</span>
        </div>
        <Divider className="my-2" />
        <DevicesTable
          data={devices}
          loading={devicesLoading}
          onRefresh={() => onDevicesRefresh(id)}
          hiddenColumns={["group"]}
          isClientActive
        />
      </div>
      <div className="bg-white rounded-lg p-3 mt-3">
        <div>
          <span className="font-semibold">Schedule Information</span>
        </div>
        <Divider className="my-2" />
        <Calendar
          {...calendarProps}
          views={{ day: true, week: true, month: true, agenda: true }}
          defaultDate={defaultDate}
          defaultView={Views.AGENDA}
          onSelectEvent={(event: any) =>
            window.open(`/campaigns/${event.campaignId}`, "_blank")
          }
          events={events
            ?.map((e) =>
              e.slots?.map((slot) => ({
                ...slot,
                start: new Date(slot.start),
                end: new Date(slot.end),
                status: e.status,
                campaignId: e._id,
              }))
            )
            .reduce((acc, item) => {
              acc.push(...item);
              return acc;
            }, [])}
          selectable
          style={{ height: 600 }}
        />
      </div>
    </Spin>
  );
}
export default View;
