import { Button, Descriptions, Divider, Spin, Typography } from "antd";
import { useParams } from "react-router-dom";
import { TITLE, BASE_PATH } from ".";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import { useEffect, useRef, useState } from "react";
import service from "@services/device";
import { useNavigate } from "react-router-dom";

import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
const { Title } = Typography;

function View(props: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const setBreadcrumbs = useBreadcrumbs();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "View Device" },
  ]);
  useEffect(() => {
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const handleForm = (entityId?: string) => {
    if (entityId) {
      navigate(`${BASE_PATH}/edit?entity=${entityId}`);
    } else {
      navigate(`${BASE_PATH}/new`);
    }
  };
  useEffect(() => {
    if (id) {
      setLoading(true);
      service
        .getForClient(id)
        .then((res) => {
          setData(res);
          breadcrumbsRef.current = [
            { label: TITLE[1], url: BASE_PATH },
            { label: res.name },
          ];
          setBreadcrumbs(breadcrumbsRef.current);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);
  return (
    <Spin spinning={loading}>
      <div className="flex justify-between">
        <Title level={3}>{data?.macAddress}</Title>
        <Button icon={<EditOutlined />} onClick={() => handleForm(id)}>
          Edit
        </Button>
      </div>

      <div className="bg-white rounded-lg p-3">
        <div>
          <span className="font-semibold">Device Information</span>
        </div>
        <Divider className="my-2" />
        <div>
          <Descriptions
            size="small"
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="MAC Address">
              {data?.macAddress}
            </Descriptions.Item>
            <Descriptions.Item label="Vendor Name">
              {data?.vendorName}
            </Descriptions.Item>
            <Descriptions.Item label="Vendor ID">
              {data?.vendorId}
            </Descriptions.Item>
            <Descriptions.Item label="Vendor Product ID">
              {data?.vendorProductId}
            </Descriptions.Item>
            <Descriptions.Item label="Product ID">
              {data?.productId}
            </Descriptions.Item>
            <Descriptions.Item label="Launcher Version">
              {data?.launcherVersion}
            </Descriptions.Item>
            <Descriptions.Item label="Remote">{data?.remote}</Descriptions.Item>
            <Descriptions.Item label="Remote">{data?.remote}</Descriptions.Item>
            <Descriptions.Item label="OS Version">
              {data?.osVersion}
            </Descriptions.Item>
            <Descriptions.Item label="Platform Name">
              {data?.platformName}
            </Descriptions.Item>
            <Descriptions.Item label="Content Type">
              {data?.contentType}
            </Descriptions.Item>
            <Descriptions.Item label="Stage">{data?.stage}</Descriptions.Item>
            <Descriptions.Item label="Flag">{data?.flag}</Descriptions.Item>
            <Descriptions.Item label="Max Resolution">
              {data?.maxResolution}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
      {data?.client && (
        <div className=" rounded-lg p-3 mt-2">
          <div>
            <span className="font-semibold">Client Information</span>
          </div>
          <Divider className="my-2" />
          <div>
            <Descriptions
              size="small"
              bordered
              column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label="Stage">
                {data?.client?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Flag">
                {data?.client?.code}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      )}
    </Spin>
  );
}
export default View;
