import service from "@services/client";
import {
  Alert,
  Button,
  Col,
  Divider,
  Empty,
  Modal,
  Row,
  Segmented,
  Tag,
  message,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import deviceService from "@services/device";
import { useSelector } from "react-redux";
import { Store } from "@store/index";
import dayjs, { Dayjs } from "dayjs";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js/auto";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DEFAULT_OPTIONS: any = {
  datasets: {
    line: {
      borderWidth: 1.5,
      pointBackgroundColor: "transparent",
      pointBorderWidth: 0,
    },
  },
  interaction: {
    mode: "index",
    intersect: false,
  },
  // Hide Legend
  plugins: {
    legend: {
      display: false,
    },
  },
};
export default function TelemetryModal(props: any) {
  const { data, status } = props;
  const [cpuData, setCpuData] = useState({ labels: [], datasets: [] });
  const [ramData, setRamData] = useState({ labels: [], datasets: [] });
  const [diskData, setDiskData] = useState({ labels: [], datasets: [] });
  const [latencyData, setLatencyData] = useState({ labels: [], datasets: [] });
  const [historicalTelemetry, setHistoricalTelemetry] = useState([]);
  const [duration, setDuration] = useState<any>("All");
  const telemetry = useSelector(
    (state: Store) => state.telemetry.metrics[data?.deviceId]
  );
  useEffect(() => {
    if (!data || !data.deviceId) {
      console.log("No device id found");

      setCpuData({ labels: [], datasets: [] });
      setRamData({ labels: [], datasets: [] });
      setLatencyData({ labels: [], datasets: [] });
      setDiskData({ labels: [], datasets: [] });
      setHistoricalTelemetry([]);
      return () => {};
    }
    deviceService.subscribeToEvent(data?.deviceId, "TELEMETRY");
    deviceService.getTelemetry(data?.deviceId).then((res) => {
      if (res?.length > 0) {
        setHistoricalTelemetry(res.map((r) => ({ ...r, ...r.payload })));
      }
    });
  }, [data?.deviceId]);
  useEffect(() => {
    if (historicalTelemetry?.length > 0) {
      setCpuData(getHistoricalCpuData());
      setRamData(getHistoricalRamData());
      setLatencyData(getHistoricalLatencyData());
      setDiskData(getHistoricalDiskData());
    }
  }, [historicalTelemetry]);

  const getHistoricalDiskData = useCallback(() => {
    const historyDiskData = {
      labels: [
        ...(historicalTelemetry
          ?.map((metric) => metric.timestamp)
          .map((t) => dayjs(t).format("hh:mm A")) || []),
      ],
      datasets: [
        {
          data: [
            ...(historicalTelemetry?.map(
              (p) => (p.disk.used * 100) / p.disk.total
            ) || []),
          ],
        },
      ],
    };
    return historyDiskData;
  }, [historicalTelemetry]);
  const getHistoricalCpuData = useCallback(() => {
    const historyCpuData = {
      labels: [
        ...(historicalTelemetry
          ?.map((metric) => metric.timestamp)
          .map((t) => dayjs(t).format("hh:mm A")) || []),
      ],
      datasets: [
        {
          data: [
            ...(historicalTelemetry?.map((p) => p.cpu.averageLoadPercentage) ||
              []),
          ],
        },
      ],
    };
    return historyCpuData;
  }, [historicalTelemetry]);

  const getHistoricalRamData = useCallback(() => {
    const historyRamData = {
      labels: [
        ...(historicalTelemetry
          ?.map((metric) => metric.timestamp)
          .map((t) => dayjs(t).format("hh:mm A")) || []),
      ],
      datasets: [
        {
          data: [
            ...(historicalTelemetry?.map(
              (p) => (p.ram.used * 100) / p.ram.total
            ) || []),
          ],
        },
      ],
    };
    return historyRamData;
  }, [historicalTelemetry]);

  const getHistoricalLatencyData = useCallback(() => {
    const historyLatencyData = {
      labels: [
        ...(historicalTelemetry
          ?.map((metric) => metric.timestamp)
          .map((t) => dayjs(t).format("hh:mm A")) || []),
      ],
      datasets: [
        {
          data: [
            ...(historicalTelemetry
              ?.map((r) => r.payload)
              .map((p) => p.pingTime) || []),
          ],
        },
      ],
    };
    return historyLatencyData;
  }, [historicalTelemetry]);
  const getFilterFunction = useCallback(
    (duration: string) => {
      if (duration === "All") {
        return (_) => true;
      }
      const durationInHours = {
        "30m": 0.5,
        "1h": 1,
        "3h": 3,
        "5h": 5,
      };

      return (metric) =>
        dayjs().diff(dayjs(metric.timestamp), "hours", true) <=
        durationInHours[duration];
    },
    [duration]
  );
  useEffect(() => {
    const comprehensiveTelemetry = [
      ...historicalTelemetry,
      ...(telemetry || []),
    ];

    const cpuData = {
      labels: comprehensiveTelemetry
        ?.filter(getFilterFunction(duration))
        .map((metric) => metric.timestamp)
        .map((t) => dayjs(t).format("hh:mm A")),
      datasets: [
        {
          label: "",
          data: comprehensiveTelemetry
            ?.filter(getFilterFunction(duration))
            .map((metric) => metric.cpu.averageLoadPercentage),
        },
      ],
    };

    setCpuData({
      labels: [...(cpuData.labels || [])],
      datasets: [
        {
          data: [...(cpuData.datasets[0].data || [])],
        },
      ],
    });

    const ramData = {
      labels: comprehensiveTelemetry
        ?.filter(getFilterFunction(duration))
        .map((metric) => metric.timestamp)
        .map((t) => dayjs(t).format("hh:mm A")),
      datasets: [
        {
          label: "",
          data: comprehensiveTelemetry
            ?.filter(getFilterFunction(duration))
            .map((metric) => (metric.ram.used * 100) / metric.ram.total),
        },
      ],
    };

    setRamData({
      labels: [...(ramData.labels || [])],
      datasets: [
        {
          data: [...(ramData.datasets[0].data || [])],
        },
      ],
    });

    const latencyData = {
      labels: comprehensiveTelemetry
        ?.filter(getFilterFunction(duration))
        .map((metric) => metric.timestamp)
        .map((t) => dayjs(t).format("hh:mm A")),
      datasets: [
        {
          label: "",
          data: comprehensiveTelemetry
            ?.filter(getFilterFunction(duration))
            .map((metric) => metric.pingTime),
        },
      ],
    };

    setLatencyData({
      labels: [...(latencyData.labels || [])],
      datasets: [
        {
          data: [...(latencyData.datasets[0].data || [])],
        },
      ],
    });

    const diskData = {
      labels: comprehensiveTelemetry
        ?.filter(getFilterFunction(duration))
        .map((metric) => metric.timestamp)
        .map((t) => dayjs(t).format("hh:mm A")),
      datasets: [
        {
          label: "",
          data: comprehensiveTelemetry
            ?.filter(getFilterFunction(duration))
            .map((metric) => (metric.disk.used * 100) / metric.disk.total),
        },
      ],
    };

    setDiskData({
      labels: [...(diskData.labels || [])],
      datasets: [
        {
          data: [...(diskData.datasets[0].data || [])],
        },
      ],
    });
  }, [telemetry, duration]);
  const uptime = telemetry
    ? Math.round(+telemetry?.[telemetry.length - 1].uptime / (10000 * 60))
    : Math.round(
        +historicalTelemetry?.[historicalTelemetry.length - 1]?.uptime /
          (10000 * 60)
      );
  const appDisk = telemetry
    ? +telemetry?.[telemetry.length - 1].appDisk.used / (1024 * 1024)
    : +historicalTelemetry?.[historicalTelemetry.length - 1]?.appDisk.used /
      (1024 * 1024);
  const uptimeRender =
    uptime > 1440
      ? `${(uptime / 1440).toFixed(2)} days`
      : uptime > 60
      ? `${(uptime / 60).toFixed(2)} hours`
      : `${uptime.toFixed(2)} minutes`;

  const appDiskRender =
    appDisk > 1024
      ? `${(appDisk / 1024).toFixed(2)} GB`
      : `${appDisk.toFixed(2)} MB`;
  return (
    <div>
      <Modal
        {...props}
        title={"Screen Statistics"}
        bodyStyle={{ width: 900 }}
        footer={null}
      >
        <div className="mb-4 flex flex-col gap-y-2">
          <div className="flex items-baseline gap-x-2">
            <span className="font-semibold">Status:</span>
            <span>
              {status?.isConnected ? (
                <Tag color="green" className="flex items-center gap-x-1">
                  <span className="bg-green-500 p-1 rounded-full"></span>
                  Online
                </Tag>
              ) : (
                <Tag color="red" className="flex items-center gap-x-1">
                  <span className="bg-red-500 p-1 rounded-full"></span>
                  Offline
                </Tag>
              )}
            </span>
          </div>
          <div>
            <span className="font-semibold">App Files & Folders Size: </span>
            <span>{appDiskRender} </span>
          </div>
          {status?.isConnected && (
            <>
              <div>
                <span className="font-semibold">Uptime: </span>
                <span>{uptimeRender} </span>
              </div>
              <div>
                <span className="font-semibold">Duration: </span>
                <span>
                  <Segmented
                    options={["30m", "1h", "3h", "5h", "All"]}
                    value={duration}
                    onChange={setDuration}
                  />
                </span>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-2">
            <div className="w-full border rounded p-1">
              <div className="text-xs flex justify-between items-center">
                <span>RAM Usage</span>
              </div>
              <Divider className="my-1" />
              {ramData?.datasets?.length === 0 ? (
                <Empty />
              ) : (
                <Line
                  options={{
                    ...DEFAULT_OPTIONS,
                    scales: {
                      x: {
                        title: {
                          display: true,
                          text: "Time",
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: "RAM Usage (%)",
                        },
                        min: 0,
                        max: 100,
                      },
                    },
                  }}
                  datasetIdKey="id"
                  data={ramData}
                />
              )}
            </div>
            <div className="w-full border rounded p-1">
              <div className="text-xs flex justify-between items-center">
                <span>Disk Usage</span>
              </div>
              <Divider className="my-1" />
              {ramData?.datasets?.length === 0 ? (
                <Empty />
              ) : (
                <Line
                  options={{
                    ...DEFAULT_OPTIONS,
                    scales: {
                      x: {
                        title: {
                          display: true,
                          text: "Time",
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: "Disk Usage (%)",
                        },
                        min: 0,
                        max: 100,
                      },
                    },
                  }}
                  datasetIdKey="id"
                  data={diskData}
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-2">
            <div className="w-full lg:w-1/2 border rounded p-1">
              <div className="text-xs">CPU Load</div>
              <Divider className="my-1" />

              {cpuData?.datasets?.length === 0 ? (
                <Empty />
              ) : (
                <Line
                  options={{
                    ...DEFAULT_OPTIONS,
                    scales: {
                      x: {
                        title: {
                          display: true,
                          text: "Time",
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: "CPU Load (%)",
                        },
                        min: 0,
                        max: 100,
                      },
                    },
                  }}
                  datasetIdKey="id"
                  data={cpuData}
                />
              )}
            </div>
            <div className="w-full lg:w-1/2 border rounded p-1">
              <div className="text-xs">Latency</div>
              <Divider className="my-1" />
              {latencyData?.datasets?.length === 0 ? (
                <Empty />
              ) : (
                <Line
                  options={{
                    ...DEFAULT_OPTIONS,
                    scales: {
                      x: {
                        title: {
                          display: true,
                          text: "Time",
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: "Latency (ms)",
                        },
                      },
                    },
                  }}
                  datasetIdKey="id"
                  data={latencyData}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
