import service from "@services/folder";
import assetService from "@services/asset";
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Drawer,
  Empty,
  Form,
  Input,
  Modal,
  Progress,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import * as dashboardActions from "@store/dashboardSlice";
import {
  FolderAddOutlined,
  ArrowLeftOutlined,
  FolderFilled,
  UploadOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { MdOutlineDriveFileMove } from "react-icons/md";
import { BASE_PATH, ProcessingStatusBadge } from ".";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import { useNavigate, useSearchParams } from "react-router-dom";
import EventForm from "./Form";
import qs from "qs";
import getThumbnailUrl from "@utils/getThumbnailUrl";
import dayjs from "dayjs";
import { Store } from "@store/index";
import { useDispatch, useSelector } from "react-redux";
import TotalUsage from "@components/TotalUsage";

const { Title, Paragraph } = Typography;
const { confirm } = Modal;
const CreateFolderModal = (props: any) => {
  const [form] = Form.useForm();
  return (
    <Modal {...props} footer={null}>
      <Form form={form} onFinish={(values) => props.onOk(values)}>
        <Form.Item
          label="Folder Name"
          name="name"
          rules={[
            { required: true, message: "Folder Name is required" },
            {
              validator: async (_, value) => {
                const response = await service.checkFolderName(
                  props.parent,
                  value?.trim()
                );
                return response.exists
                  ? Promise.reject("Folder already exists")
                  : Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Divider className="mb-2 mt-4" />
        <Form.Item noStyle>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

function MoveModal(props: any) {
  const [path, setPath] = useState<any[]>(props.path);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [parent, setParent] = useState<any>();
  const [newFolderModalVisible, setNewFolderModalVisible] = useState(false);
  useEffect(() => {
    setParent(path[path.length - 1]);
  }, [path]);

  useEffect(() => {
    if (parent) {
      handleRefresh(parent);
    }
  }, [parent, props.open]);

  const handleLoadFolders = async (parent?: string) => {
    const children = await service.getChildren(parent);
    setData(children);
  };

  const handleRefresh = async (parent) => {
    try {
      setLoading(true);
      const promises = [handleLoadFolders(parent?._id)];
      await Promise.all(promises);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (props.open) {
      setPath(props.path);
    }
  }, [props.open]);
  const handleMove = async () => {
    try {
      setLoading(true);
      if (props.data.isFolder) {
        await service.move({
          id: props.data._id,
          parent: parent._id,
        });
      } else {
        await assetService.move({
          id: props.data._id,
          parent: parent._id,
        });
      }
      props.onCancel(false);
      props.onRefresh();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      destroyOnClose
      {...props}
      footer={[
        <div>
          <Button
            type="link"
            icon={<FolderAddOutlined />}
            onClick={() => setNewFolderModalVisible(true)}
          >
            New Folder
          </Button>
        </div>,
        <div className="flex gap-x-1">
          <Button type="default" onClick={() => props.onCancel(false)}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleMove} loading={loading}>
            Move to {path[path.length - 1]?.name || "Home"}
          </Button>
        </div>,
      ]}
    >
      <CreateFolderModal
        title="Create a New Folder"
        onOk={(values) => {
          props.onNewFolder(values);
          setNewFolderModalVisible(false);
          handleRefresh(parent);
        }}
        onCancel={() => setNewFolderModalVisible(false)}
        open={newFolderModalVisible}
        destroyOnClose
        parent={parent?._id}
      />
      <div className="flex gap-x-2 items-center">
        Current location:{" "}
        <Button
          type="default"
          onClick={() => {
            setPath(props.path);
          }}
          icon={
            <FolderFilled
              style={{ fontSize: "1rem" }}
              className="text-blue-400"
            />
          }
        >
          {props.path[props.path.length - 1]?.name}
        </Button>
      </div>
      <div className="flex gap-x-2 mt-4 items-center">
        {path.length > 1 && (
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => setPath(path.slice(0, -1))}
            type="link"
          />
        )}
        <span className="text-lg">{path[path.length - 1]?.name || "Home"}</span>
      </div>
      <Divider className="mb-2 mt-1" />
      <div
        className="flex flex-wrap gap-x-2 gap-y-2 mb-6"
        style={{ maxWidth: 600, minWidth: 600 }}
      >
        {data.length > 0 ? (
          data
            .filter((folder) => props.data?._id !== folder._id)
            .map((folder) => (
              <div
                className="flex gap-x-2  cursor-pointer flex-col bg-white p-2 border rounded-lg"
                onDoubleClick={() => {
                  setPath([...path, { _id: folder._id, name: folder.name }]);
                }}
              >
                <FolderFilled
                  style={{ fontSize: "4rem" }}
                  className="text-blue-400"
                />
                <span>{folder.name}</span>
              </div>
            ))
        ) : (
          <div className="w-full items-center justify-center bg-gray-50 p-5">
            <Empty
              description={
                <span>
                  No folders present in <br />
                  <FolderFilled
                    className="text-blue-400"
                    style={{ fontSize: "1rem" }}
                  />{" "}
                  {path[path.length - 1]?.name || "Home"}
                </span>
              }
            />
          </div>
        )}
      </div>
    </Modal>
  );
}
function getDefaultPath(searchParams) {
  const parsed = qs.parse(searchParams.get("path"));
  const defaultAcc: any =
    Object.keys(parsed).length === 0 ? [{ _id: "null", name: "Home" }] : [];
  const payload = Object.keys(parsed).reduce(
    (acc, key) => [
      ...acc,
      {
        _id: (parsed as any)[key]._id,
        name: (parsed as any)[key].name,
      },
    ],
    defaultAcc
  );
  return payload;
}

function Folders(props: any) {
  const setDefaultBreadcrumbs = useBreadcrumbs();
  const breadcrumbsRef = useRef([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [breadcrumbs, setBreadcrumbs] = useState(getDefaultPath(searchParams));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hover, setHover] = useState<string | undefined>();
  const dashboardState = useSelector((state: Store) => state.dashboard);
  const [path, setPath] = useState<any[]>(getDefaultPath(searchParams));
  const [uploadAssetsVisible, setUploadAssetsVisible] = useState(false);
  const [data, setData] = useState([]);
  const [assets, setAssets] = useState([]);
  const [parent, setParent] = useState(breadcrumbs[breadcrumbs.length - 1]);
  const [loading, setLoading] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [newFolderModalVisible, setNewFolderModalVisible] = useState(false);
  const [moveModalVisible, setMoveModalVisible] = useState(false);
  const [editing, setEditing] = useState("");
  const [editedName, setEditedName] = useState("");
  const [selectedRow, setSelectedRow] = useState<any>();
  useEffect(() => {
    setDefaultBreadcrumbs(breadcrumbsRef.current);
  }, []);
  useEffect(() => {
    setBreadcrumbs(path.map((p) => ({ _id: p._id, name: p.name })));

    setParent(path[path.length - 1]);
    setSearchParams({ path: qs.stringify(path) });
  }, [path]);
  useEffect(() => {
    const parsedPath: any = qs.parse(searchParams.get("path"));

    const path = Object.keys(parsedPath).reduce(
      (acc, key) => [
        ...acc,
        { _id: parsedPath[key]._id, name: parsedPath[key].name },
      ],
      []
    );

    const implicitParent = path[path.length - 1];
    if (implicitParent && parent && implicitParent?._id !== parent?._id) {
      setPath(path);
    }
  }, [searchParams]);

  useEffect(() => {
    if (parent) {
      handleRefresh(parent);
    }
  }, [parent]);

  const handleRefresh = async (parent) => {
    try {
      setLoading(true);
      const promises = [
        handleLoadFolders(parent?._id),
        handleLoadAssets(parent?._id),
      ];
      dispatch(dashboardActions.getUsageAsync({}));
      await Promise.all(promises);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleLoadAssets = async (parent?: string) => {
    const assets = await assetService.getByFolder(parent);
    setAssets(assets);
  };
  const handleLoadFolders = async (parent?: string) => {
    const children = await service.getChildren(parent);
    setData(children);
  };
  const handleNewFolder = useCallback(
    (values) => {
      service
        .create({ ...values, parent: parent._id })
        .then(() => {
          handleLoadFolders(parent._id);
        })
        .catch(() => {})
        .finally(() => {
          setNewFolderName("");
          setNewFolderModalVisible(false);
        });
    },
    [parent, newFolderName]
  );
  const handleView = (entityId?: string) => {
    navigate(`${BASE_PATH}/${entityId}`);
  };
  const handleMove = (record) => {
    setMoveModalVisible(true);
    setSelectedRow(record);
  };
  const handleDelete = (record) => {
    confirm({
      title: "Are you sure you want to delete this folder?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone",
      onOk() {
        record.isFolder
          ? service.remove(record._id).then((_) => handleRefresh(parent))
          : assetService.remove(record._id).then((_) => handleRefresh(parent));
      },
      onCancel() {},
    });
  };
  const handleRename = () => {
    service
      .rename(editing, editedName)
      .then(() => {
        setEditing("");
        setEditedName("");
        handleRefresh(parent);
      })
      .catch(() => {});
  };

  return (
    <div className="h-full">
      <MoveModal
        parent={parent}
        path={path}
        open={moveModalVisible}
        onCancel={() => setMoveModalVisible(false)}
        onRefresh={() => handleRefresh(parent)}
        title={`Move "${selectedRow?.displayName || selectedRow?.name}"`}
        data={selectedRow}
        destroyOnClose
        onNewFolder={handleNewFolder}
      />

      <Drawer
        open={uploadAssetsVisible}
        onClose={() => {
          setUploadAssetsVisible(false);
        }}
        title="Upload Media Files"
        size="large"
        className="upload-assets-drawer"
        bodyStyle={{ padding: 0 }}
      >
        <EventForm
          folder={parent?._id}
          onSubmit={() => {
            setUploadAssetsVisible(false);
            handleRefresh(parent);
          }}
          onCancel={() => {
            setUploadAssetsVisible(false);
          }}
        />
      </Drawer>
      <CreateFolderModal
        title="Create a New Folder"
        onOk={(values) => handleNewFolder(values)}
        onCancel={() => setNewFolderModalVisible(false)}
        open={newFolderModalVisible}
        destroyOnClose
        parent={parent?._id}
      />
      <div className="flex flex-col justify-between ">
        <div>
          <div className="mb-4">
            <Breadcrumb>
              {breadcrumbs.map((b) => (
                <Breadcrumb.Item>
                  <a
                    onClick={() => {
                      const index = path.findIndex((p) => p._id === b._id);
                      setPath(path.slice(0, index + 1));
                    }}
                  >
                    {b.name}
                  </a>
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </div>
          <Row justify="space-between">
            <Col>
              <Title level={3}>{parent?.name}</Title>
            </Col>
            <Col></Col>
            <Col>
              <Space>
                <Button
                  icon={<SyncOutlined />}
                  onClick={() => handleRefresh(parent)}
                />
                <Button
                  type="link"
                  icon={<FolderAddOutlined />}
                  onClick={() => setNewFolderModalVisible(true)}
                >
                  New Folder
                </Button>
                <Button
                  type="primary"
                  icon={<UploadOutlined />}
                  onClick={() => setUploadAssetsVisible(true)}
                >
                  Upload Media Files
                </Button>
              </Space>
            </Col>
          </Row>
          <div className="flex gap-x-2 flex-col gap-y-4">
            <Table
              loading={loading}
              pagination={false}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: editing
                    ? () => {}
                    : record.isFolder
                    ? () => {
                        setPath([
                          ...path,
                          { _id: record._id, name: record.name },
                        ]);
                      }
                    : () => {},
                  onContextMenu: (event) => {},
                  onMouseEnter: (event) => {
                    setHover(record._id);
                  },
                  onMouseLeave: (event) => {
                    setHover(undefined);
                  },
                };
              }}
              columns={[
                {
                  ellipsis: true,
                  title: "Name",
                  dataIndex: "name",
                  key: "name",
                  width: "50%",
                  render: (text: string, record: any) => (
                    <div className="flex gap-x-1 items-center w-full select-none">
                      {record.isFolder ? (
                        <>
                          <div className="flex gap-x-1 items-center w-full cursor-pointer">
                            <FolderFilled
                              style={{ fontSize: "2rem" }}
                              className="text-blue-400"
                            />
                            {editing === record._id ? (
                              <div className="flex items-center gap-x-4">
                                <Input
                                  autoFocus
                                  defaultValue={text}
                                  onChange={(e) =>
                                    setEditedName(e.target.value)
                                  }
                                />
                                <div className="flex gap-x-2 items-center">
                                  <a onClick={() => handleRename()}>
                                    <CheckOutlined className="text-green-500" />
                                  </a>
                                  <a onClick={() => setEditing("")}>
                                    <CloseOutlined className="text-red-500" />
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <Paragraph className="w-full mb-0 ml-2" ellipsis>
                                {text}
                              </Paragraph>
                            )}
                          </div>
                          <div></div>
                        </>
                      ) : (
                        <div className="flex gap-x-1 items-center w-full justify-between">
                          <div className="flex gap-x-1 items-center w-full">
                            <Tooltip
                              placement="right"
                              title={
                                <img
                                  src={getThumbnailUrl(
                                    record.mimeType,
                                    record.processedUrls
                                  )}
                                  style={{
                                    height: "300px",
                                    width: "300px",
                                    objectFit: "cover",
                                    maxWidth: "unset",
                                  }}
                                />
                              }
                            >
                              <img
                                src={getThumbnailUrl(
                                  record.mimeType,
                                  record.processedUrls
                                )}
                                style={{
                                  height: "32px",
                                  width: "32px",
                                  objectFit: "cover",
                                }}
                              />
                            </Tooltip>
                            <div className="flex justify-between items-center"></div>
                            {editing === record._id ? (
                              <div className="flex items-center gap-x-4">
                                <Input
                                  autoFocus
                                  defaultValue={record.displayName}
                                  onChange={(e) =>
                                    setEditedName(e.target.value)
                                  }
                                />
                                <div className="flex gap-x-2 items-center">
                                  <a onClick={() => handleRename()}>
                                    <CheckOutlined className="text-green-500" />
                                  </a>
                                  <a onClick={() => setEditing("")}>
                                    <CloseOutlined className="text-red-500" />
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <Paragraph className="w-full mb-0 ml-2" ellipsis>
                                {record.displayName}
                              </Paragraph>
                            )}
                          </div>
                          <div className="flex">
                            <Tag>
                              {record.linkedCompositions.length} Compositions
                            </Tag>
                            <ProcessingStatusBadge
                              record={record}
                              status={record.status}
                              isLoading={false}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  title: "Type",
                  dataIndex: "mimeType",
                  key: "mimeType",
                  render: (text: string, record: any) => (
                    <span>{text || "Folder"}</span>
                  ),
                },
                {
                  title: "Size",
                  dataIndex: "size",
                  key: "size",
                  render: (text: number) => (
                    <span>{(text / 1000000).toFixed(2)} MB</span>
                  ),
                },
                {
                  title: "Date Modified",
                  dataIndex: "updatedAt",
                  key: "updatedAt",
                  render: (text: string, record: any) => (
                    <span>{dayjs(text).format("MMM DD, YYYY")}</span>
                  ),
                },
                {
                  title: "Location",
                  dataIndex: "parent",
                  key: "parent",
                  render: (text: any, record: any) => (
                    <div className="flex justify-between items-center">
                      <div>{text?.name || "Home"}</div>
                      {hover === record._id && (
                        <div className="flex gap-x-2 items-center">
                          <Tooltip title="Move">
                            <Button
                              onClick={() => handleMove(record)}
                              type="link"
                              icon={
                                <MdOutlineDriveFileMove className="text-lg" />
                              }
                            />
                          </Tooltip>
                          <Tooltip title="Rename">
                            <Button
                              onClick={() => setEditing(record._id)}
                              type="link"
                              icon={<EditOutlined />}
                            />
                          </Tooltip>
                          <Tooltip title="Delete">
                            <Button
                              disabled={
                                record.linkedCompositions?.length > 0 ||
                                record.assets?.length > 0 ||
                                record.subFolders?.length > 0
                              }
                              onClick={() => handleDelete(record)}
                              type="link"
                              icon={<DeleteOutlined />}
                            />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  ),
                },
              ]}
              size="small"
              dataSource={[
                ...data.map((f) => ({ ...f, isFolder: true })),
                ...assets,
              ]}
            />
          </div>
        </div>
        <div className="w-1/4 mt-3">
          <TotalUsage />
        </div>
      </div>
    </div>
  );
}

export default Folders;
