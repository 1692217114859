import { Routes, Route } from "react-router-dom";

import { Tag } from "antd";
import ListEntities from "./List";

export const MODEL = "layout";
export const BASE_PATH = "/layouts";
export const TITLE = ["Layout", "Layouts"];

export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/`} element={<ListEntities />} />
    </Routes>
  );
}
