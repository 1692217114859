import getThumbnailUrl from "@utils/getThumbnailUrl";
import { Button, Divider, Modal, Timeline, Tooltip } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import VideoPlayer from "./VideoPlayer";
import deviceService from "@services/device";
import { Store } from "@store/index";
import { useSelector } from "react-redux";
import assetService from "@services/asset";
import dayjs from "dayjs";
import Offline from "./Vectors/Offline";
function ProofOfPlayModal(props: any) {
  const { data, status } = props;
  const playerRef = useRef<HTMLDivElement>(null);
  const [asset, setAsset] = useState<any>(null);
  const [proofOfPlayData, setProofOfPlayData] = useState<any[]>([]);
  const [historicalPOPData, setHistoricalPOPData] = useState<any[]>([]);
  const proofOfPlay = useSelector(
    (state: Store) => state.proofOfPlay.metrics[data?.deviceId]
  );
  const [isInPlayback, setIsInPlayback] = useState(false);
  useEffect(() => {
    if (!data || !data.deviceId) {
      console.log("No device id found");
      setProofOfPlayData([]);
      return () => {};
    }
    deviceService.subscribeToEvent(data?.deviceId, "PROOF_OF_PLAY");
    deviceService.getProofOfPlay(data?.deviceId).then((res) => {
      if (res?.length > 0) {
        setHistoricalPOPData(res.map((r) => ({ ...r, ...r.payload })));
      }
    });
  }, [data?.deviceId]);
  useEffect(() => {
    let proofOfPlayData = [...historicalPOPData, ...(proofOfPlay || [])];

    const dates = new Set(
      proofOfPlayData
        .filter((pop) => !!pop.startedAt)
        .map((pop) => dayjs(pop.startedAt).format("YYYY-MM-DD"))
    );
    console.log(dates);
    proofOfPlayData = proofOfPlayData
      .map((pop, index) => {
        const next =
          index !== proofOfPlayData.length - 1
            ? proofOfPlayData[index + 1]
            : null;
        if (
          dayjs(pop.endedAt || pop.startedAt).get("date") !==
          dayjs(next?.endedAt || next?.startedAt).get("date")
        ) {
          return [
            pop,
            {
              isDateEdge: true,
              date: dayjs(pop.endedAt || pop.startedAt).format("YYYY-MM-DD"),
            },
          ];
        } else {
          return pop;
        }
      })
      .flat();
    if (
      proofOfPlayData.length > 0 &&
      !proofOfPlayData[proofOfPlayData.length - 1].date
    ) {
      proofOfPlayData.push({
        date: dayjs().format("YYYY-MM-DD"),
        isDateEdge: true,
      });
    }
    setProofOfPlayData([...proofOfPlayData]);
    const filteredOnlyStarted = proofOfPlayData.filter((p) => !!p.startedAt);
    const lastStarted = filteredOnlyStarted[filteredOnlyStarted.length - 1];
    if (lastStarted && !isInPlayback) {
      loadAndSetAsset(lastStarted.assetId);
    }
  }, [proofOfPlay, historicalPOPData, isInPlayback]);
  useEffect(() => {
    window.onresize = () => {
      if (playerRef.current) {
        console.log(
          playerRef.current.clientWidth,
          playerRef.current.clientHeight
        );
      }
    };
    return () => {
      window.onresize = null;
    };
  }, []);
  const loadAndSetAsset = (assetId: string) => {
    try {
      assetService.get(assetId).then((res) => {
        setAsset(res);
      });
    } catch (error) {}
  };
  const containsPOPData = proofOfPlayData.some((pop) => !!pop.assetId);

  const showDeviceOffline = status && !status.isConnected && !isInPlayback;
  const noHistoryPresent = status && !containsPOPData && isInPlayback;
  const showHistoricalAsset = status && isInPlayback && containsPOPData;
  const noAssetPlaying =
    status && status.isConnected && !containsPOPData && !isInPlayback;
  const showLiveData =
    status && status.isConnected && containsPOPData && !isInPlayback;

  console.log("showDeviceOffline", showDeviceOffline);
  console.log("noHistoryPresent", noHistoryPresent);
  console.log("showHistoricalAsset", showHistoricalAsset);
  console.log("noAssetPlaying", noAssetPlaying);
  console.log("showLiveData", showLiveData);

  const televisionStyle = useMemo(() => {
    if (asset && asset.dimension.width < asset.dimension.height) {
      return {
        height: 600,
        width: 337,
        borderWidth: "1rem",
      };
    } else {
      return {
        height: 337,
        width: 600,
        borderWidth: "1rem",
      };
    }
  }, [asset]);

  const renderTimeline = useCallback(() => {
    if (containsPOPData) {
      return (
        <Timeline reverse mode="alternate">
          {proofOfPlayData.map((pop, index) =>
            pop.isDateEdge ? (
              <Timeline.Item
                key={index}
                style={{ zIndex: 20 }}
                className="sticky top-0 py-6"
                dot={
                  <div className="bg-black text-white rounded-lg px-2 py-1 ">
                    {dayjs(pop.date).format("DD/MM/YYYY")}
                  </div>
                }
              ></Timeline.Item>
            ) : (
              <Timeline.Item
                key={index}
                color={pop.endedAt ? "red" : "green"}
                className="cursor-pointer"
              >
                <div
                  onClick={() => {
                    setIsInPlayback(true);
                    loadAndSetAsset(pop.assetId);
                  }}
                >
                  <div>
                    <span className="text-sm">
                      {pop.endedAt ? "Ended" : "Started"}{" "}
                      {pop.isSuspended && <span>(Suspended)</span>}
                    </span>
                  </div>
                  <div>
                    {pop.startedAt ? (
                      <span className="text-sm text-gray-400">
                        {pop.startedAt
                          ? dayjs(pop.startedAt).format("hh:mm A")
                          : ""}
                      </span>
                    ) : (
                      <span className="text-sm text-gray-400">
                        {pop.endedAt
                          ? dayjs(pop.endedAt).format("hh:mm A")
                          : ""}
                      </span>
                    )}
                  </div>
                </div>
              </Timeline.Item>
            )
          )}
        </Timeline>
      );
    }

    return <div className="bg-gray-100 h-full">No historical data present</div>;
  }, [containsPOPData, proofOfPlayData]);

  const renderTelevision = () => {
    if (showDeviceOffline) {
      return (
        <div
          className="rounded border-black bg-gray-800 shadow-2xl relative proof-of-play-modal__player_portrait"
          style={televisionStyle}
        >
          <div className="text-gray-400 text-lg flex justify-center h-full items-center ">
            This screen is not connected to the internet.
          </div>
          <div className="absolute left-0 bottom-0">
            <Offline />
          </div>
          <div
            className={`absolute right-2 bottom-2 bg-gray-700 text-gray-400 px-1.5 py-0.5 border-gray-400 border rounded-lg`}
            onClick={() => {
              const lastStarted = proofOfPlayData
                .reverse()
                .find((p) => !!p.assetId);
              setIsInPlayback(false);
              loadAndSetAsset(lastStarted.assetId);
            }}
          >
            Screen Offline
          </div>
        </div>
      );
    }
    if (noHistoryPresent) {
      return (
        <div
          className="rounded border-black bg-gray-800 shadow-2xl relative proof-of-play-modal__player_portrait"
          style={televisionStyle}
        >
          <div className="text-gray-400 text-lg flex justify-center h-full items-center ">
            This screen is not connected to the internet.
          </div>
          <div className="absolute left-0 bottom-0">
            <Offline />
          </div>
          <div
            className={`absolute right-2 bottom-2 bg-gray-700 text-gray-400 px-1.5 py-0.5 border-gray-400 border rounded-lg`}
            onClick={() => {
              const lastStarted = proofOfPlayData
                .reverse()
                .find((p) => !!p.assetId);
              setIsInPlayback(false);
              loadAndSetAsset(lastStarted.assetId);
            }}
          >
            Screen Offline
          </div>
        </div>
      );
    }
    if (showHistoricalAsset) {
      return (
        <div
          className="rounded border-black bg-gray-800 shadow-2xl relative proof-of-play-modal__player_portrait"
          style={televisionStyle}
        >
          {asset && (
            <div
              ref={playerRef}
              key={asset._id}
              className="bg-gray-800 flex items-center h-full"
            >
              {asset.mimeType.includes("image") ? (
                <img
                  src={getThumbnailUrl(asset.mimeType, asset.processedUrls)}
                  alt={asset.name}
                  className="w-full h-full object-contain"
                />
              ) : (
                <VideoPlayer
                  src={asset.originalUrl}
                  index={0}
                  currentSlide={0}
                />
              )}
            </div>
          )}
          <div
            className={`absolute right-2 bottom-2 bg-gray-700 text-red-500 px-1 py-0.5 border-red-500 border rounded-lg  cursor-pointer hover:bg-red-500 hover:text-white transition-all`}
            onClick={() => {
              const lastStarted = proofOfPlayData
                .reverse()
                .find((p) => !!p.assetId);
              setIsInPlayback(false);
              loadAndSetAsset(lastStarted.assetId);
            }}
          >
            Go Live
          </div>
        </div>
      );
    }
    if (noAssetPlaying) {
      return (
        <div
          className="rounded border-black bg-gray-800 shadow-2xl relative proof-of-play-modal__player_portrait"
          style={televisionStyle}
        >
          <div className="text-gray-400 text-lg flex justify-center h-full items-center ">
            No asset is currently playing on this screen.
          </div>
          <div className="absolute right-2 bottom-2 bg-red-500 text-white px-1 py-0.5 rounded-lg">
            LIVE
          </div>
        </div>
      );
    }
    if (showLiveData) {
      return (
        <div
          className="rounded border-black bg-gray-800 shadow-2xl relative proof-of-play-modal__player_portrait"
          style={televisionStyle}
        >
          {asset && (
            <div
              ref={playerRef}
              key={asset._id}
              className="bg-gray-800 flex items-center h-full"
            >
              {asset.mimeType.includes("image") ? (
                <img
                  src={getThumbnailUrl(asset.mimeType, asset.processedUrls)}
                  alt={asset.name}
                  className="w-full h-full object-contain"
                />
              ) : (
                <VideoPlayer
                  src={asset.originalUrl}
                  index={0}
                  currentSlide={0}
                />
              )}
            </div>
          )}
          <div
            className={`absolute right-2 bottom-2 bg-red-500 text-white px-1 py-0.5 rounded-lg`}
          >
            LIVE
          </div>
        </div>
      );
    }
  };

  return (
    status && (
      <Modal
        {...props}
        title="Live View"
        className="w-max"
        destroyOnClose
        footer={[<Button onClick={() => props.onCancel()}>Cancel</Button>]}
      >
        <div className="flex gap-x-8 items-center justify-between w-full h-full">
          <div className="w-3/5">{renderTelevision()}</div>
          <Divider type="vertical" className="" />
          <div
            className="py-2 w-2/5"
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              minWidth: "200px",
              maxHeight: "400px",
            }}
          >
            {renderTimeline()}
          </div>
        </div>
      </Modal>
    )
  );
}

export default ProofOfPlayModal;
