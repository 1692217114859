import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/client";
export const create = (payload) => postRequest(`${SERVICE_URL}`, payload);
export const update = (payload) => putRequest(`${SERVICE_URL}`, payload);
export const resetPassword = (payload) =>
  putRequest(`${SERVICE_URL}/reset-password`, payload);
export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);

export const activate = (id: string) =>
  putRequest(`${SERVICE_URL}/${id}/activate`);
export const suspend = (id: string) =>
  putRequest(`${SERVICE_URL}/${id}/suspend`);
export const get = (id) => getRequest(`${SERVICE_URL}/${id}`);
export const getAll = () => getRequest(`${SERVICE_URL}/all`);
export const getForDependents = (ids: string[]) =>
  postRequest(`${SERVICE_URL}/dependents`, ids);
export const toggleActive = (id: string) =>
  getRequest(`${SERVICE_URL}/${id}/toggle-active`);
export const checkCode = (name: string, id: string) =>
  getRequest(
    `${SERVICE_URL}/check-code?name=${encodeURIComponent(
      name
    )}&id=${encodeURIComponent(id)}`
  );
export const checkUsername = (name: string, id: string) =>
  getRequest(
    `${SERVICE_URL}/check-username?name=${encodeURIComponent(
      name
    )}&id=${encodeURIComponent(id)}`
  );
export const exportToXLSX = () => postBlobRequest(`${SERVICE_URL}/export`);
export const getDevicesForClient = (client: string) =>
  getRequest(`${SERVICE_URL}/${client}/devices`);
export const activateDeviceAccess = (id: string) =>
  putRequest(`${SERVICE_URL}/device/${id}/activate`);
export const suspendDeviceAccess = (id: string) =>
  putRequest(`${SERVICE_URL}/device/${id}/suspend`);
export default {
  create,
  update,
  paginate,
  get,
  getAll,
  getForDependents,
  toggleActive,
  checkCode,
  checkUsername,
  resetPassword,
  exportToXLSX,
  getDevicesForClient,
  activate,
  suspend,
  activateDeviceAccess,
  suspendDeviceAccess,
};
