import Android9 from "@components/Vectors/Android9";
import Android10 from "@components/Vectors/Android10";
import Android8 from "@components/Vectors/Android8";
import Android11 from "@components/Vectors/Android11";
import Android12 from "@components/Vectors/Android12";
import Android13 from "@components/Vectors/Android13";
import Android14 from "@components/Vectors/Android14";
import Android15 from "@components/Vectors/Android15";

export default function useAndroidIcon() {
  const getAndroidOSIcon = (osVersion) => {
    switch (osVersion.replace("Android TV ", "")) {
      case "9":
        return <Android9 />;
      case "10":
        return <Android10 />;
      case "8":
        return <Android8 />;
      case "11":
        return <Android11 />;
      case "12":
        return <Android12 />;
      case "13":
        return <Android13 />;
      case "14":
        return <Android14 />;
      case "15":
        return <Android15 />;
      default:
        return <Android10 />;
    }
  };
  return getAndroidOSIcon;
}
