import service from "@services/client";
import { Alert, Button, Modal, message } from "antd";
import { useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";

export default function ClientStatusModal(props: any) {
  const [loading, setLoading] = useState(false);
  const { data } = props;
  const handleSuspend = async () => {
    try {
      setLoading(true);
      await service.suspend(data._id);
      props.onRequestRefresh();
      message.success("Client suspended successfully");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleActivate = async () => {
    try {
      setLoading(true);
      await service.activate(data._id);
      props.onRequestRefresh();
      message.success("Client activated successfully");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Modal
        {...props}
        title={
          data?.isActive
            ? `Suspend Client: ${data?.name}`
            : `Activate Client: ${data?.name}`
        }
        footer={[
          <div className="flex gap-x-1">
            <Button>Cancel</Button>
            {data?.isActive ? (
              <Button type="primary" loading={loading} onClick={handleSuspend}>
                Suspend
              </Button>
            ) : (
              <Button type="primary" loading={loading} onClick={handleActivate}>
                Activate
              </Button>
            )}
          </div>,
        ]}
      >
        {data?.isActive ? (
          <>
            <p>Are you sure you want to suspend this client?</p>
            <Alert
              message="This will immediately suspend all screens under
          this client as well."
              type="warning"
              showIcon
            />
          </>
        ) : (
          <>
            <p>Are you sure you want to activate this client?</p>
            <Alert
              message="This will activate all screens under
          this client."
              type="info"
              showIcon
            />
          </>
        )}
      </Modal>
    </div>
  );
}
