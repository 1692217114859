import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const TYPE = "canvas";

export interface CanvasState {
  zoom: number;
}

const INITIAL_STATE: CanvasState = {
  zoom: 1,
};

const slice = createSlice({
  name: TYPE,
  initialState: INITIAL_STATE,
  reducers: {
    setZoom: (state, action) => {
      state.zoom = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setZoom } = slice.actions;

export default slice.reducer;
