import { Button, Dropdown, Space } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import {
  CaretRightOutlined,
  DownOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import VideoPlayer from "./VideoPlayer";
import getThumbnailUrl from "@utils/getThumbnailUrl";

const settings: any = {
  dots: false,
  arrows: false,
  infinite: true,
  lazyLoad: `progressive`,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: false,
};

function Seeker(props: any) {
  const [hover, setHover] = useState("");
  const jumpToAsset = (id: string) => {
    const index = props.assets.findIndex((asset: any) => asset._id === id);
    const timeToBeSet = props.assets
      .slice(0, index)
      .reduce((acc: number, cur: any) => acc + cur.duration, 0);
    props.onJump(id, timeToBeSet);
  };
  return (
    <div
      className="w-full bg-gray-200 flex mt-8 relative"
      style={{ maxWidth: 600 }}
      onMouseLeave={() => setHover("")}
    >
      {props.assets?.map((asset: any) => {
        return (
          <div
            style={{
              width: `${(asset.duration * 100) / props.totalDuration}%`,
              minHeight: 10,
              transform: asset._id === hover && `scale(1.2)`,
              backgroundColor: asset._id === hover ? "#91d5ff" : "#1890ff",
            }}
            onClick={() => jumpToAsset(asset._id)}
            onMouseEnter={() => setHover(asset._id)}
            className={`border  cursor-pointer`}
          ></div>
        );
      })}
      <div
        className="py-2  bg-gray-100 absolute rounded border border-gray-500"
        style={{
          left: `-3.5px`,
          top: "-40%",
          width: 7,
          // transform: `translateX(20px)`,
          transform: `translateX(${
            (props.currentTime * 600) / props.totalDuration
          }px)`,
        }}
      ></div>
    </div>
  );
}
function PreviewPlaylist(props: { assets: any[]; orientation: string }) {
  const sliderRef = useRef<Slider>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const slickCurrentRef = useRef(0);
  const [slickCurrent, setSlickCurrent] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout>();
  const timeElapsedRef = useRef(0);
  const totalDuration = useMemo(() => {
    return props.assets?.reduce((acc, cur) => acc + cur.duration, 0) || 0;
  }, [props.assets]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const handlePlay = (speed) => {
    setIsPlaying(true);
    intervalRef.current = setInterval(() => {
      timeElapsedRef.current += 1;
      setTimeElapsed((timeElapsed) =>
        timeElapsed === totalDuration - 1 ? 0 : timeElapsed + 1
      );
      const currentAsset = props.assets[slickCurrentRef.current];
      if (timeElapsedRef.current >= currentAsset.duration) {
        timeElapsedRef.current = 0;
        slickCurrentRef.current += 1;
        if (slickCurrentRef.current >= props.assets.length) {
          slickCurrentRef.current = 0;
        }
        sliderRef.current?.slickGoTo(slickCurrentRef.current);
        setSlickCurrent(slickCurrentRef.current);
      }
    }, 1000 / speed);
  };
  const handlePause = () => {
    setIsPlaying(false);
    clearInterval(intervalRef.current);
  };
  const handleJumpToAsset = (id: string, time: number) => {
    sliderRef.current.slickGoTo(
      props.assets.findIndex((asset: any) => asset._id === id)
    );
    setTimeElapsed(time);
    timeElapsedRef.current = time;
  };
  return (
    <div className="flex flex-col items-center">
      <div
        style={{ width: 630 }}
        className="flex justify-between mb-3 items-baseline"
      >
        <div className="text-xl">Playlist Preview</div>
        <div className="flex gap-x-2 items-center justify-end">
          <Dropdown
            menu={{
              items: [
                {
                  label: "1x",
                  key: 1,
                  onClick: () => setSpeed(1),
                  style: speed === 1 && { fontWeight: 700 },
                },
                {
                  label: "2x",
                  key: 2,
                  onClick: () => setSpeed(2),
                  style: speed === 2 && { fontWeight: 700 },
                },
                {
                  label: "5x",
                  key: 5,
                  onClick: () => setSpeed(5),
                  style: speed === 5 && { fontWeight: 700 },
                },
              ],
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <span>Speed: {speed}x</span>
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
          <div className="flex gap-x-2 justify-end">
            {isPlaying ? (
              <Button icon={<PauseOutlined />} onClick={() => handlePause()} />
            ) : (
              <Button
                icon={<CaretRightOutlined />}
                onClick={() => handlePlay(speed)}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className=" rounded border-black   shadow-2xl w-max"
        style={{ borderWidth: "1rem" }}
      >
        <Slider
          {...settings}
          style={
            props.orientation === "portrait"
              ? { height: 600, width: 337 }
              : { height: 337, width: 600 }
          }
          ref={sliderRef}
          className={`composition-slider`}
        >
          {props.assets?.map((asset: any, index: number) => (
            <div
              key={asset._id}
              className="h-full bg-gray-800 flex items-center"
            >
              {asset.mimeType.includes("image") ? (
                <img
                  src={getThumbnailUrl(asset.mimeType, asset.processedUrls)}
                  alt={asset.name}
                  className="w-full h-full object-contain"
                />
              ) : (
                <VideoPlayer
                  src={asset.originalUrl}
                  index={index}
                  currentSlide={slickCurrent}
                />
              )}
            </div>
          ))}
        </Slider>
      </div>
      <Seeker
        assets={props.assets}
        totalDuration={totalDuration}
        currentTime={timeElapsed}
        onJump={handleJumpToAsset}
      />
    </div>
  );
}

export default PreviewPlaylist;
